import { Link } from "react-router-dom";
import { Button, message } from "antd";
import axios from "axios";
import showConsentDialog from "../../utils/show-consent-dialog";
import getGraphAccessToken from "../../utils/get-graph-token";
import AttachmentFilesSharepoint from "../../components/attachment-files-sp";

export default function SpFilesHome() {
  const getGraphMe = async () => {
    const graphAccessToken = await getGraphAccessToken();

    const requestHeaders = {
      Authorization: `Bearer ${graphAccessToken}`,
    };
    console.log(requestHeaders);
    axios
      .get("https://graph.microsoft.com/v1.0/me", {
        headers: requestHeaders,
      })
      .then(({ data }) => {
        console.log(data);
      });
  };

  // hàm hỗ trợ get file, sao ko truyền cái đường dẫn vào
  // vậy laf ta sẽ truyền vào 1 cái đường dẫn
  const getFiles = async () => {
    const graphAccessToken = await getGraphAccessToken();
    const URL =
      "https://graph.microsoft.com/v1.0/sites/i24h.sharepoint.com,29a988ae-350b-451f-8266-43b280d2713f,9bdee716-4c6b-42f4-9673-5094d8f78236/drive/root:/document-iso/334e51aa-afcb-4144-889c-6a301afe7fbf/8b184bd4-dff1-45dc-8475-4b896d4bdc10:/children";
    const requestHeaders = {
      Authorization: `Bearer ${graphAccessToken}`,
    };
    axios
      .get(URL, {
        headers: requestHeaders,
      })
      .then(({ data }) => {
        console.log(data);
      });
  };

  return (
    <div>
      <h1> List SP files</h1>
      <p>
        <Link to="/">Home</Link>
      </p>
      <p>files</p>
      <p>portal</p>
      <Button onClick={getGraphMe}>get graph me</Button>
      <Button onClick={getFiles}>get Files</Button>
      <Button onClick={showConsentDialog}>showConsentDialog</Button>

      <hr />
      <h3>Test component upload lên SP</h3>
      <AttachmentFilesSharepoint />
    </div>
  );
}
