import React, {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {AppRouterItem} from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";
import {LoadingLazyComponents, NotFoundPage} from "ict24h-themes-react";
import {mapRouterHelper} from "../../utils/MapRouterHelper";
const PORTAL_PREFIX_URL = "/portal";

export const routers: AppRouterItem[] = [
];

// export cai
const PortalRouters: FC = () => {
	return (
		<Routes>

			{mapRouterHelper(routers, undefined)}
			{/* Fallback Not found pages */}
			<Route
				path="*"
				element={<LoadingLazyComponents children={<NotFoundPage />} />}
			/>
		</Routes>
	);
};

export default PortalRouters;

