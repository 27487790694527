import { Button, Drawer, Row, Space, Table, TableColumnsType, Tag } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import Search from 'antd/lib/input/Search'
import { useAuth } from 'ict24h-themes-react'
import { useNavigate } from 'react-router-dom'
import CauHoiService from '../../ngan-hang-cau-hoi/cau-hoi.service'
import { MetadataType, searchQuery } from '../../../../../utils/metadata.types'
import {
  CauHoiEntity,
  CauTraLoiEntity,
} from '../../../../../server-api-hrm.type'

type propsType = {
  open: boolean
  close: () => void
  // eslint-disable-next-line no-unused-vars
  addCauHoi: (cauHoiId: string) => void
}

export default function DrawerAddCauHoi({ open, close, addCauHoi }: propsType) {
  const auth = useAuth()
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState<string>('')
  const [cauHoiList, setCauHoiList] = useState<CauHoiEntity[]>([])
  const [isLoadingCauHoi, setIsLoadingCauHoi] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [metadata, setMetadata] = useState<MetadataType>()

  const getCauHoi = async (query?: searchQuery) => {
    setIsLoadingCauHoi(true)
    const res = await CauHoiService(auth.token!, navigate).getAll(query)
    setCauHoiList(res.data.data)
    setMetadata(res.data.metadata)
    setIsLoadingCauHoi(false)
  }

  const handleSearch = (search: string) => {
    setName(search)
    getCauHoi({ name: search })
  }

  useEffect(() => {
    getCauHoi()
    // eslint-disable-next-line
  }, [])

  const handleAddCauHoi = async (record: CauHoiEntity) => {
    addCauHoi(record.id)
  }

  const columnsCauTraLoiTable: TableColumnsType<CauTraLoiEntity> = [
    {
      title: 'Tên câu trả lời',
      dataIndex: 'tenCauTraLoi',
      key: 'tenCauTraLoi',
    },
    {
      title: 'Đúng/Sai',
      key: 'status',
      render: (text: string, record: CauTraLoiEntity) =>
        record.dung ? (
          <span>
            <Tag color="green">Đúng</Tag>
          </span>
        ) : (
          <span>
            <Tag color="red">Sai</Tag>
          </span>
        ),
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      render: (text: string, record: CauTraLoiEntity) =>
        dayjs(record.createdAt).format('DD/MM/YYYY'),
    },
    {
      title: 'Cập nhật lần cuối',
      key: 'updatedAt',
      render: (text: string, record: CauTraLoiEntity) =>
        dayjs(record.updatedAt).format('DD/MM/YYYY'),
    },
  ]

  const columnsCauHoiTable: TableColumnsType<CauHoiEntity> = [
    {
      title: 'Tên câu hỏi',
      dataIndex: 'tenCauHoi',
      key: 'tenCauHoi',
    },
    {
      title: 'Số câu trả lời',
      dataIndex: ['_count', 'cauTraLoi'],
      key: 'count',
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      render: (text: string, record: CauHoiEntity) =>
        dayjs(record.createdAt).format('DD/MM/YYYY hh:mm'),
    },
    {
      title: 'Cập nhật lần cuối',
      key: 'updatedAt',
      render: (text: string, record: CauHoiEntity) =>
        dayjs(record.updatedAt).format('DD/MM/YYYY hh:mm'),
    },
    {
      title: 'Thêm câu hỏi',
      key: 'updatedAt',
      render: (text: string, record: CauHoiEntity) => (
        <Button
          type="primary"
          onClick={() => handleAddCauHoi(record)}
          disabled={record.cauTraLoi?.length === 0}
        >
          Thêm
        </Button>
      ),
    },
  ]

  const tableTraLoi = (record: CauHoiEntity) => (
    <Table
      size="small"
      rowKey={(r) => r.id}
      columns={columnsCauTraLoiTable}
      dataSource={record.cauTraLoi}
      pagination={false}
    />
  )

  return (
    <Drawer
      width="100%"
      title="Thêm câu hỏi"
      open={open}
      onClose={() => {
        close()
      }}
      footer={null}
      destroyOnClose
    >
      <div>
        <Row justify="end">
          <Search
            value={searchText}
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={(value: string) => {
              handleSearch(value)
            }}
            style={{ width: 200, marginRight: '16px', marginBottom: '16px' }}
          />
        </Row>
        <Table
          size="small"
          scroll={{ x: 600 }}
          columns={columnsCauHoiTable}
          loading={isLoadingCauHoi}
          expandedRowRender={tableTraLoi}
          rowKey={(record) => record.id}
          pagination={false}
          dataSource={cauHoiList}
        />
        <Row justify="end" style={{ marginTop: '16px' }}>
          <Space>
            <Button
              disabled={metadata?.paging.cursor.prev.length === 0}
              onClick={() => {
                if (metadata) {
                  getCauHoi({ pageId: metadata.paging.cursor.prev, name })
                }
              }}
            >
              Trang trước
            </Button>
            <Button
              disabled={metadata?.paging.cursor.next.length === 0}
              onClick={() => {
                if (metadata) {
                  getCauHoi({ pageId: metadata.paging.cursor.next, name })
                }
              }}
            >
              Trang tiếp
            </Button>
          </Space>
        </Row>
      </div>
    </Drawer>
  )
}
