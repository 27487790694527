import { MenuProps, Menu } from 'antd'
import { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import LIST_MENU from '../constants/list-menu.quan-ly-dao-tao'

const LeftMenuHomeApp: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [selectedKey, setSelectedKey] = useState<string>('')

  // Tự highlight menu đúng với đường dẫn
  useEffect(() => {
    if (pathname) {
      setSelectedKey(pathname)
    }
  }, [pathname])

  // tạo menu fake mẫu
  const items2: MenuProps['items'] = []

  // Thêm list menu = chương trình
  items2.unshift({
    key: 'other1',
    icon: <UserOutlined />,
    label: 'Quản lý đào tào',
    children: LIST_MENU.map((menuItem) => {
      // eslint-disable-next-line no-param-reassign
      const temp = menuItem
      temp.onClick = () => {
        navigate(temp.key)
      }
      return temp
    }),
  })

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={['other1']}
      style={{ height: '100%', minHeight: '89vh', borderRight: 0 }}
      items={items2}
      selectedKeys={[selectedKey]}
    />
  )
}

export default LeftMenuHomeApp
