import { message } from 'antd'
import { NavigateFunction } from 'react-router-dom'
import {
  CreateHocVienDto,
  HocVienEntity,
} from '../../../../server-api-hrm.type'
import axiosClient from '../../../../utils/custom-fetch'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'
import { URL_ENDPOINT_HOC_VIEN } from './constants/config.hoc-vien'

export default function HocVienService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllHocVien = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: HocVienEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_HOC_VIEN, {
      params: query,
    })
    return response
  }

  const editHocVien = async (id: string, values: CreateHocVienDto) => {
    const response = await axios.patch<HocVienEntity>(
      `${URL_ENDPOINT_HOC_VIEN}/${id}`,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const addHocVien = async (values: CreateHocVienDto) => {
    const response = await axios.post<HocVienEntity>(
      URL_ENDPOINT_HOC_VIEN,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const deleteHocVien = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_HOC_VIEN}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietHocVien = async (id: string) => {
    const response = await axios.get<HocVienEntity>(
      `${URL_ENDPOINT_HOC_VIEN}/${id}`
    )
    return response
  }
  return {
    getAllHocVien,
    addHocVien,
    deleteHocVien,
    getChitietHocVien,
    editHocVien,
  }
}
