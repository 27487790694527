import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { CA_LAM_PREFIX_URL } from './constants/config.ca-lam'

const ListPage = lazy(() => import('./pages/ListPage.CaLam'))
const DetailPage = lazy(() => import('./pages/DetailPage.CaLam'))

const CaLamRouters: AppRouterItem[] = [
  {
    path: `${CA_LAM_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${CA_LAM_PREFIX_URL}/:id`,
    element: (
      <LoadingLazyComponents>
        <DetailPage />
      </LoadingLazyComponents>
    ),
  },
]

export default CaLamRouters
