import { Breadcrumb } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { HOME_APP_PREFIX_URL } from '../../home-app/constants/config.home-app'
import { QUAN_LY_DAO_TAO_PREFIX_URL } from '../constants/config.quan-ly-dao-tao'

type propsType = {
  label: string
  item3rd: {
    label: string
    link: string
  }
}

export default function BreadCrumb4th({ item3rd, label }: propsType) {
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={HOME_APP_PREFIX_URL}>
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={QUAN_LY_DAO_TAO_PREFIX_URL}>
          <UserOutlined />
          Danh mục modules
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={item3rd.link}>
          <UserOutlined />
          {item3rd.label}
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <UserOutlined />
        {label}
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}
