import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { TAI_LIEU_GIAO_AN_PREFIX_URL } from './constants/config.tai-lieu-giao-an'

const ListPage = lazy(() => import('./pages/ListPage.TaiLieuGiaoAn'))

const TaiLieuGiaoAnRouters: AppRouterItem[] = [
  {
    path: `${TAI_LIEU_GIAO_AN_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default TaiLieuGiaoAnRouters
