import { FC, lazy } from 'react'
import { LoadingLazyComponents, NotFoundPage } from 'ict24h-themes-react'
import { Route, Routes } from 'react-router-dom'
import { QUAN_TRI_DANH_MUC_CHUNG_PATH } from './modules/quan-tri-danh-muc-chung/constants/config.quan-tri-danh-muc-chung'
import { DANH_SACH_DU_AN_PATH } from './modules/danh-sach-du-an/constants/config.danh-sach-du-an'
import { CHI_TIET_DU_AN_PATH } from './modules/chi-tiet-du-an/constants/config.chi-tiet-du-an'

const QuanTriDanhMucChungLayout = lazy(
  () => import('./modules/quan-tri-danh-muc-chung/QuanTriDanhMucChungLayout')
)
const DanhSachDuAnLayout = lazy(
  () => import('./modules/danh-sach-du-an/DanhSachDuAnLayout')
)
const ChiTietDuAnLayout = lazy(
  () => import('./modules/chi-tiet-du-an/ChiTietDuAnLayout')
)

const QuanLyDuAnDauTuRouters: FC = () => (
  <Routes>
    <Route
      path={`${QUAN_TRI_DANH_MUC_CHUNG_PATH}/*`}
      element={
        <LoadingLazyComponents loadingType="skeleton">
          <QuanTriDanhMucChungLayout />
        </LoadingLazyComponents>
      }
    />

    <Route
      path="/*"
      element={
        <LoadingLazyComponents loadingType="skeleton">
          <DanhSachDuAnLayout />
        </LoadingLazyComponents>
      }
    />

    <Route
      path={`${DANH_SACH_DU_AN_PATH}/*`}
      element={
        <LoadingLazyComponents loadingType="skeleton">
          <DanhSachDuAnLayout />
        </LoadingLazyComponents>
      }
    />

    <Route
      path={`${CHI_TIET_DU_AN_PATH}/:duAnId/*`}
      element={
        <LoadingLazyComponents loadingType="skeleton">
          <ChiTietDuAnLayout />
        </LoadingLazyComponents>
      }
    />

    {/* Fallback Not found pages */}
    <Route
      path="*"
      element={
        <LoadingLazyComponents loadingType="skeleton">
          <NotFoundPage />
        </LoadingLazyComponents>
      }
    />
  </Routes>
)

export default QuanLyDuAnDauTuRouters
