import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { HOC_VIEN_PREFIX_URL } from './constants/config.hoc-vien'
import ChiTietHocVien from './pages/DetailPage.HocVien'

const ListPage = lazy(() => import('./pages/ListPage.HocVien'))

const HocVienRouters: AppRouterItem[] = [
  {
    path: `${HOC_VIEN_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${HOC_VIEN_PREFIX_URL}/:id`,
    element: (
      <LoadingLazyComponents>
        <ChiTietHocVien />
      </LoadingLazyComponents>
    ),
  },
]

export default HocVienRouters
