import {
  Button,
  Card,
  Descriptions,
  Drawer,
  Popconfirm,
  Row,
  Space,
  Table,
  TableColumnsType,
  Tag,
} from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import Search from 'antd/lib/input/Search'
import { useAuth } from 'ict24h-themes-react'
import ThongTinBaiTestService from '../bai-test.service'
import CauHoiService from '../../ngan-hang-cau-hoi/cau-hoi.service'
import FormThongTinBaiTest from '../components/FormBaiTest'
import BreadCrumb4th from '../../../components/BreadCrumb4th.QuanLyDaoTao'
import { QUAN_LY_DAO_TAO_PREFIX_URL } from '../../../constants/config.quan-ly-dao-tao'
import { THONG_TIN_BAI_TEST_PREFIX_URL } from '../constants/config.bai-test'
import { MetadataType, searchQuery } from '../../../../../utils/metadata.types'
import {
  CauHoiEntity,
  CauTraLoiEntity,
  CreateThongTinBaiTestDto,
  ThongTinBaiTestEntity,
} from '../../../../../server-api-hrm.type'
import DrawerAddCauHoi from '../components/DrawerAddCauHoi'

export default function DetailPageChiTietBaiTest() {
  const auth = useAuth()

  const { id } = useParams()
  const [thongTinBaiTest, setThongTinBaiTest] =
    useState<ThongTinBaiTestEntity>()
  const [isLoadingThongTinBaiTest, setIsLoadingThongTinBaiTest] =
    useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchText, setSearchText] = useState<string>('')
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [name, setName] = useState<string>('')
  const [cauHoiList, setCauHoiList] = useState<CauHoiEntity[]>([])
  const [metadata, setMetadata] = useState<MetadataType>()
  const [isLoadingCauHoi, setIsLoadingCauHoi] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()
  const [openModalAddCauHoi, setOpenModalAddCauHoi] = useState<boolean>(false)
  const [loadingFormThongTinBaiTest, setLoadingFormThongTinBaiTest] =
    useState<boolean>(false)
  const [isModalAddThongTinBaiTestOpen, setIsModalAddThongTinBaiTestOpen] =
    useState<boolean>(false)
  // eslint-disable-next-line
  const [pageId, setPageId] = useState<string>('')
  // eslint-disable-next-line

  const handleSearch = (text: string) => {
    setSearchText(text)
    if (text.length > 0) {
      searchParams.set('name', text)
    } else {
      searchParams.delete('name')
    }
    searchParams.delete('pageId')
    setSearchParams(searchParams, { replace: true })
  }

  const getThongTinBaiTest = async (idThongTinBaiTest: string) => {
    setIsLoadingThongTinBaiTest(true)
    const res = await ThongTinBaiTestService(
      auth.token!,
      navigate
    ).getChitietThongTinBaiTest(idThongTinBaiTest)

    setThongTinBaiTest(res.data)
    setIsLoadingThongTinBaiTest(false)
  }

  const getCauHoi = async (query?: searchQuery) => {
    setIsLoadingCauHoi(true)
    const res = await CauHoiService(auth.token!, navigate).getAll({
      ...query,
      thongTinBaiTestId: id,
    })
    setCauHoiList(res.data.data)
    setMetadata(res.data.metadata)
    setIsLoadingCauHoi(false)
  }

  useEffect(() => {
    const nameUrl = searchParams.get('name')
    const pageIdUrl = searchParams.get('pageIdUrl')
    const query: searchQuery = {}
    if (nameUrl) {
      setName(nameUrl)
      setSearchText(nameUrl)
      query.name = nameUrl
    } else {
      setName('')
      setSearchText('')
    }
    if (pageIdUrl) {
      setPageId(pageIdUrl)
      query.pageId = pageIdUrl
    } else {
      setPageId('')
    }
    setSearchParams(searchParams, { replace: true })
    if (id) {
      getCauHoi(query)
    }
    // eslint-disable-next-line
  }, [location.search, id])

  useEffect(() => {
    if (id) {
      getThongTinBaiTest(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleAddCauHoi = async (cauHoiId: string) => {
    if (id) {
      await ThongTinBaiTestService(
        auth.token!,
        navigate
      ).addCauHoiInToThongTinBaiTest({
        thongTinBaiTestId: id,
        cauHoiId,
      })
      if (location.search === '') getCauHoi()
      else {
        setSearchText('')
        setPageId('')
        navigate(location.pathname)
      }
    }
  }

  const removeCauHoi = async (record: CauHoiEntity) => {
    if (id) {
      await ThongTinBaiTestService(
        auth.token!,
        navigate
      ).removeCauHoiFromThongTin(id, record.id)

      if (location.search === '') getCauHoi()
      else {
        setSearchText('')
        setPageId('')
        navigate(location.pathname)
      }
    }
  }

  const handleEdit = async (values: CreateThongTinBaiTestDto) => {
    if (thongTinBaiTest) {
      setLoadingFormThongTinBaiTest(true)
      await ThongTinBaiTestService(auth.token!, navigate).editThongTinBaiTest(
        values,
        thongTinBaiTest.id
      )
      if (id) getThongTinBaiTest(id)
      setLoadingFormThongTinBaiTest(false)
      setIsModalAddThongTinBaiTestOpen(false)
    }
  }

  const columnsCauTraLoiTable: TableColumnsType<CauTraLoiEntity> = [
    {
      title: 'Tên câu trả lời',
      dataIndex: 'tenCauTraLoi',
      key: 'tenCauTraLoi',
    },
    {
      title: 'Đúng/Sai',
      key: 'status',
      render: (text: string, record: CauTraLoiEntity) =>
        record.dung ? (
          <span>
            <Tag color="green">Đúng</Tag>
          </span>
        ) : (
          <span>
            <Tag color="red">Sai</Tag>
          </span>
        ),
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      render: (text: string, record: CauTraLoiEntity) =>
        dayjs(record.createdAt).format('DD/MM/YYYY'),
    },
    {
      title: 'Cập nhật lần cuối',
      key: 'updatedAt',
      render: (text: string, record: CauTraLoiEntity) =>
        dayjs(record.updatedAt).format('DD/MM/YYYY'),
    },
  ]

  const columnsCauHoiTable: TableColumnsType<CauHoiEntity> = [
    {
      title: 'Tên câu hỏi',
      dataIndex: 'tenCauHoi',
      key: 'tenCauHoi',
    },
    {
      title: 'Số lượng câu trả lời',
      dataIndex: ['_count', 'cauTraLoi'],
      key: 'tenCauHoi',
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      render: (text: string, record: CauHoiEntity) =>
        dayjs(record.createdAt).format('DD/MM/YYYY hh:mm'),
    },
    {
      title: 'Cập nhật lần cuối',
      key: 'updatedAt',
      render: (text: string, record: CauHoiEntity) =>
        dayjs(record.updatedAt).format('DD/MM/YYYY hh:mm'),
    },
    {
      title: 'Xóa khỏi',
      key: 'delete',
      fixed: 'right',
      width: '100px',
      render: (text: string, record: CauHoiEntity) => (
        <Popconfirm
          title="Bạn có chắc chắn là muốn xóa?"
          onConfirm={() => removeCauHoi(record)}
          okText="Có"
          cancelText="Không"
        >
          <Button danger>Xóa</Button>
        </Popconfirm>
      ),
    },
  ]

  const tableCauTraLoi = (record: CauHoiEntity) => (
    <Table
      size="small"
      rowKey={(r) => r.id}
      columns={columnsCauTraLoiTable}
      dataSource={record.cauTraLoi}
      pagination={false}
    />
  )

  return (
    <div style={{ paddingTop: 16 }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <BreadCrumb4th
          label="Chi tiết bài test"
          item3rd={{
            label: 'Thông tin bài test',
            link: QUAN_LY_DAO_TAO_PREFIX_URL + THONG_TIN_BAI_TEST_PREFIX_URL,
          }}
        />
        <Card
          loading={isLoadingThongTinBaiTest}
          title={<b>Chi tiết thông tin bài test</b>}
          style={{ width: '100%', marginTop: 16 }}
          extra={
            <Button
              onClick={() => {
                setIsModalAddThongTinBaiTestOpen(true)
              }}
            >
              <EditOutlined />
            </Button>
          }
        >
          <Descriptions>
            <Descriptions.Item label="Tiêu đề">
              {thongTinBaiTest?.tieuDe}
            </Descriptions.Item>
            <Descriptions.Item label="Thời gian bắt đầu">
              {dayjs(thongTinBaiTest?.ngayBatDau).format('DD/MM/YYYY hh:mm')}
            </Descriptions.Item>
            <Descriptions.Item label="Thời gian kết thúc">
              {dayjs(thongTinBaiTest?.ngayKetThuc).format('DD/MM/YYYY hh:mm')}
            </Descriptions.Item>
            <Descriptions.Item label="Thời gian làm bài">
              {thongTinBaiTest?.thoiGianLamBai} phút
            </Descriptions.Item>
            <Descriptions.Item label="Ngày tạo">
              {dayjs(thongTinBaiTest?.createdAt).format('DD/MM/YYYY hh:mm')}
            </Descriptions.Item>
            <Descriptions.Item label="Cập nhật gần nhất">
              {dayjs(thongTinBaiTest?.updatedAt).format('DD/MM/YYYY hh:mm')}
            </Descriptions.Item>
          </Descriptions>
        </Card>

        <Card title={<b>Danh sách câu hỏi</b>} style={{ width: '100%' }}>
          <Row justify="end" style={{ marginBottom: '8px' }}>
            <Search
              value={searchText}
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={(value: string) => {
                handleSearch(value)
              }}
              style={{ width: 200, marginRight: '16px' }}
            />
            <Button type="primary" onClick={() => setOpenModalAddCauHoi(true)}>
              <PlusOutlined />
              Thêm
            </Button>
          </Row>
          <Table
            size="small"
            scroll={{ x: 1100 }}
            columns={columnsCauHoiTable}
            loading={isLoadingCauHoi}
            expandedRowRender={tableCauTraLoi}
            rowKey={(record) => record.id}
            pagination={false}
            dataSource={cauHoiList}
          />
          <Row justify="end" style={{ marginTop: '16px' }}>
            <Space>
              <Button
                disabled={metadata?.paging.cursor.prev.length === 0}
                onClick={() => {
                  if (metadata) {
                    searchParams.set('pageId', metadata?.paging.cursor.prev)
                    setSearchParams(searchParams)
                  }
                }}
              >
                Trang trước
              </Button>
              <Button
                disabled={metadata?.paging.cursor.next.length === 0}
                onClick={() => {
                  if (metadata) {
                    searchParams.set('pageId', metadata?.paging.cursor.next)
                    setSearchParams(searchParams)
                  }
                }}
              >
                Trang tiếp
              </Button>
            </Space>
          </Row>
        </Card>
        <DrawerAddCauHoi
          open={openModalAddCauHoi}
          close={() => setOpenModalAddCauHoi(false)}
          addCauHoi={handleAddCauHoi}
        />
        <Drawer
          title="Sửa thông tin bài test"
          open={isModalAddThongTinBaiTestOpen}
          onClose={() => {
            setIsModalAddThongTinBaiTestOpen(false)
          }}
          footer={null}
          destroyOnClose
        >
          <FormThongTinBaiTest
            loading={loadingFormThongTinBaiTest}
            onSubmit={handleEdit}
            thongTinBaiTest={thongTinBaiTest}
          />
        </Drawer>
      </Space>
    </div>
  )
}
