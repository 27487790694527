import React from 'react'
import { Drawer } from 'antd'
import FormKhoaDaoTaoOfKeHoach from './FormKhoaDaoTaoOfKeHoach'
import { KhoaDaoTaoEntity } from '../../../../../../server-api-hrm.type'

type propsType = {
  isModalOpen: boolean
  handleCancel: () => void
  thongTinKhoaDaoTao: KhoaDaoTaoEntity | undefined
  // eslint-disable-next-line no-unused-vars
  addThongTinKhoaDaoTao: (khoaDaoTaoId: string) => void
}

function DrawerKhoaDaoTaoOfKeHoach({
  isModalOpen,
  handleCancel,
  thongTinKhoaDaoTao,
  addThongTinKhoaDaoTao,
}: propsType) {
  return (
    <Drawer
      width="100%"
      title={<strong>Thêm Khoá Đào Tạo Vào Kế Hoạch</strong>}
      open={isModalOpen}
      onClose={handleCancel}
      footer={null}
      destroyOnClose
    >
      <FormKhoaDaoTaoOfKeHoach
        addThongTinKhoaDaoTao={addThongTinKhoaDaoTao}
        thongTinKhoaDaoTao={thongTinKhoaDaoTao}
      />
    </Drawer>
  )
}

export default DrawerKhoaDaoTaoOfKeHoach
