import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const PHONG_BAN_PREFIX_URL = '/phong-ban'
export const URL_ENDPOINT_PHONG_BAN = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/phong-ban`

export const configPhongBanLayout: HeaderLayoutConfig = {
  appName: ' Thông tin phòng ban ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
