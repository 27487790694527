import { LoadingLazyComponents, NotFoundPage } from "ict24h-themes-react";
import { FC, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { CONFIG_WORKFLOW_APP_PREFIX_URL } from "./constants/config.home-app";
import { WorkflowStepProvider } from "./pages/contexts/WorkflowStep.context";

const WFPage = lazy(() => import("./pages/WF.ConfigWorkflows"));
const WorkflowDetailPage = lazy(() => import("./pages/Workflow.detail"));
const FormPage = lazy(() => import("./pages/Form.ConfigWorkflows"));
const FormDetailPage = lazy(
  () => import("./pages/Form.detail.ConfigWorkflows")
);

const HomeAppRouters: FC = () => {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <LoadingLazyComponents
            children={
              <Navigate
                to={CONFIG_WORKFLOW_APP_PREFIX_URL + "/form"}
                replace={true}
              />
            }
          />
        }
      />
      <Route
        path="/form"
        element={<LoadingLazyComponents children={<FormPage />} />}
      />
      <Route
        path="/form/:id"
        element={<LoadingLazyComponents children={<FormDetailPage />} />}
      />
      <Route
        path={"/wf"}
        element={<LoadingLazyComponents children={<WFPage />} />}
      />
      <Route
        path={"/wf/:id"}
        element={
          <LoadingLazyComponents
            children={
              <WorkflowStepProvider>
                <WorkflowDetailPage />
              </WorkflowStepProvider>
            }
          />
        }
      />

      {/* Fallback Not found pages */}
      <Route
        path="*"
        element={<LoadingLazyComponents children={<NotFoundPage />} />}
      />
    </Routes>
  );
};

export default HomeAppRouters;
