import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const CHE_DO_BAO_HIEM_PREFIX_URL = '/che-do-bao-hiem'
export const URL_ENDPOINT_CHE_DO_BAO_HIEM = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/che-do-bao-hiem-xa-hoi`

export const configCheDoBaoHiemLayout: HeaderLayoutConfig = {
  appName: ' Thông tin chế độ bảo hiểm xã hội ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
