import { LoadingLazyComponents, NotFoundPage } from 'ict24h-themes-react'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import mapRouterHelper from '../../utils/MapRouterHelper'
import ListModules from './pages/ListModules.Kpi'
import CapKpiRouters from './modules/cap-kpi/CapKpiRouters'
import PhuongPhapTinhKpiRouters from './modules/phuong-phap-tinh/PhuongPhapTinhKpiRouters'
import DonViTinhKpiRouters from './modules/don-vi-tinh/DonViTinhKpiRouters'
import NhomTieuChiDanhGiaKpiRouters from './modules/nhom-tieu-chi-danh-gia/NhomTieuChiDanhGiaKpiRouters'
import KeHoachKpiRouters from './modules/ke-hoach-kpi/KeHoachKpiRouters'
import ChiDinhKpiRouters from './modules/chi-dinh-kpi/ChiDinhKpiRouters'
import ChungMinhKpiRouters from './modules/chung-minh-kpi/ChungMinhKpiRouters'
import ThuVienKpiRouters from './modules/thu-vien-kpi/ThuVienKpiRouters'
import DashboardRouters from './modules/dashboard-kpi/DashboardKpiRouters'

const DanhGiaKpiRouters: FC = () => (
  <Routes>
    <Route
      path="/"
      element={
        <LoadingLazyComponents>
          <ListModules />
        </LoadingLazyComponents>
      }
    />

    {mapRouterHelper(CapKpiRouters)}
    {mapRouterHelper(PhuongPhapTinhKpiRouters)}
    {mapRouterHelper(DonViTinhKpiRouters)}
    {mapRouterHelper(NhomTieuChiDanhGiaKpiRouters)}
    {mapRouterHelper(KeHoachKpiRouters)}
    {mapRouterHelper(ChiDinhKpiRouters)}
    {mapRouterHelper(ChungMinhKpiRouters)}
    {mapRouterHelper(ThuVienKpiRouters)}
    {mapRouterHelper(DashboardRouters)}

    {/* Fallback Not found pages */}
    <Route
      path="*"
      element={
        <LoadingLazyComponents>
          <NotFoundPage />
        </LoadingLazyComponents>
      }
    />
  </Routes>
)

export default DanhGiaKpiRouters
