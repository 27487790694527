import { LoadingLazyComponents, NotFoundPage } from 'ict24h-themes-react'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import mapRouterHelper from '../../utils/MapRouterHelper'
import ListModules from './pages/ListModules.KeHoachDieuDongLuanChuyenNhanSu'
import PhieuRouters from './modules/phieu-dieu-dong-luan-chuyen-nhan-su/PhieuRouters'
import KeHoachDieuDongLuanChuyenNhanSuRouters from './modules/ke-hoach-dieu-dong-luan-chuyen-nhan-su/KeHoachDieuDongLuanChuyenNhanSuRouters'

const QuanLyDieuDongLuanChuyenNhanSuRouters: FC = () => (
  <Routes>
    <Route
      path="/"
      element={
        <LoadingLazyComponents>
          <ListModules />
        </LoadingLazyComponents>
      }
    />
    {mapRouterHelper(KeHoachDieuDongLuanChuyenNhanSuRouters)}

    {mapRouterHelper(PhieuRouters)}

    {/* Fallback Not found pages */}
    <Route
      path="*"
      element={
        <LoadingLazyComponents>
          <NotFoundPage />
        </LoadingLazyComponents>
      }
    />
  </Routes>
)

export default QuanLyDieuDongLuanChuyenNhanSuRouters
