import { message } from 'antd'

import { NavigateFunction } from 'react-router-dom'
import { URL_ENDPOINT_CHI_NHANH } from './constants/config.chi-nhanh'
import axiosClient from '../../../../utils/custom-fetch'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'
import {
  ChiNhanhEntity,
  CreateChiNhanhDto,
  UpdateChiNhanhDto,
} from '../../../../server-api-hrm.type'

export default function ChiNhanhService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllChiNhanh = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: ChiNhanhEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_CHI_NHANH, {
      params: query,
    })
    return response
  }

  const addChiNhanh = async (values: CreateChiNhanhDto) => {
    const response = await axios.post<ChiNhanhEntity>(
      URL_ENDPOINT_CHI_NHANH,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editChiNhanh = async (values: UpdateChiNhanhDto, id: string) => {
    const res = await axios.patch(`${URL_ENDPOINT_CHI_NHANH}/${id}`, values)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const deleteChiNhanh = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_CHI_NHANH}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietChiNhanh = async (id: string) => {
    const response = await axios.get<ChiNhanhEntity>(
      `${URL_ENDPOINT_CHI_NHANH}/${id}`
    )
    return response
  }

  return {
    getAllChiNhanh,
    addChiNhanh,
    editChiNhanh,
    deleteChiNhanh,
    getChitietChiNhanh,
  }
}
