import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const KE_HOACH_KPI_PREFIX_URL = '/ke-hoach-kpi'
export const CHI_TIET_KE_HOACH_KPI_PREFIX_URL = '/ke-hoach-kpi'

export const URL_ENDPOINT_KE_HOACH_KPI = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/ke-hoach-kpi`
export const URL_ENDPOINT_CHI_TIET_KE_HOACH_KPI = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/ke-hoach-kpi`
export const URL_ENDPOINT_KE_HOACH_KPI_APPROVAL_LOG = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/ke-hoach-kpi-approval-log`

export const configMauKpiLayout: HeaderLayoutConfig = {
  appName: 'Kế hoạch KPI',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
