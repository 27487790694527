import { Breadcrumb, Card } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { HOME_APP_PREFIX_URL } from '../../home-app/constants/config.home-app'
import { DANH_GIA_KPI_PATH } from '../constants/config.danh-gia-kpi'
import { CAP_KPI_PREFIX_URL } from '../modules/cap-kpi/constants/config.cap-kpi'
import { PHUONG_PHAP_TINH_KPI_PREFIX_URL } from '../modules/phuong-phap-tinh/constants/config.phuong-phap-tinh-kpi'

const gridStyle: React.CSSProperties = {
  width: '25%',
  textAlign: 'center',
}

const ListModules = () => (
  <div style={{ paddingTop: 16 }}>
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={HOME_APP_PREFIX_URL}>
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <UserOutlined />
        Danh mục modules
      </Breadcrumb.Item>
    </Breadcrumb>
    <Card title="Danh sách modules đánh giá Kpi" style={{ marginTop: 16 }}>
      <Card.Grid style={gridStyle}>
        <Link to={DANH_GIA_KPI_PATH + CAP_KPI_PREFIX_URL}>Cấp KPI</Link>
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Link to={DANH_GIA_KPI_PATH + PHUONG_PHAP_TINH_KPI_PREFIX_URL}>
          Bảng kết quả KPI
        </Link>
      </Card.Grid>
      {/* <Card.Grid style={gridStyle}>
        <Link to={DANH_GIA_KPI_PATH + TIEU_CHI_KPI_PREFIX_URL}>
          Danh sách tiêu chí KPI
        </Link>
      </Card.Grid> */}
    </Card>
  </div>
)

export default ListModules
