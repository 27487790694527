import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const CHI_NHANH_PREFIX_URL = '/chi-nhanh'
export const URL_ENDPOINT_CHI_NHANH = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/chi-nhanh`

export const configChiNhanhLayout: HeaderLayoutConfig = {
  appName: ' Thông tin chi nhánh ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
