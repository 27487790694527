import { LoadingLazyComponents } from "ict24h-themes-react";
import { AppRouterItem } from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";
import React from "react";
import { lazy } from "react";
import { DE_NGHI_MUA_SAM_PREFIX_URL } from "./constants/config.de-nghi-mua-sam";

const MyWaitingApprovalPage = lazy(
  () => import("../de-nghi-mua-sam/pages/DanhSachPhieuChoToiDuyet.DeNghiMuaSam")
);

const MyApprovedApprovalPage = lazy(
  () => import("./pages/DanhSachPhieuToiThamGiaPheDuyet.DeNghiMuaSam")
);
const EditPage = lazy(
  () => import("../de-nghi-mua-sam/pages/EditPage.DeNghiMuaSam")
);
const CreatePage = lazy(
  () => import("../de-nghi-mua-sam/pages/CreatePage.DeNghiMuaSam")
);
const DanhSachPhieuToiTao_DeNghiMuaSam = lazy(
  () => import("../de-nghi-mua-sam/pages/DanhSachPhieuToiTao.DeNghiMuaSam")
);
const DetailPage = lazy(
  () => import("../de-nghi-mua-sam/pages/DetailPage.DeNghiMuaSam")
);
const PreviewPage = lazy(
  () => import("../de-nghi-mua-sam/pages/PreviewPage.DeNghiMuaSam")
);
export const DeNghiMuaSamRouters: AppRouterItem[] = [
  {
    path: DE_NGHI_MUA_SAM_PREFIX_URL + "/create",
    element: (
      <LoadingLazyComponents children={<CreatePage />} loadingType="skeleton" />
    ),
  },

  {
    path: DE_NGHI_MUA_SAM_PREFIX_URL + "/:id" + "/edit",
    element: (
      <LoadingLazyComponents children={<EditPage />} loadingType="skeleton" />
    ),
  },

  {
    path: DE_NGHI_MUA_SAM_PREFIX_URL + "/my-approval",
    element: (
      <LoadingLazyComponents
        children={<DanhSachPhieuToiTao_DeNghiMuaSam />}
        loadingType="skeleton"
      />
    ),
  },
  {
    path: DE_NGHI_MUA_SAM_PREFIX_URL + "/:id" + "/view",
    element: (
      <LoadingLazyComponents children={<DetailPage />} loadingType="skeleton" />
    ),
  },
  {
    path: DE_NGHI_MUA_SAM_PREFIX_URL + "/preview" + "/:id",
    element: (
      <LoadingLazyComponents
        children={<PreviewPage />}
        loadingType="skeleton"
      />
    ),
  },
  {
    path: DE_NGHI_MUA_SAM_PREFIX_URL + "/waiting-approval",
    element: (
      <LoadingLazyComponents
        children={<MyWaitingApprovalPage />}
        loadingType="skeleton"
      />
    ),
  },
  {
    path: DE_NGHI_MUA_SAM_PREFIX_URL + "/my-act-approval",
    element: (
      <LoadingLazyComponents
        children={<MyApprovedApprovalPage />}
        loadingType="skeleton"
      />
    ),
  },
];
