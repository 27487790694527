import { FormActionEnum } from '../../../../constants/quan-ly-tuyen-dung-enums'

// eslint-disable-next-line import/prefer-default-export
export const BO_TIEU_CHI_DANH_GIA_PREFIX_URL = '/bo-tieu-chi-danh-gia'

export interface OpenDrawerPropsType<T> {
  isOpen: boolean
  action: FormActionEnum | undefined
  data: T | undefined
  createType?: 'Chung' | 'Chuyên môn'
}
