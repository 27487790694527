import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { NGAY_LE_PREFIX_URL } from './constants/config.ngay-le'

const ListPage = lazy(() => import('./pages/ListPage.NgayLe'))

const NgayLeRouters: AppRouterItem[] = [
  {
    path: `${NGAY_LE_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default NgayLeRouters
