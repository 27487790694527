import { message } from 'antd'
import { NavigateFunction } from 'react-router-dom'
import axiosClient from '../../../../utils/custom-fetch'
import { URL_ENDPOINT_KE_HOACH_HOC_TAP } from './constants/config.ke-hoach-hoc-tap'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'
import {
  AddDeXuatToKeHoachDto,
  CreateKeHoachHocTapDto,
  KeHoachHocTapEntity,
  UpdateKeHoachHocTapDto,
} from '../../../../server-api-hrm.type'

export default function KeHoachHocTapService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllKeHoachHocTap = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: KeHoachHocTapEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_KE_HOACH_HOC_TAP, {
      params: query,
    })
    return response
  }

  const addKeHoach = async (values: CreateKeHoachHocTapDto) => {
    const response = await axios.post<KeHoachHocTapEntity>(
      URL_ENDPOINT_KE_HOACH_HOC_TAP,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editKeHoach = async (values: UpdateKeHoachHocTapDto, id: string) => {
    const res = await axios.patch(
      `${URL_ENDPOINT_KE_HOACH_HOC_TAP}/${id}`,
      values
    )
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getDeleteKeHoach = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_KE_HOACH_HOC_TAP}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getSingle = async (id: string) => {
    const response = await axios.get<KeHoachHocTapEntity>(
      `${URL_ENDPOINT_KE_HOACH_HOC_TAP}/${id}`
    )
    return response
  }

  const getChitietKeHoach = async (id: string) => {
    const response = await axios.get<KeHoachHocTapEntity>(
      `${URL_ENDPOINT_KE_HOACH_HOC_TAP}/${id}`
    )
    return response
  }

  const deleteKhoaDaoTaoOfKeHoach = async (object: {
    keHoachHocTapId: string
    khoaDaoTaoId: string
  }) => {
    const res = await axios.delete(
      `${URL_ENDPOINT_KE_HOACH_HOC_TAP}/${object.keHoachHocTapId}/khoa-dao-tao/${object.khoaDaoTaoId}`
    )
    return res
  }

  const deleteDeXuatOfKeHoach = async (object: {
    keHoachHocTapId: string
    deXuatKhoaDaoTaoId: string
  }) => {
    const res = await axios.delete(
      `${URL_ENDPOINT_KE_HOACH_HOC_TAP}/${object.keHoachHocTapId}/de-xuat-khoa-dao-tao/${object.deXuatKhoaDaoTaoId}`
    )
    return res
  }

  const addKhoaDaoTaoToKeHoach = async (query: searchQuery) => {
    const res = await axios.post(
      `${URL_ENDPOINT_KE_HOACH_HOC_TAP}/khoa-dao-tao`,
      query
    )
    return res
  }

  const addDeXuatToKeHoach = async (query: AddDeXuatToKeHoachDto) => {
    const res = await axios.post(
      `${URL_ENDPOINT_KE_HOACH_HOC_TAP}/de-xuat-khoa-dao-tao`,
      query
    )
    return res
  }

  return {
    deleteDeXuatOfKeHoach,
    addDeXuatToKeHoach,
    getSingle,
    addKhoaDaoTaoToKeHoach,
    deleteKhoaDaoTaoOfKeHoach,
    getChitietKeHoach,
    editKeHoach,
    getAllKeHoachHocTap,
    addKeHoach,
    getDeleteKeHoach,
  }
}
