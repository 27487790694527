import { LoadingLazyComponents } from "ict24h-themes-react";
import { AppRouterItem } from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";
import React from "react";
import { lazy } from "react";
import { CAP_BAC_NHAN_VIEN_PREFIX_URL } from "./constants/config.cap-bac-nhan-vien";

const ListPage = lazy(() => import("./pages/ListPage.CapBacNhanVien"));

export const CapBacNhanVienRouters: AppRouterItem[] = [
  {
    path: CAP_BAC_NHAN_VIEN_PREFIX_URL + "/",
    element: <LoadingLazyComponents children={<ListPage />} />,
  },
];
