import {SidebarLeftLayout} from "ict24h-themes-react";
import {FC} from "react";
import PortalRouters from "./PortalRouter";

import {configPortalLayout} from "./config.portal";


const PortalLayout: FC = () => {
	return (
		<SidebarLeftLayout menu={null} headerConfig={configPortalLayout} >
			<PortalRouters />
		</SidebarLeftLayout>
	);
};

export default PortalLayout;
