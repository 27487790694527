import { HeaderLayoutConfig } from "ict24h-themes-react/lib/esm/components/UI/Header/Header";
import { appAPI } from "./custom-fetch-api";

export const deNghiThanhToanEndpoint = appAPI + '/de-nghi-thanh-toan'
export const chiTietDeNghiThanhToanEndpoint = appAPI + `/chi-tiet-de-nghi-thanh-toan`
export const deNghiThanhToanApprovalLogEndpoint = appAPI + `/de-nghi-thanh-toan-approval-log/de-nghi-thanh-toan`
export const userEndpoint = appAPI + `/user`
export const currentUserEndpoint = userEndpoint + `/whoami`
export const capBacEndpoint = appAPI + `/cap-bac`
export const donViEndpoint = appAPI + `/don-vi`
export const myActAprrovalEndpoint = deNghiThanhToanEndpoint + '/my-act-approval'
export const myCreatedAprrovalEndpoint = deNghiThanhToanEndpoint + '/my-created-approval'
export const myWaitingAprrovalEndpoint = deNghiThanhToanEndpoint + '/waiting-approval'
export const approveEndpoint = deNghiThanhToanEndpoint + '/approve'
export const submitEndpoint = deNghiThanhToanEndpoint + '/submit'
export const tienTeEndpoint = appAPI + '/tien-te'

export const DE_NGHI_THANH_TOAN_PREFIX_URL = "/de-nghi-thanh-toan";
export const configDeNghiThanhToanLayout: HeaderLayoutConfig = {
  appName: " ICT24h Demo Đề nghị thanh toán ",
  logoLink: "https://ict24h.net",
  backgroundColor: "#454791",
  color: "#000",
};
