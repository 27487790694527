import { LoadingLazyComponents } from "ict24h-themes-react";
import { AppRouterItem } from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";
import { lazy } from "react";
import { LOAI_PHIEU_THANH_TOAN_PREFIX_URL } from "./constants/config.loai-phieu-thanh-toan";

const LayoutPage = lazy(() => import("./pages/LayoutPage.LoaiPhieuThanhToan"));

export const LoaiPhieuThanhToanRouters: AppRouterItem[] = [
  {
    path: LOAI_PHIEU_THANH_TOAN_PREFIX_URL + "/",
    element: <LoadingLazyComponents children={<LayoutPage />} loadingType="skeleton" />,
  },
];
