/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, Descriptions, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from 'ict24h-themes-react'
import HocVienService from '../hoc-vien.service'
import BreadCrumb4th from '../../../components/BreadCrumb4th.QuanLyDaoTao'
import { QUAN_LY_DAO_TAO_PREFIX_URL } from '../../../constants/config.quan-ly-dao-tao'
import { HOC_VIEN_PREFIX_URL } from '../constants/config.hoc-vien'
import { HocVienEntity } from '../../../../../server-api-hrm.type'

function ChiTietHocVien() {
  const auth = useAuth()
  const { id } = useParams()
  const [hocVien, setHocVien] = useState<HocVienEntity>()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const fetchSingleHocVien = async (idHocVien: string) => {
    setLoading(true)
    const data = await HocVienService(auth.token!, navigate).getChitietHocVien(
      idHocVien
    )
    if (data) setHocVien(data.data)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      fetchSingleHocVien(id)
    }
    // eslint-disable-next-line
  }, [id])

  return (
    <div style={{ paddingTop: 16 }}>
      <BreadCrumb4th
        label="Chi tiết học viên"
        item3rd={{
          label: 'Danh sách học viên',
          link: QUAN_LY_DAO_TAO_PREFIX_URL + HOC_VIEN_PREFIX_URL,
        }}
      />
      <Card title={<b>Chi tiết học viên</b>} style={{ marginTop: 16 }}>
        {loading ? (
          <Skeleton />
        ) : (
          <Descriptions>
            <Descriptions.Item label="Khoá đào tạo">
              <text>{`${hocVien?.khoaDaoTao.tieuDe}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Nhân viên">
              <text>{`${hocVien?.nhanVien.hoTen}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Số điện thoại">
              <text>{`${hocVien?.nhanVien.soDienThoai}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Địa chỉ">
              <text>{`${hocVien?.nhanVien.diaChi}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <text>{`${hocVien?.nhanVien.email}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Chi nhánh">
              <text>{`${hocVien?.nhanVien.chiNhanh?.name}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Phòng ban">
              <text>{`${hocVien?.nhanVien.phongBan?.name}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Chức vụ">
              <text>{`${hocVien?.nhanVien.chucVu?.name}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Điểm kiểm tra">
              <text>{hocVien?.diemKiemTra}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Đánh giá">
              <text>{hocVien?.danhGia}</text>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    </div>
  )
}

export default ChiTietHocVien
