import { LoadingLazyComponents } from "ict24h-themes-react";
import React from "react";
import { lazy } from "react";
import { TIEN_TE_PREFIX_URL } from "./constants/config.tien-te";
import {AppRouterItem} from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";

const ListPage = lazy(() => import("./pages/ListPage.TienTe"));

export const TienTeRouters: AppRouterItem[] = [
  {
    path: TIEN_TE_PREFIX_URL + "/",
    element: <LoadingLazyComponents children={<ListPage />} />,
  },
];
