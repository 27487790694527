import message from 'antd/es/message'
import { NavigateFunction } from 'react-router-dom'
import { CauHoiEntity, CreateCauHoiDto } from '../../../../server-api-hrm.type'
import axiosClient from '../../../../utils/custom-fetch'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'
import { URL_ENDPOINT_CAU_HOI } from './constants/config.ngan-hang-cau-hoi'

export default function CauHoiService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAll = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: CauHoiEntity[]
      metadata: MetadataType
    }>(`${URL_ENDPOINT_CAU_HOI}`, {
      params: query,
    })
    return response
  }

  const getSingle = async (id: string) => {
    const response = await axios.get<CauHoiEntity>(
      `${URL_ENDPOINT_CAU_HOI}/${id}`
    )
    return response
  }

  const add = async (values: CreateCauHoiDto) => {
    const res = await axios.post(URL_ENDPOINT_CAU_HOI, values)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const edit = async (values: CreateCauHoiDto, id: string) => {
    const res = await axios.patch(`${URL_ENDPOINT_CAU_HOI}/${id}`, values)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const remove = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_CAU_HOI}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  return {
    getAll,
    getSingle,
    add,
    edit,
    remove,
  }
}
