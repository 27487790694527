/* eslint-disable no-nested-ternary */
import {
  Button,
  Card,
  Checkbox,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
} from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import Column from 'antd/lib/table/Column'
import React, { useEffect, useState } from 'react'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import dayjs from 'dayjs'
import Search from 'antd/lib/input/Search'
import { useAuth } from 'ict24h-themes-react'
import BuoiHocService from '../../buoi-hoc.service'
import HocVienService from '../../../../../hoc-vien/hoc-vien.service'
import HocVienVangServices from '../hoc-vien-vang.service'
import { NHAN_VIEN_PREFIX_URL } from '../../../../../../../cau-hinh-thong-tin/modules/nhan-vien/constants/config.nhan-vien'
import { searchQuery } from '../../../../../../../../utils/metadata.types'
import {
  BuoiHocEntity,
  CreateHocVienVangDto,
  HocVienEntity,
} from '../../../../../../../../server-api-hrm.type'

export default function HocVienVang() {
  const auth = useAuth()
  const [buoiHoc, setBuoiHoc] = useState<BuoiHocEntity>()
  const [isLoadingBuoiHoc, setIsLoadingBuoiHoc] = useState<boolean>(false)
  const [hocVienList, setHocVienList] = useState<HocVienEntity[]>([])
  const [isLoadingHocVien, setIsLoadingHocVien] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedHocVien, setSelectedHocVien] = useState<HocVienEntity>()
  const [form] = Form.useForm()
  const [searchParams, setSearchParams] = useSearchParams()
  const { buoiHocId, id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [filterValue, setFilterValue] = useState<string>('undefined')
  const [name, setName] = useState<string>('')
  const [loadingFormLyDo, setLoadingFormLyDo] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')

  const handleSearch = (value: string) => {
    setName(value)
    if (value.length > 0) {
      searchParams.set('name', value)
    } else {
      searchParams.delete('name')
    }
    searchParams.delete('pageId')
    setSearchParams(searchParams)
  }

  useEffect(() => {
    const getBuoiHoc = async (idBuoihoc: string) => {
      setIsLoadingBuoiHoc(true)
      const res = await BuoiHocService(auth.token!, navigate).getChitietBuoiHoc(
        idBuoihoc
      )
      setBuoiHoc(res.data)
      setIsLoadingBuoiHoc(false)
    }
    if (buoiHocId) getBuoiHoc(buoiHocId)
    // eslint-disable-next-line
  }, [])

  const getHocVien = async (query?: searchQuery) => {
    setIsLoadingHocVien(true)
    const res = await HocVienService(auth.token!, navigate).getAllHocVien({
      ...query,
      khoaDaoTaoId: id,
      buoiHocId,
    })
    setHocVienList(res.data.data)
    setIsLoadingHocVien(false)
  }

  useEffect(() => {
    const nameUrl = searchParams.get('name')
    const filterUrl = searchParams.get('vang')
    const query: any = {}
    if (nameUrl) {
      setName(nameUrl)
      setSearchText(nameUrl)
      query.name = nameUrl
    } else {
      setName('')
      setSearchText('')
    }
    if (filterUrl && (filterUrl === '0' || filterUrl === '1')) {
      setFilterValue(filterUrl)
      query.filterValue = filterUrl
    } else {
      setFilterValue('undefined')
    }
    setSearchParams(searchParams, { replace: true })
    getHocVien(query)
    // eslint-disable-next-line
  }, [location.search])

  const onFinish = async (values: CreateHocVienVangDto) => {
    if (selectedHocVien) {
      const data: CreateHocVienVangDto = {
        lyDo: values.lyDo,
        hocVienId: selectedHocVien?.id,
        buoiHocId: values.buoiHocId,
      }
      setLoadingFormLyDo(true)
      await HocVienVangServices().addHocVienVang(data)
      setLoadingFormLyDo(false)
      getHocVien({ name, vang: filterValue })
      setSelectedHocVien(undefined)
      setOpenModal(false)
    }
  }

  const onFinishFailed = () => {
    // eslint-disable-next-line no-console
  }

  const handleFilter = (value: string) => {
    setFilterValue(value)
    if (value === 'undefined') {
      searchParams.delete('vang')
    } else {
      searchParams.set('vang', value)
    }
    searchParams.delete('pageId')
    setSearchParams(searchParams)
  }

  const handleChangeCheckBox = async (
    e: CheckboxChangeEvent,
    record: HocVienEntity
  ) => {
    if (e.target.checked) {
      setOpenModal(true)
      setSelectedHocVien(record)
    } else {
      await HocVienVangServices().deleteHocVienVang(record.hocVienVang[0].id)
      getHocVien({ name, vang: filterValue })
    }
  }

  return (
    <div>
      {isLoadingBuoiHoc ? (
        <>
          <Skeleton />
          <Skeleton />
        </>
      ) : buoiHoc ? (
        <>
          <Card title={<b>Khóa đào tạo</b>} style={{ width: '100%' }}>
            <Descriptions>
              <Descriptions.Item label="Tên khóa đào tạo">
                {buoiHoc.khoaDaoTao.tieuDe}
              </Descriptions.Item>
              <Descriptions.Item label="Mô tả khóa đào tạo">
                {buoiHoc.khoaDaoTao.moTa}
              </Descriptions.Item>
              <Descriptions.Item label="Thể loại">
                {buoiHoc.khoaDaoTao.danhMuc.name}
              </Descriptions.Item>
              <Descriptions.Item label="Ngày bắt đầu">
                {dayjs(buoiHoc.khoaDaoTao.ngayBatDau).format('DD-MM-yyyy')}
              </Descriptions.Item>
              <Descriptions.Item label="Ngày kết thúc">
                {dayjs(buoiHoc.khoaDaoTao.ngayKetThuc).format('DD-MM-yyyy')}
              </Descriptions.Item>
            </Descriptions>
          </Card>

          <Card
            title={<b>Thông tin buổi đào tạo</b>}
            style={{ width: '100%', marginTop: 10 }}
          >
            <Descriptions>
              <Descriptions.Item label="Tên buổi học">
                {buoiHoc.tenBuoiHoc || 'Chưa có'}
              </Descriptions.Item>
              <Descriptions.Item label="Mô tả buổi học">
                {buoiHoc.moTaBuoiHoc || 'Chưa có'}
              </Descriptions.Item>
              <Descriptions.Item label="Ngày học">
                {dayjs(buoiHoc.ngayHoc).format('DD-MM-yyyy')}
              </Descriptions.Item>
              <Descriptions.Item label="Giờ bắt đầu">
                {dayjs(buoiHoc.gioBatDau).format('HH:mm')}
              </Descriptions.Item>
              <Descriptions.Item label="Giờ kết thúc">
                {dayjs(buoiHoc.gioKetThuc).format('HH:mm')}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </>
      ) : (
        <Skeleton />
      )}
      <Card
        title={<b>Học ViênBáo vắng</b>}
        style={{ width: '100%', marginTop: 10 }}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setSearchText('')
                navigate(`${location.pathname}`)
              }}
              style={{ marginRight: '16px' }}
            >
              Reset
            </Button>
            <Search
              value={searchText}
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={(value: string) => {
                handleSearch(value)
              }}
              style={{ width: 200, marginRight: '16px' }}
            />
            <Select
              value={filterValue}
              style={{ width: 200, marginRight: '16px' }}
              onChange={handleFilter}
              options={[
                { label: 'Vắng', value: '1' },
                { label: 'Không vắng', value: '0' },
                { label: 'Default', value: 'undefined' },
              ]}
            />
          </Space>
        }
      >
        <Table
          dataSource={hocVienList}
          scroll={{ x: 'max-content' }}
          rowKey={(record: HocVienEntity) => record.nhanVien.id}
          loading={isLoadingHocVien}
          pagination={false}
        >
          <Column
            title="Tên học viên"
            dataIndex={['nhanVien', 'hoTen']}
            key="name"
            render={(text, record: any) => (
              <Link to={`/${NHAN_VIEN_PREFIX_URL}/${record.nhanVien.id}`}>
                {text}
              </Link>
            )}
          />
          <Column
            title="Email"
            dataIndex={['nhanVien', 'email']}
            key="hocvien.email"
          />
          <Column
            title="Số điện thoại"
            dataIndex={['nhanVien', 'soDienThoai']}
            key="hocvien.phone"
          />
          <Column
            title="Địa chỉ"
            dataIndex={['nhanVien', 'diaChi']}
            key="hocvien.address"
          />
          <Column
            title="Lý do vắng"
            key="reason"
            render={(text, record: HocVienEntity) => {
              if (record.hocVienVang.length > 0)
                return record.hocVienVang[0].lyDo
              return ''
            }}
          />
          <Column
            width={120}
            title="Vắng"
            key="vang"
            fixed="right"
            render={(text: string, record: HocVienEntity) => (
              <Checkbox
                checked={record.hocVienVang.length > 0}
                onChange={(e: CheckboxChangeEvent) => {
                  handleChangeCheckBox(e, record)
                }}
              >
                Vắng
              </Checkbox>
            )}
          />
        </Table>
      </Card>

      <Modal
        title="Lý do vắng"
        open={openModal}
        footer={false}
        destroyOnClose
        onCancel={() => {
          setSelectedHocVien(undefined)
          setOpenModal(false)
        }}
      >
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item label="Lý do vắng" name="lyDo">
            <Input.TextArea />
          </Form.Item>

          <Row justify="end">
            <Space>
              <Button
                onClick={() => {
                  setSelectedHocVien(undefined)
                  setOpenModal(false)
                }}
              >
                Bỏ qua
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingFormLyDo}
              >
                OK
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
