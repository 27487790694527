import { message } from 'antd'
import { NavigateFunction } from 'react-router-dom'
import { URL_ENDPOINT_TAI_LIEU_GIAO_AN } from './constants/config.tai-lieu-giao-an'
import axiosClient from '../../../../utils/custom-fetch'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'
import {
  CreateTaiLieuGiaoAnDto,
  TaiLieuGiaoAnEntity,
} from '../../../../server-api-hrm.type'

export default function TaiLieuGiaoAnService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllTaiLieuGiaoAn = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: TaiLieuGiaoAnEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_TAI_LIEU_GIAO_AN, {
      params: query,
    })
    return response
  }

  const addTaiLieuGiaoAn = async (values: CreateTaiLieuGiaoAnDto) => {
    const response = await axios.post<TaiLieuGiaoAnEntity>(
      URL_ENDPOINT_TAI_LIEU_GIAO_AN,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editTaiLieuGiaoAn = async (
    values: CreateTaiLieuGiaoAnDto,
    id: string
  ) => {
    const res = await axios.patch(
      `${URL_ENDPOINT_TAI_LIEU_GIAO_AN}/${id}`,
      values
    )
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const deleteTaiLieuGiaoAn = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_TAI_LIEU_GIAO_AN}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietTaiLieuGiaoAn = async (id: string) => {
    const response = await axios.get<TaiLieuGiaoAnEntity>(
      `${URL_ENDPOINT_TAI_LIEU_GIAO_AN}/${id}`
    )
    return response
  }
  return {
    getAllTaiLieuGiaoAn,
    addTaiLieuGiaoAn,
    editTaiLieuGiaoAn,
    deleteTaiLieuGiaoAn,
    getChitietTaiLieuGiaoAn,
  }
}
