import { useEffect, useState } from 'react'
import { Button, Row, Space, Table, TableColumnsType, Tag } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { useAuth } from 'ict24h-themes-react'
import KhoaDaoTaoService from '../../../khoa-dao-tao/khoa-dao-tao.service'
import {
  MetadataType,
  searchQuery,
} from '../../../../../../utils/metadata.types'
import { KhoaDaoTaoEntity } from '../../../../../../server-api-hrm.type'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

type propsType = {
  thongTinKhoaDaoTao: KhoaDaoTaoEntity | undefined
  // eslint-disable-next-line no-unused-vars
  addThongTinKhoaDaoTao: (khoaDaoTaoId: string) => void
}

function FormKhoaDaoTaoOfKeHoach({
  thongTinKhoaDaoTao,
  addThongTinKhoaDaoTao,
}: propsType) {
  const auth = useAuth()
  const navigate = useNavigate()
  const [data, setData] = useState<KhoaDaoTaoEntity[]>([])
  const [metadata, setMetadata] = useState<MetadataType>()

  const handleAddKhoaDaoTao = async (record: KhoaDaoTaoEntity) => {
    addThongTinKhoaDaoTao(record.id)
  }

  const getAllKhoaDaoTao = async (query?: searchQuery) => {
    const res = await KhoaDaoTaoService(auth.token!, navigate).getAllKhoaDaoTao(
      query
    )
    setData(res.data.data)
    setMetadata(res.data.metadata)
  }

  useEffect(() => {
    getAllKhoaDaoTao()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: TableColumnsType<KhoaDaoTaoEntity> = [
    {
      title: 'Tiêu Đề Khoá Đào Tạo',
      dataIndex: 'tieuDe',
      key: 'name',
      render: (text, record: KhoaDaoTaoEntity) => (
        <Link to={record.id}>{text}</Link>
      ),
    },
    {
      title: 'Mô Tả',
      dataIndex: 'moTa',
      key: 'diachi',
    },
    {
      title: 'Trạng Thái',
      key: 'status',
      dataIndex: 'trangThai',
      render: (text: string, record: KhoaDaoTaoEntity) => {
        if (
          dayjs(record?.ngayKetThuc).isSameOrAfter(dayjs(), 'day') &&
          dayjs(record?.ngayBatDau).isSameOrBefore(dayjs(), 'day')
        ) {
          return <Tag color="green">Active</Tag>
        }
        if (dayjs(record?.ngayBatDau).isAfter(dayjs(), 'day')) {
          return <Tag color="blue">Soon</Tag>
        }
        return <Tag color="red">Expired</Tag>
      },
    },

    {
      title: 'Hình Thức',
      dataIndex: 'hinhThuc',
      key: 'hinhThuc',
    },
    {
      title: 'Loại Hình',
      dataIndex: 'loaiHinh',
      key: 'loaiHinh',
    },
    {
      title: 'Lĩnh Vực Đào Tạo',
      dataIndex: ['linhVucDaoTao', 'name'],
    },
    {
      title: 'Danh Mục',
      dataIndex: ['danhMuc', 'name'],
    },

    {
      title: 'Action',
      fixed: 'right',
      width: '8%',
      render: (record: KhoaDaoTaoEntity) => (
        <Button onClick={() => handleAddKhoaDaoTao(record)}>
          {thongTinKhoaDaoTao ? 'Sửa' : 'Thêm'}
        </Button>
      ),
    },
  ]

  return (
    <>
      <Table
        scroll={{ x: 1100 }}
        size="small"
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={false}
      />
      <Row justify="end" style={{ marginTop: '16px' }}>
        <Space>
          <Button
            disabled={metadata?.paging.cursor.prev.length === 0}
            onClick={async () => {
              if (metadata) {
                getAllKhoaDaoTao({ pageId: metadata?.paging.cursor.prev })
              }
            }}
          >
            Trang trước
          </Button>
          <Button
            disabled={metadata?.paging.cursor.next.length === 0}
            onClick={() => {
              if (metadata) {
                if (metadata) {
                  getAllKhoaDaoTao({ pageId: metadata?.paging.cursor.next })
                }
              }
            }}
          >
            Trang tiếp
          </Button>
        </Space>
      </Row>
    </>
  )
}

export default FormKhoaDaoTaoOfKeHoach
