import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { NGAN_HANG_PREFIX_URL } from './constants/config.ngan-hang'

const ListPage = lazy(() => import('./pages/ListPage.NganHang'))

const NganHangRouters: AppRouterItem[] = [
  {
    path: `${NGAN_HANG_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default NganHangRouters
