import { SidebarLeftLayout } from "ict24h-themes-react";
import type { FC } from "react";
import LeftMenu from "./components/LeftMenu.ChamCong";
import { configHomeAppLayout } from "./constants/config.cham-cong";
import ModuleRouters from "./ChamCongRouters";
import { dockConfig } from "../home/dock-config";

const ChamCongLayout: FC = () => (
  <SidebarLeftLayout menu={<LeftMenu />} headerConfig={null} dock={dockConfig}>
    <ModuleRouters />
  </SidebarLeftLayout>
);

export default ChamCongLayout;
