import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { KE_HOACH_HOC_TAP_PREFIX_URL } from './constants/config.ke-hoach-hoc-tap'
import DetailPageKeHoachHocTap from './pages/DetailPage.KeHoachHocTap'

const ListPage = lazy(() => import('./pages/ListPage.KeHoachHocTap'))

const KeHoachHocTapRouters: AppRouterItem[] = [
  {
    path: `${KE_HOACH_HOC_TAP_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KE_HOACH_HOC_TAP_PREFIX_URL}/:id`,
    element: (
      <LoadingLazyComponents>
        <DetailPageKeHoachHocTap />
      </LoadingLazyComponents>
    ),
  },
]

export default KeHoachHocTapRouters
