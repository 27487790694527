import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { BUOI_HOC_PREFIX_URL } from './constants/config.buoi-hoc'
import { HOC_VIEN_VANG_PREFIX_URL } from './modules/constants/config.hoc-vien-vang'
import HocVienVang from './modules/pages/ListPage.HocVienVang'
import ChiTietBuoiHoc from './pages/ChiTietBuoiHoc'

const ListPage = lazy(() => import('./pages/ListPage.BuoiHoc'))

const BuoiHocRouters: AppRouterItem[] = [
  {
    path: `${BUOI_HOC_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${BUOI_HOC_PREFIX_URL}/:id`,
    element: (
      <LoadingLazyComponents>
        <ChiTietBuoiHoc />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${BUOI_HOC_PREFIX_URL}/:buoiHocId${HOC_VIEN_VANG_PREFIX_URL}`,
    element: (
      <LoadingLazyComponents>
        <HocVienVang />
      </LoadingLazyComponents>
    ),
  },
]

export default BuoiHocRouters
