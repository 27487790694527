import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { KE_HOACH_KPI_PREFIX_URL } from './constants/config.ke-hoach-kpi'

const ToiTaoKeHoachKpi = lazy(() => import('./pages/ToiTao.KeHoachKpi'))
const ChoToiDuyetKeHoachKpi = lazy(
  () => import('./pages/ChoToiDuyet.KeHoachKpi')
)
const CapKpiKeHoachKpi = lazy(() => import('./pages/CapKpi.KeHoachKpi'))
const ChiTietKeHoachKpiDisplay = lazy(
  () => import('./components/ChiTietKeHoachKpiDisplay')
)
const KeHoachKpiRouters: AppRouterItem[] = [
  {
    path: `${KE_HOACH_KPI_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ToiTaoKeHoachKpi />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KE_HOACH_KPI_PREFIX_URL}/toi-tao`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ToiTaoKeHoachKpi />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KE_HOACH_KPI_PREFIX_URL}/cho-toi-duyet`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ChoToiDuyetKeHoachKpi />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KE_HOACH_KPI_PREFIX_URL}/cap-kpi/:capKpiId`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CapKpiKeHoachKpi />
      </LoadingLazyComponents>
    ),
  },
  // {
  //   path: `${CAP_KPI_PREFIX_URL}/:id/edit`,
  //   element: (
  //     <LoadingLazyComponents>
  //       <EditPageMauKpi />
  //     </LoadingLazyComponents>
  //   ),
  // },
  {
    path: `${KE_HOACH_KPI_PREFIX_URL}/:id/view`,
    element: (
      <LoadingLazyComponents>
        <ChiTietKeHoachKpiDisplay />
      </LoadingLazyComponents>
    ),
  },
]

export default KeHoachKpiRouters
