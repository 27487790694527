import React from 'react'
import { Breadcrumb, Card } from 'antd'
import { Link } from 'react-router-dom'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { HOME_APP_PREFIX_URL } from '../../home-app/constants/config.home-app'
import { QUAN_LY_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PATH } from '../constants/config.dieu-dong-luan-chuyen-nhan-su'
import { KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL } from '../modules/ke-hoach-dieu-dong-luan-chuyen-nhan-su/constants/config.ke-hoach-dieu-dong-luan-chuyen-nhan-su'
import { PHIEU_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL } from '../modules/phieu-dieu-dong-luan-chuyen-nhan-su/constants/config.phieu-dieu-dong-luan-chuyen-nhan-su'

const gridStyle: React.CSSProperties = {
  width: '25%',
  textAlign: 'center',
}

function ListModules() {
  return (
    <div style={{ paddingTop: 16 }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={HOME_APP_PREFIX_URL}>
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <UserOutlined />
          Danh mục modules
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title="Danh sách modules cấu hình thông tin"
        style={{ marginTop: 16 }}
      >
        <Card.Grid style={gridStyle}>
          <Link
            to={`${
              QUAN_LY_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PATH +
              KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL
            }/my-approval`}
          >
            Kế hoạch điều động luân chuyển nhân sự
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={`${
              QUAN_LY_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PATH +
              PHIEU_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL
            }/my-approval`}
          >
            Phiếu điều động luân chuyển nhân sự
          </Link>
        </Card.Grid>
      </Card>
    </div>
  )
}

export default ListModules
