import { LoadingLazyComponents, NotFoundPage } from 'ict24h-themes-react'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import mapRouterHelper from '../../utils/MapRouterHelper'
import { CHAM_CONG_PREFIX_URL } from './constants/config.cham-cong'
import CaLamRouters from './modules/ca-lam/CaLamRouters'
import NgayLeRouters from './modules/ngay-le/NgayLeRouters'
import QuanLyChamCongRouters from './modules/quan-ly-cham-cong/QuanLyChamCongRouters'
import ListModules from './pages/ListModules.ChamCong'

const CauHinhChamCongRouters: FC = () => (
  <Routes>
    <Route
      path={`${CHAM_CONG_PREFIX_URL}/`}
      element={
        <LoadingLazyComponents>
          <ListModules />
        </LoadingLazyComponents>
      }
    />
    {mapRouterHelper(NgayLeRouters)}
    {mapRouterHelper(CaLamRouters)}
    {mapRouterHelper(QuanLyChamCongRouters)}
    {/* Fallback Not found pages */}
    <Route
      path="*"
      element={
        <LoadingLazyComponents>
          <NotFoundPage />
        </LoadingLazyComponents>
      }
    />
  </Routes>
)

export default CauHinhChamCongRouters
