import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const CAP_KPI_PREFIX_URL = '/cap-kpi'
export const CAP_KPI_CHI_TIET_PREFIX_URL = '/cap-kpi-chi-tiet'

export const URL_ENDPOINT_CAP_KPI = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/cap-kpi`

export const URL_ENDPOINT_CAP_KPI_CHI_TIET = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/cap-kpi-chi-tiet`

export const configMauKpiLayout: HeaderLayoutConfig = {
  appName: ' Thông tin danh sách cấp KPI',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
