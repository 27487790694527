import { Button, DatePicker, Form, Input, InputNumber, message } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { CreateThongTinBaiTestDto } from '../../../../../server-api-hrm.type'

type propsType = {
  // eslint-disable-next-line react/require-default-props
  thongTinBaiTest?: CreateThongTinBaiTestDto
  // eslint-disable-next-line no-unused-vars
  onSubmit: (value: any) => void
  loading: boolean
}

export default function FormThongTinBaiTest({
  thongTinBaiTest,
  onSubmit,
  loading,
}: propsType) {
  const [form] = Form.useForm()
  const { RangePicker } = DatePicker

  useEffect(() => {
    if (thongTinBaiTest) {
      form.setFieldsValue({
        tieuDe: thongTinBaiTest.tieuDe,
        valueDate: [
          dayjs(thongTinBaiTest?.ngayBatDau),
          dayjs(thongTinBaiTest?.ngayKetThuc),
        ],
        thoiGianLamBai: thongTinBaiTest.thoiGianLamBai,
      })
    }
    // eslint-disable-next-line
  }, [])

  const onFinish = (values: {
    tieuDe: string
    valueDate: [string, string]
    thoiGianLamBai: number
  }) => {
    const data: CreateThongTinBaiTestDto = {
      ngayBatDau: dayjs(values.valueDate[0]).toISOString(),
      ngayKetThuc: dayjs(values.valueDate[1]).toISOString(),
      tieuDe: values.tieuDe,
      thoiGianLamBai: values.thoiGianLamBai,
    }
    onSubmit(data)
  }

  const onFinishFailed = () => {
    message.error('Something went wrong')
    // eslint-disable-next-line no-console
  }

  return (
    <Form
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label="Tiêu đề"
        name="tieuDe"
        rules={[{ required: true, message: 'Hãy nhập tên bài test!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Ngày bắt đầu và ngày kết thúc"
        name="valueDate"
        rules={[{ required: true, message: 'Hãy nhập ngày bắt đầu!' }]}
      >
        <RangePicker />
      </Form.Item>

      <Form.Item
        label="Thời gian làm bài"
        name="thoiGianLamBai"
        rules={[{ required: true, message: 'Hãy thời gian làm bài!' }]}
      >
        <InputNumber addonAfter="phút" />
      </Form.Item>
      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          {thongTinBaiTest ? 'Sửa' : 'Thêm'}
        </Button>
      </Form.Item>
    </Form>
  )
}
