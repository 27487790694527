import React from 'react'
import { Breadcrumb, Card } from 'antd'
import { Link } from 'react-router-dom'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { HOME_APP_PREFIX_URL } from '../../home-app/constants/config.home-app'
import { QUAN_LY_DAO_TAO_PREFIX_URL } from '../constants/config.quan-ly-dao-tao'
import { DANH_MUC_PREFIX_URL } from '../modules/danh-muc/constants/config.danh-muc'
import { LINH_VUC_PREFIX_URL } from '../modules/linh-vuc/constants/config.linh-vuc'
import { HOC_VIEN_PREFIX_URL } from '../modules/hoc-vien/constants/config.hoc-vien'
import { TAI_LIEU_GIAO_AN_PREFIX_URL } from '../modules/tai-lieu-giao-an/constants/config.tai-lieu-giao-an'
import { DE_XUAT_KHOA_DAO_TAO_PREFIX_URL } from '../modules/de-xuat-khoa-dao-tao/constants/config.de-xuat-khoa-dao-tao'
import { THONG_TIN_BAI_TEST_PREFIX_URL } from '../modules/bai-kiem-tra/constants/config.bai-test'
import { DON_VI_DAO_TAO_PREFIX_URL } from '../modules/don-vi-dao-tao/constants/config.don-vi-dao-tao'
import { BAI_KIEM_TRA_CUA_USER_PREFIX_URL } from '../modules/test-trac-nghiem/constants/config.bai-kiem-tra-cua-user'
import { KE_HOACH_HOC_TAP_PREFIX_URL } from '../modules/ke-hoach-hoc-tap/constants/config.ke-hoach-hoc-tap'
import { KHOA_DAO_TAO_PREFIX_URL } from '../modules/khoa-dao-tao/constants/config.khoa-dao-tao'

const gridStyle: React.CSSProperties = {
  width: '25%',
  textAlign: 'center',
}

function ListModules() {
  return (
    <div style={{ paddingTop: 16 }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={HOME_APP_PREFIX_URL}>
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <UserOutlined />
          Danh mục modules
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title="Danh sách modules cấu hình thông tin"
        style={{ marginTop: 16 }}
      >
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_DAO_TAO_PREFIX_URL + DANH_MUC_PREFIX_URL}>
            Danh mục đào tạo
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_DAO_TAO_PREFIX_URL + LINH_VUC_PREFIX_URL}>
            Lĩnh vực đào tạo
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_DAO_TAO_PREFIX_URL + HOC_VIEN_PREFIX_URL}>
            Học viên khóa học
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_DAO_TAO_PREFIX_URL + TAI_LIEU_GIAO_AN_PREFIX_URL}>
            Tài liệu giáo án
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={QUAN_LY_DAO_TAO_PREFIX_URL + DE_XUAT_KHOA_DAO_TAO_PREFIX_URL}
          >
            Đề xuất khoá đào tạo
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_DAO_TAO_PREFIX_URL + THONG_TIN_BAI_TEST_PREFIX_URL}>
            Thông tin bài test
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_DAO_TAO_PREFIX_URL + DON_VI_DAO_TAO_PREFIX_URL}>
            Đơn vị đào tạo
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={QUAN_LY_DAO_TAO_PREFIX_URL + BAI_KIEM_TRA_CUA_USER_PREFIX_URL}
          >
            Bài kiểm tra
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_DAO_TAO_PREFIX_URL + KE_HOACH_HOC_TAP_PREFIX_URL}>
            Kế hoạch học tập
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_DAO_TAO_PREFIX_URL + KHOA_DAO_TAO_PREFIX_URL}>
            Khoá đào tạo
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_DAO_TAO_PREFIX_URL + TAI_LIEU_GIAO_AN_PREFIX_URL}>
            Tài liệu giáo án
          </Link>
        </Card.Grid>
      </Card>
    </div>
  )
}

export default ListModules
