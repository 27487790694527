import { NeedAuthLoadingLazy } from "ict24h-themes-react";
import { Route } from "react-router-dom";
import { AppRouterItem } from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";

export const mapRouterHelper = (routers: AppRouterItem[], prefix?: string) => {
  if (!routers) return null;

  return routers.map(({ path, element }) => {
    const fullPath = prefix ? prefix + path : path;
    return (
      <Route
        path={fullPath}
        element={<NeedAuthLoadingLazy children={element} />}
        key={fullPath}
      />
    );
  });
};

export default mapRouterHelper;
