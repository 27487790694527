import { lazy } from 'react'
import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { KE_HOACH_TUYEN_DUNG_URL } from '../../constants/config.quan-ly-tuyen-dung'

const MyCreatedApprovalKeHoachTuyenDungList = lazy(
  () => import('./pages/MyCreatedApproval.KeHoachTuyenDungList')
)
const WaitingApprovalKeHoachTuyenDungList = lazy(
  () => import('./pages/WaitingApproval.KeHoachTuyenDungList')
)
const ApprovedKeHoachTuyenDungList = lazy(
  () => import('./pages/Approved.KeHoachTuyenDungList')
)
const CreateKeHoachTuyenDungPage = lazy(
  () => import('./pages/Create.KeHoachTuyenDungPage')
)
const ViewKeHoachTuyenDungPage = lazy(
  () => import('./pages/View.KeHoachTuyenDungPage')
)
const EditKeHoachTuyenDungPage = lazy(
  () => import('./pages/Edit.KeHoachTuyenDungPage')
)
const CreateChiTietKeHoachTuyenDungPage = lazy(
  () =>
    import('./pages/ChiTietKeHoachTuyenDung/Create.ChiTietKeHoachTuyenDungPage')
)
const ViewChiTietKeHoachTuyenDungPage = lazy(
  () =>
    import('./pages/ChiTietKeHoachTuyenDung/View.ChiTietKeHoachTuyenDungPage')
)
const EditChiTietKeHoachTuyenDungPage = lazy(
  () =>
    import('./pages/ChiTietKeHoachTuyenDung/Edit.ChiTietKeHoachTuyenDungPage')
)
const ApprovedChiTietKeHoachTuyenDungList = lazy(
  () =>
    import(
      './pages/ChiTietKeHoachTuyenDung/Approved.ChiTietKeHoachTuyenDungList'
    )
)

const KeHoachTuyenDungRouters: AppRouterItem[] = [
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <MyCreatedApprovalKeHoachTuyenDungList />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/my-created-approval`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <MyCreatedApprovalKeHoachTuyenDungList />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/waiting-approval`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <WaitingApprovalKeHoachTuyenDungList />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/approved`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ApprovedKeHoachTuyenDungList />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/create`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CreateKeHoachTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/:keHoachTuyenDungId/view`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ViewKeHoachTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/:keHoachTuyenDungId/edit`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <EditKeHoachTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/:keHoachTuyenDungId/chi-tiet/create`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CreateChiTietKeHoachTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/:keHoachTuyenDungId/chi-tiet/:chiTietKeHoachTuyenDungId/view`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ViewChiTietKeHoachTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/:keHoachTuyenDungId/chi-tiet/:chiTietKeHoachTuyenDungId/edit`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <EditChiTietKeHoachTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${KE_HOACH_TUYEN_DUNG_URL}/approved-chi-tiet-ke-hoach-tuyen-dung`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ApprovedChiTietKeHoachTuyenDungList />
      </LoadingLazyComponents>
    ),
  },
]

export default KeHoachTuyenDungRouters
