import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const NGAN_HANG_PREFIX_URL = '/ngan-hang'
export const URL_ENDPOINT_NGAN_HANG = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/ngan-hang`

export const configNganHangLayout: HeaderLayoutConfig = {
  appName: ' Thông tin ngân hàng ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
