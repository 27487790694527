import { LoadingLazyComponents, NotFoundPage } from 'ict24h-themes-react'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import mapRouterHelper from '../../utils/MapRouterHelper'
import BaiTestRouters from './modules/bai-kiem-tra/BaiTestRouters'
import DanhMucRouters from './modules/danh-muc/DanhMucRouters'
import DeXuatKhoaDaoTaoRouters from './modules/de-xuat-khoa-dao-tao/DeXuatKhoaDaoTaoRouters'
import DonViDaoTaoRouters from './modules/don-vi-dao-tao/DonViDaoTaoRouters'
import HocVienRouters from './modules/hoc-vien/HocVienRouters'
import KeHoachHocTapRouters from './modules/ke-hoach-hoc-tap/KeHoachHocTapRouters'
import KhoaDaoTaoRouters from './modules/khoa-dao-tao/KhoaDaoTaoRouters'
import LinhVucRouters from './modules/linh-vuc/LinhVucRouters'
import NganHangCauHoiRouters from './modules/ngan-hang-cau-hoi/NganHangCauHoiRouters'
import TaiLieuGiaoAnRouters from './modules/tai-lieu-giao-an/TaiLieuGiaoAnRouters'
import BaiKiemTraCuaUserRouters from './modules/test-trac-nghiem/BaiKiemTraCuaUserRouters'
import ListModules from './pages/ListModules.QuanLyDaoTao'

const QuanLyDaoTaoRouters: FC = () => (
  <Routes>
    <Route
      path="/"
      element={
        <LoadingLazyComponents>
          <ListModules />
        </LoadingLazyComponents>
      }
    />
    {mapRouterHelper(DanhMucRouters)}
    {mapRouterHelper(LinhVucRouters)}
    {mapRouterHelper(HocVienRouters)}
    {mapRouterHelper(TaiLieuGiaoAnRouters)}
    {mapRouterHelper(DeXuatKhoaDaoTaoRouters)}
    {mapRouterHelper(BaiTestRouters)}
    {mapRouterHelper(DonViDaoTaoRouters)}
    {mapRouterHelper(BaiKiemTraCuaUserRouters)}
    {mapRouterHelper(KeHoachHocTapRouters)}
    {mapRouterHelper(KhoaDaoTaoRouters)}
    {mapRouterHelper(NganHangCauHoiRouters)}
    {/* Fallback Not found pages */}
    <Route
      path="*"
      element={
        <LoadingLazyComponents>
          <NotFoundPage />
        </LoadingLazyComponents>
      }
    />
  </Routes>
)

export default QuanLyDaoTaoRouters
