import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { KHOA_DAO_TAO_PREFIX_URL } from './constants/config.khoa-dao-tao'
import BuoiHocRouters from './modules/buoi-hoc/BuoiHocRouters'
import HocVienRouters from './modules/hoc-vien/HocVienRouters'
import TaiLieuGiaoAnRouters from './modules/tai-lieu-giao-an/TaiLieuGiaoAnRouters'
import ThongTinBaiTestRouters from './modules/thong-tin-bai-test/ThongTinBaiTestRouters'

const ListPage = lazy(() => import('./pages/ListPage.KhoaDaoTao'))
const DetailPage = lazy(() => import('./pages/DetailPage.KhoaDaoTao'))

const HocVienTungKhoaPath = HocVienRouters.map((it) => ({
  ...it,
  path: `${KHOA_DAO_TAO_PREFIX_URL}/:id${it.path}`,
}))

const BuoiHocTungKhoaPath = BuoiHocRouters.map((it) => ({
  ...it,
  path: `${KHOA_DAO_TAO_PREFIX_URL}/:id${it.path}`,
}))
const TaiLieuGiaoAnTungKhoaPath = TaiLieuGiaoAnRouters.map((it) => ({
  ...it,
  path: `${KHOA_DAO_TAO_PREFIX_URL}/:id${it.path}`,
}))
const BaiKiemTraTungKhoaPath = ThongTinBaiTestRouters.map((it) => ({
  ...it,
  path: `${KHOA_DAO_TAO_PREFIX_URL}/:id${it.path}`,
}))
const KhoaDaoTaoRouters: AppRouterItem[] = [
  {
    path: `${KHOA_DAO_TAO_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KHOA_DAO_TAO_PREFIX_URL}/:id`,
    element: (
      <LoadingLazyComponents>
        <DetailPage />
      </LoadingLazyComponents>
    ),
  },
  ...HocVienTungKhoaPath,
  ...BuoiHocTungKhoaPath,
  ...TaiLieuGiaoAnTungKhoaPath,
  ...BaiKiemTraTungKhoaPath,
]

export default KhoaDaoTaoRouters
