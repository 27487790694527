import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { THU_VIEN_KPI_PREFIX_URL } from './constants/config.thu-vien-kpi'

const ListPage = lazy(() => import('./pages/ListPage.ThuVienKpi'))
// const EditPageMauKpi = lazy(() => import('./pages/EditPage.MauKpi'))
const DeatailPageThuVienKpi = lazy(
  () => import('./pages/DeatailPage.ThuVienKpi')
)

const ThuVienKpiRouters: AppRouterItem[] = [
  {
    path: `${THU_VIEN_KPI_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  // {
  //   path: `${CAP_KPI_PREFIX_URL}/:id/edit`,
  //   element: (
  //     <LoadingLazyComponents>
  //       <EditPageMauKpi />
  //     </LoadingLazyComponents>
  //   ),
  // },
  {
    path: `${THU_VIEN_KPI_PREFIX_URL}/:id/view`,
    element: (
      <LoadingLazyComponents>
        <DeatailPageThuVienKpi />
      </LoadingLazyComponents>
    ),
  },
]

export default ThuVienKpiRouters
