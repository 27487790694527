import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const NGAY_LE_PREFIX_URL = '/ngay-le'
export const URL_ENDPOINT_NGAY_LE = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/ngay-le`

export const configNgayLeLayout: HeaderLayoutConfig = {
  appName: ' Thông tin ngày lễ ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
