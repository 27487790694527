import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { CHI_DINH_KPI_PREFIX_URL } from './constants/config.chi-dinh-kpi'

const CuaToiChiDinhKpi = lazy(() => import('./pages/CuaToi.ChiDinhKpi'))
const ChiTietChiDinhKpi = lazy(() => import('./pages/ChiTiet.ChiDinhKpi'))

const ChiDinhKpiRouters: AppRouterItem[] = [
  {
    path: `${CHI_DINH_KPI_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CuaToiChiDinhKpi />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${CHI_DINH_KPI_PREFIX_URL}/cua-toi`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CuaToiChiDinhKpi />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${CHI_DINH_KPI_PREFIX_URL}/:chiDinhKpiId/view`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ChiTietChiDinhKpi />
      </LoadingLazyComponents>
    ),
  },
]

export default ChiDinhKpiRouters
