import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const CHI_DINH_KPI_PREFIX_URL = '/chi-dinh-kpi'
export const URL_ENDPOINT_CHI_DINH_KPI = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/chi-dinh-kpi`

export const configMauKpiLayout: HeaderLayoutConfig = {
  appName: 'Chỉ định KPI',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
