import { SidebarLeftLayout } from "ict24h-themes-react";
import type { FC } from "react";
import LeftMenu from "./components/LeftMenu.QuanLyDaoTao";
import { configHomeAppLayout } from "./constants/config.quan-ly-dao-tao";
import ModuleRouters from "./QuanLyDaoTaoRouters";
import { dockConfig } from "../home/dock-config";

const HomeAppLayout: FC = () => (
  <SidebarLeftLayout menu={<LeftMenu />} headerConfig={null} dock={dockConfig}>
    <ModuleRouters />
  </SidebarLeftLayout>
);

export default HomeAppLayout;
