import { LoadingLazyComponents, NotFoundPage } from "ict24h-themes-react";
import React from "react";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { mapRouterHelper } from "../../utils/MapRouterHelper";
import { QUAN_LY_PHE_DUYET_PREFIX_URL } from "./constants/config.quan-ly-phe-duyet";
import { CapBacNhanVienRouters } from "./modules/cap-bac-nhan-vien/CapBacNhanVienRouters";
import { DeNghiHoanUngRouters } from "./modules/de-nghi-hoan-ung/DeNghiHoanUngRouters";
import { DeNghiMuaSamRouters } from "./modules/de-nghi-mua-sam/DeNghiMuaSamRouters";
import { DeNghiThanhToanRouters } from "./modules/de-nghi-thanh-toan/DeNghiThanhToanRouters";
import { DonViRouters } from "./modules/don-vi/DonViRouters";
import { TamUngNoiBoRouters } from "./modules/tam-ung-noi-bo/TamUngNoiBoRouters";
import { TienTeRouters } from "./modules/tien-te/TienTeRouters";
import ListModules from "./pages/ListModules.QuanLyPheDuyet";
import { LoaiPhieuThanhToanRouters } from "./modules/loai-phieu-thanh-toan/LoaiPhieuThanhToanRouters";
import { NhaCungCapRouters } from "./modules/nha-cung-cap/NhaCungCapRoutersRouters";

const QuanLyPheDuyetRouters: FC = () => {
  return (
    <Routes>
      <Route
        path={ "/"}
        element={<LoadingLazyComponents children={<ListModules />} />}
      />
      {mapRouterHelper(TienTeRouters)}
      {mapRouterHelper(DonViRouters)}
      {mapRouterHelper(CapBacNhanVienRouters)}
      {mapRouterHelper(TamUngNoiBoRouters)}
      {mapRouterHelper(DeNghiHoanUngRouters)}
      {mapRouterHelper(DeNghiMuaSamRouters)}
      {mapRouterHelper(DeNghiThanhToanRouters)}
      {mapRouterHelper(LoaiPhieuThanhToanRouters)}
      {mapRouterHelper(NhaCungCapRouters)}
      {/* Fallback Not found pages */}
      <Route
        path="*"
        element={<LoadingLazyComponents children={<NotFoundPage />} />}
      />
      <Route
        path="/not-found"
        element={<LoadingLazyComponents children={<NotFoundPage />} />}
      />
    </Routes>
  );
};

export default QuanLyPheDuyetRouters;
