import { SidebarLeftLayout } from "ict24h-themes-react";
import type { FC } from "react";
import LeftMenu from "./components/LeftMenu.QuanLyTuyenDung";
import { configQuanLyTuyenDungLayout } from "./constants/config.quan-ly-tuyen-dung";
import ModuleRouters from "./QuanLyTuyenDungRouters";
import { dockConfig } from "../home/dock-config";

const QuanLyTuyenDungLayout: FC = () => (
  <SidebarLeftLayout menu={<LeftMenu />} headerConfig={null} dock={dockConfig}>
    <ModuleRouters />
  </SidebarLeftLayout>
);

export default QuanLyTuyenDungLayout;
