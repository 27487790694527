import { LoadingLazyComponents } from "ict24h-themes-react";
// import { AppRouterItem } from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";
import React from "react";
import { lazy } from "react";
import { DON_VI_PREFIX_URL } from "./constants/config.don-vi";
import {AppRouterItem} from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";

const ListPage = lazy(() => import("./pages/ListPage.DonVi"));

export const DonViRouters: AppRouterItem[] = [
  {
    path: DON_VI_PREFIX_URL + "/",
    element: <LoadingLazyComponents children={<ListPage />} />,
  },
];
