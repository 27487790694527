import React from 'react'
import { Breadcrumb, Card } from 'antd'
import { Link } from 'react-router-dom'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { CHAM_CONG_PREFIX_URL } from '../constants/config.cham-cong'
import { CA_LAM_PREFIX_URL } from '../modules/ca-lam/constants/config.ca-lam'
import { NGAY_LE_PREFIX_URL } from '../modules/ngay-le/constants/config.ngay-le'
import { QUAN_LY_CHAM_CONG_PREFIX_URL } from '../modules/quan-ly-cham-cong/constants/config.quan-ly-cham-cong'
import { HOME_APP_PREFIX_URL } from '../../home-app/constants/config.home-app'

const gridStyle: React.CSSProperties = {
  width: 'calc(100%/3)',
  textAlign: 'center',
}

function ListModules() {
  return (
    <div style={{ paddingTop: 16 }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={HOME_APP_PREFIX_URL}>
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <UserOutlined />
          Danh mục modules
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card title="Danh sách modules chấm công" style={{ marginTop: 16 }}>
        <Card.Grid style={gridStyle}>
          <Link to={CHAM_CONG_PREFIX_URL + CA_LAM_PREFIX_URL}>Ca làm việc</Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CHAM_CONG_PREFIX_URL + NGAY_LE_PREFIX_URL}>Ngày lễ</Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CHAM_CONG_PREFIX_URL + QUAN_LY_CHAM_CONG_PREFIX_URL}>
            Quản lý chấm công
          </Link>
        </Card.Grid>
      </Card>
    </div>
  )
}

export default ListModules
