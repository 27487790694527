import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import axios from "axios";
import getGraphAccessToken from "../../utils/get-graph-token";

const { Dragger } = Upload;

const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/sites/",
  SITE_ID =
    "i24h.sharepoint.com,29a988ae-350b-451f-8266-43b280d2713f,9bdee716-4c6b-42f4-9673-5094d8f78236";

/*
      VUE_APP_SP_SITE_URL=sites/dev2/customer-view/efc
      VUE_APP_SP_FOLDER_ROOT=ProjectAttachments
    */

/*
NOTE: cách để upload file lên sharepoint
B1: call api để lấy url upload
B2: call api upload file lên url đó (thuộc tính bên trên trả về: uploadUrl)
B3: truyền uploadUrl vào prop bên dưới
*/

const props: UploadProps = {
  name: "file",
  multiple: true,
  // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
  maxCount: 2, // số file tối đa được upload
  customRequest: async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) => {
    const graphAccessToken = await getGraphAccessToken();

    const uploadUrl = await getUploadSessionUrl(graphAccessToken!, filename);

    // @ts-ignore
    const { size } = file;
    console.log("file", size, file, data);
    const headersPutFile = {
      Authorization: "Bearer " + graphAccessToken,
      "Content-Range": `bytes 0-${+size - 1}/${size}`,
    };

    axios
      .put(uploadUrl, file, {
        headers: headersPutFile,
        onUploadProgress: ({ total, loaded }) => {
          // @ts-ignore
          onProgress(
            { percent: Math.round((loaded / total!) * 100).toFixed(2) },
            // @ts-ignore
            file
          );
        },
      })
      .then(({ data: response }) => {
        // @ts-ignore
        onSuccess(response, file);
      })
      .catch(onError);

    return {
      abort() {
        console.log("upload progress is aborted.");
      },
    };
  },
};

const UploadComponent: React.FC = () => {
  const [actionUrl, setActionUrl] = useState<string>("");

  const beforeUploadHandler = async () => {
    // const graphAccessToken = await getGraphAccessToken();
    // const uploadUrl = await getUploadSessionUrl(
    //   undefined,
    //   graphAccessToken!,
    //   "test.txt"
    // );
    // setActionUrl(uploadUrl);
  };

  return (
    <Dragger {...props} action={actionUrl}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      {/* <p className="ant-upload-text">Click or drag file to this area to upload</p> */}
      <p className="ant-upload-text">
        Bấm hoặc kéo file vào vùng này để upload
      </p>
      <p className="ant-upload-hint">Hỗ trợ tải lên 1 hoặc nhiều file</p>
    </Dragger>
  );
};

export default UploadComponent;

/**
 * NOTE: cần chuyển upload trực tiếp lên SP thành upload lên server của mình, sau đó upload lên SP
 * cần check lại link folder, mình đang set cứng
 * ở chỗ folder path có thể chuyển hẳn sang ID luôn,
 * khi di chuyển code cũ sang code mới sẽ cần làm cả 1 phần gọi là fallback
 * nếu ko có dữ liệu ở folder mới thì sẽ lấy dữ liệu ở folder cũ theo cách fallback
 * @param accessToken
 * @param fileName
 * @returns
 */

// https://graph.microsoft.com/v1.0/sites/i24h.sharepoint.com,29a988ae-350b-451f-8266-43b280d2713f,9bdee716-4c6b-42f4-9673-5094d8f78236/drive/root:/contract/282b7646-7952-4b6a-9aee-68472a96ad3f/attachmentFiles/Test1.docx:/createUploadSession
async function getUploadSessionUrl(accessToken: string, fileName?: string) {
  const urlFolderUpload = `${GRAPH_ENDPOINT}${SITE_ID}/drive/root:/ProjectAttachments/hoa-test-new/${fileName}:/createUploadSession`;
  //Test1.docx:/createUploadSession
  const { data } = await axios({
    method: "POST",
    url: urlFolderUpload,
    data: {
      item: {
        "@microsoft.graph.conflictBehavior": "rename",
      },
      // fileSystemInfo: { "@odata.type": "microsoft.graph.fileSystemInfo" },
      // name: fileName,
    },
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  });
  return data?.uploadUrl;
}
