import { message } from 'antd'
import { NavigateFunction } from 'react-router-dom'
import { URL_ENDPOINT_PHONG_BAN } from './constants/config.phong-ban'
import axiosClient from '../../../../utils/custom-fetch'
import {
  CreatePhongBanDto,
  PhongBanEntity,
  UpdatePhongBanDto,
} from '../../../../server-api-hrm.type'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'

export default function PhongBanService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllPhongBan = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: PhongBanEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_PHONG_BAN, {
      params: query,
    })
    return response
  }

  const addPhongBan = async (values: CreatePhongBanDto) => {
    const response = await axios.post<PhongBanEntity>(
      URL_ENDPOINT_PHONG_BAN,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editPhongBan = async (values: UpdatePhongBanDto, id: string) => {
    const res = await axios.patch(`${URL_ENDPOINT_PHONG_BAN}/${id}`, values)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const deletePhongBan = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_PHONG_BAN}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietPhongBan = async (id: string) => {
    const response = await axios.get<PhongBanEntity>(
      `${URL_ENDPOINT_PHONG_BAN}/${id}`
    )
    return response
  }

  return {
    getAllPhongBan,
    addPhongBan,
    editPhongBan,
    deletePhongBan,
    getChitietPhongBan,
  }
}
