import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { DON_VI_DAO_TAO_PREFIX_URL } from './constants/config.don-vi-dao-tao'
import GiangVienBenNgoaiRouters from './modules/giang-vien-ben-ngoai/GiangVienBenNgoaiRouters'
import GiangVienNoiBoRouters from './modules/giang-vien-noi-bo/GiangVienNoiBoRouters'

const ListPage = lazy(() => import('./pages/ListPage.DonViDaoTao'))
const DetailPage = lazy(() => import('./pages/DetailPage.DonViDaoTao'))

const GiangVienNoiBoPath = GiangVienNoiBoRouters.map((it) => ({
  ...it,
  path: `${DON_VI_DAO_TAO_PREFIX_URL}/:id${it.path}`,
}))

const GiangVienBenNgoaiPath = GiangVienBenNgoaiRouters.map((it) => ({
  ...it,
  path: `${DON_VI_DAO_TAO_PREFIX_URL}/:id${it.path}`,
}))

const DonViDaoTaoRouters: AppRouterItem[] = [
  {
    path: `${DON_VI_DAO_TAO_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${DON_VI_DAO_TAO_PREFIX_URL}/:id`,
    element: (
      <LoadingLazyComponents>
        <DetailPage />
      </LoadingLazyComponents>
    ),
  },
  ...GiangVienNoiBoPath,
  ...GiangVienBenNgoaiPath,
]

export default DonViDaoTaoRouters
