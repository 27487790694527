import { MenuProps, Menu, Badge } from "antd";
import { FC, useState, useEffect, createElement } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserOutlined, DollarCircleOutlined } from "@ant-design/icons";
import {
  LIST_MENU_DE_NGHI_HOAN_UNG,
  LIST_MENU_DE_NGHI_MUA_SAM,
  LIST_MENU_DE_NGHI_THANH_TOAN,
  LIST_MENU_OTHERS,
  LIST_MENU_TAM_UNG_NOI_BO,
} from "../constants/list-menu.quan-ly-phe-duyet";
import { useAuth } from "ict24h-themes-react";

const LeftMenuHomeApp: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = useAuth().token;

  const [selectedKey, setSelectedKey] = useState<string>("");

  // Tự highlight menu đúng với đường dẫn
  useEffect(() => {
    if (pathname) {
      setSelectedKey(pathname);
    }
  }, [pathname]);

  // tạo menu fake mẫu
  const items2: MenuProps["items"] = [];

  // Thêm list menu = chương trình

  items2.unshift({
    key: "other3",
    icon: <UserOutlined />,
    label: "Others",
    children: LIST_MENU_OTHERS.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  items2.unshift({
    key: "other4",
    icon: <UserOutlined />,
    label: "Đề nghị mua sắm",
    children: LIST_MENU_DE_NGHI_MUA_SAM.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  items2.unshift({
    key: "other2",
    icon: <UserOutlined />,
    label: "Đề nghị hoàn ứng",
    children: LIST_MENU_DE_NGHI_HOAN_UNG.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  items2.unshift({
    key: "other5",
    icon: <DollarCircleOutlined />,
    label: "Đề nghị thanh toán",
    children: LIST_MENU_DE_NGHI_THANH_TOAN.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  items2.unshift({
    key: "other1",
    icon: <UserOutlined />,
    label: "Tạm ứng nội bộ",
    children: LIST_MENU_TAM_UNG_NOI_BO.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={["other1"]}
      style={{ height: "100%", minHeight: "89vh", borderRight: 0 }}
      items={items2}
      selectedKeys={[selectedKey]}
    />
  );
};

export default LeftMenuHomeApp;
