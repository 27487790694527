import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { CHUC_VU_PREFIX_URL } from './constants/config.chuc-vu'

const ListPage = lazy(() => import('./pages/ListPage.ChucVu'))

const ChucVuRouters: AppRouterItem[] = [
  {
    path: `${CHUC_VU_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default ChucVuRouters
