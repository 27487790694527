export const URL_ENDPOINT_TAM_UNG_NOI_BO = `${
  process.env.REACT_APP_ENDPOINT_URL
}/tam-ung-noi-bo`;

export const TAM_UNG_NOI_BO_PREFIX_URL = "/tam-ung-noi-bo";

export const URL_ENDPOINT_CHI_TIET_TAM_UNG_NOI_BO = `${
  process.env.REACT_APP_ENDPOINT_URL
}/chi-tiet-tam-ung-noi-bo`;

export const URL_ENDPOINT_CHI_TIET_TAM_UNG_NOI_BO_APPROVAL_LOG = `${
  process.env.REACT_APP_ENDPOINT_URL
}/tam-ung-noi-bo-approval-log`;

export const CHI_TIET_TAM_UNG_NOI_BO_PREFIX_URL = "/chi-tiet-tam-ung-noi-bo";
