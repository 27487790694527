import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const DANH_GIA_KPI_PATH = '/danh-gia-kpi'

export const DANH_GIA_KPI_PREFIX_URL = '/danh-gia-kpi'

export const configHomeAppLayout: HeaderLayoutConfig = {
  appName: 'Đánh giá KPI nhân viên, phòng ban, chi nhánh',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
