import { lazy } from 'react'
import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { YEU_CAU_TUYEN_DUNG_URL } from '../../constants/config.quan-ly-tuyen-dung'

const YeuCauTuyenDung = lazy(
  () => import('./pages/MyApproval.YeuCauTuyenDungList')
)
const MyApprovalYeuCauTuyenDungList = lazy(
  () => import('./pages/MyApproval.YeuCauTuyenDungList')
)
const ApprovedYeuCauTuyenDungList = lazy(
  () => import('./pages/Approved.YeuCauTuyenDungList')
)
const WaitingApprovalYeuCauTuyenDungList = lazy(
  () => import('./pages/WaitingApproval.YeuCauTuyenDungList')
)
const CreateYeuCauTuyenDungPage = lazy(
  () => import('./pages/Create.YeuCauTuyenDungPage')
)
const ViewYeuCauTuyenDungPage = lazy(
  () => import('./pages/View.YeuCauTuyenDungPage')
)
const EditYeuCauTuyenDungPage = lazy(
  () => import('./pages/Edit.YeuCauTuyenDungPage')
)

const YeuCauTuyenDungRouters: AppRouterItem[] = [
  {
    path: `/${YEU_CAU_TUYEN_DUNG_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <YeuCauTuyenDung />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${YEU_CAU_TUYEN_DUNG_URL}/my-approval`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <MyApprovalYeuCauTuyenDungList />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${YEU_CAU_TUYEN_DUNG_URL}/waiting-approval`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <WaitingApprovalYeuCauTuyenDungList />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${YEU_CAU_TUYEN_DUNG_URL}/approved`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ApprovedYeuCauTuyenDungList />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${YEU_CAU_TUYEN_DUNG_URL}/create`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CreateYeuCauTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${YEU_CAU_TUYEN_DUNG_URL}/:yeuCauTuyenDungId/edit`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <EditYeuCauTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${YEU_CAU_TUYEN_DUNG_URL}/:yeuCauTuyenDungId/view`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ViewYeuCauTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
]

export default YeuCauTuyenDungRouters
