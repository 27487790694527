import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { THONG_TIN_BAI_TEST_PREFIX_URL } from './constants/config.bai-test'
import DetailPageChiTietBaiTest from './pages/DeatilPage.ChiTietBaiTest'

const ListPage = lazy(() => import('./pages/ListPage.BaiTest'))

const BaiTestRouters: AppRouterItem[] = [
  {
    path: `${THONG_TIN_BAI_TEST_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${THONG_TIN_BAI_TEST_PREFIX_URL}/:id`,
    element: (
      <LoadingLazyComponents>
        <DetailPageChiTietBaiTest />
      </LoadingLazyComponents>
    ),
  },
]

export default BaiTestRouters
