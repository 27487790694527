import { message } from 'antd'

import { NavigateFunction } from 'react-router-dom'
import axiosClient from '../../../../../../utils/custom-fetch'
import { URL_ENDPOINT_KHOA_DAO_TAO } from '../../constants/config.khoa-dao-tao'
import { URL_ENDPOINT_BUOI_HOC } from './constants/config.buoi-hoc'
import {
  MetadataType,
  searchQuery,
} from '../../../../../../utils/metadata.types'
import {
  BuoiHocEntity,
  CreateBuoihocDto,
  KhoaDaoTaoEntity,
  UpdateBuoihocDto,
} from '../../../../../../server-api-hrm.type'

export default function BuoiHocService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllBuoiHoc = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: BuoiHocEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_BUOI_HOC, {
      params: query,
    })
    return response
  }

  const getChitietBuoiHoc = async (id: string) => {
    const response = await axios.get<BuoiHocEntity>(
      `${URL_ENDPOINT_BUOI_HOC}/${id}`
    )
    return response
  }

  const getKhoaId = async (id: string) => {
    const response = await axios.get<KhoaDaoTaoEntity>(
      `${URL_ENDPOINT_KHOA_DAO_TAO}/${id}`
    )
    return response
  }

  const addBuoiHoc = async (values: CreateBuoihocDto) => {
    const response = await axios.post<BuoiHocEntity>(
      URL_ENDPOINT_BUOI_HOC,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editBuoiHoc = async (values: UpdateBuoihocDto, id: string) => {
    const res = await axios.patch(`${URL_ENDPOINT_BUOI_HOC}/${id}`, values)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getDeleteBuoiHoc = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_BUOI_HOC}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const URL = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/tai-lieu-giao-an`

  const getchAllTaiLieuOfBuoiHoc = async (id: string) => {
    const res = await axios.get(`${URL}?buoiHocId=${id}`)
    return res
  }

  const addTaiLieuBuoiHoc = async (
    // token: string,
    // object: { buoiHocId: string; taiLieuGiaoAnId: string },
    query: searchQuery
  ) => {
    const res = await axios.post(
      `${URL_ENDPOINT_BUOI_HOC}/tai-lieu-giao-an`,
      query
    )
    return res
  }

  const deleteTaiLieuBuoiHoc = async (
    buoiHocId: string,
    taiLieuGiaoAnId: string
  ) => {
    const res = await axios.delete(
      `${URL_ENDPOINT_BUOI_HOC}/${buoiHocId}/tai-lieu-giao-an/${taiLieuGiaoAnId}`
    )
    return res
  }

  return {
    getKhoaId,
    deleteTaiLieuBuoiHoc,
    addTaiLieuBuoiHoc,
    getchAllTaiLieuOfBuoiHoc,
    getAllBuoiHoc,
    getChitietBuoiHoc,
    getDeleteBuoiHoc,
    addBuoiHoc,
    editBuoiHoc,
  }
}
