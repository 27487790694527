import React from 'react'
import { Breadcrumb, Card } from 'antd'
import { Link } from 'react-router-dom'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { HOME_APP_PREFIX_URL } from '../../home-app/constants/config.home-app'
import { CAU_HINH_THONG_TIN_PREFIX_URL } from '../constants/config.cau-hinh-thong-tin'
import { CHE_DO_BAO_HIEM_PREFIX_URL } from '../modules/che-do-bao-hiem-xa-hoi/constants/config.che-do-bao-hiem-xa-hoi'
import { CHI_NHANH_PREFIX_URL } from '../modules/chi-nhanh/constants/config.chi-nhanh'
import { CHUC_VU_PREFIX_URL } from '../modules/chuc-vu/constants/config.chuc-vu'
import { LOAI_NGHI_PHEP_PREFIX_URL } from '../modules/loai-nghi-phep/constants/config.loai-nghi-phep'
import { NGAN_HANG_PREFIX_URL } from '../modules/ngan-hang/constants/config.ngan-hang'
import { NGOAI_NGU_PREFIX_URL } from '../modules/ngoai-ngu/constants/config.ngoai-ngu'
import { NHAN_VIEN_PREFIX_URL } from '../modules/nhan-vien/constants/config.nhan-vien'
import { PHONG_BAN_PREFIX_URL } from '../modules/phong-ban/constants/config.phong-ban'
import { QUOC_GIA_PREFIX_URL } from '../modules/quoc-gia/constants/config.quoc-gia'
import { XIN_NGHI_PHEP_PREFIX_URL } from '../modules/xin-nghi-phep/constants/config.xin-nghi-phep'

const gridStyle: React.CSSProperties = {
  width: '25%',
  textAlign: 'center',
}

function ListModules() {
  return (
    <div style={{ paddingTop: 16 }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={HOME_APP_PREFIX_URL}>
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <UserOutlined />
          Danh mục modules
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title="Danh sách modules cấu hình thông tin"
        style={{ marginTop: 16 }}
      >
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + NHAN_VIEN_PREFIX_URL}>
            Nhân viên
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + CHI_NHANH_PREFIX_URL}>
            Chi nhánh
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + PHONG_BAN_PREFIX_URL}>
            Phòng ban
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + CHUC_VU_PREFIX_URL}>
            Chức vụ
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + NGOAI_NGU_PREFIX_URL}>
            Ngoại ngữ
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + QUOC_GIA_PREFIX_URL}>
            Quốc gia
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + CHE_DO_BAO_HIEM_PREFIX_URL}>
            Chế độ bảo hiểm
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + LOAI_NGHI_PHEP_PREFIX_URL}>
            Loại nghỉ phép
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + XIN_NGHI_PHEP_PREFIX_URL}>
            Xin nghỉ phép
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={CAU_HINH_THONG_TIN_PREFIX_URL + NGAN_HANG_PREFIX_URL}>
            Ngân hàng
          </Link>
        </Card.Grid>
      </Card>
    </div>
  )
}

export default ListModules
