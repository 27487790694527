export const dateFormatMain = "DD/MM/YYYY";
export const dateFormatSecond = "DD/MM/YYYY hh:mm:ss";
export const dateFormatThird = "DD/MM/YYYY hh:mm";

export const dateFormatShort = "DD/MM/YYYY";
export const dateFormatLong = "DD/MM/YYYY hh:mm:ss";
export const dateFormatMedium = "DD/MM/YYYY hh:mm";

export const weekday = [
  "",
  "Thứ hai",
  "Thứ ba",
  "Thứ tư",
  "Thứ năm",
  "Thứ sáu",
  "Thứ bảy",
  "Chủ nhật",
];
