import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const QUAN_LY_CHAM_CONG_PREFIX_URL = '/cham-cong'
export const URL_ENDPOINT_QUAN_LY_CHAM_CONG = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/cham-cong`

export const configQuanLyChamCongLayout: HeaderLayoutConfig = {
  appName: ' Thông tin Quản lý chấm công ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
