import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { GIANG_VIEN_NOI_BO_PREFIX_URL } from './constants/config.giang-vien-noi-bo'

const ListPage = lazy(() => import('./pages/ListPage.GiangVienNoiBo'))

const GiangVienNoiBoRouters: AppRouterItem[] = [
  {
    path: `${GIANG_VIEN_NOI_BO_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default GiangVienNoiBoRouters
