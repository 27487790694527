import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const LOAI_NGHI_PHEP_PREFIX_URL = '/loai-nghi-phep'
export const URL_ENDPOINT_LOAI_NGHI_PHEP = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/loai-nghi-phep`

export const configLoaiNghiPhepLayout: HeaderLayoutConfig = {
  appName: ' Thông tin loại nghỉ phép ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',

  color: '#000',
}
