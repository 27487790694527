import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const NHAN_VIEN_PREFIX_URL = '/nhan-vien'
export const URL_ENDPOINT_NHAN_VIEN = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/nhan-vien`

export const configNhanVienLayout: HeaderLayoutConfig = {
  appName: ' Thông tin nhân viên ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
