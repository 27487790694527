import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const NHOM_TIEU_CHI_DANH_GIA_KPI_PREFIX_URL =
  '/nhom-tieu-chi-danh-gia-kpi'
export const NHOM_TIEU_CHI_DANH_GIA_KPI_CHI_TIET_PREFIX_URL =
  '/nhom-tieu-chi-danh-gia-kpi-chi-tiet'

export const URL_ENDPOINT_NHOM_TIEU_CHI_DANH_GIA_KPI = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/nhom-tieu-chi-danh-gia-kpi`

export const URL_ENDPOINT_NHOM_TIEU_CHI_DANH_GIA_KPI_CHI_TIET = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/nhom-tieu-chi-danh-gia-kpi-chi-tiet`

export const configMauKpiLayout: HeaderLayoutConfig = {
  appName: ' Thông tin nhóm tiêu chí đánh giá',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
