import { message } from 'antd'
import { NavigateFunction } from 'react-router-dom'
import axiosClient from '../../../../utils/custom-fetch'
import { URL_ENDPOINT_THONG_TIN_BAI_TEST } from './constants/config.bai-test'
import {
  BaiTestEntity,
  CreateThongTinBaiTestDto,
  ThongTinBaiTestEntity,
  UpdateThongTinBaiTestDto,
} from '../../../../server-api-hrm.type'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'

export default function ThongTinBaiTestService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllThongTinBaiTest = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: ThongTinBaiTestEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_THONG_TIN_BAI_TEST, {
      params: query,
    })
    return response
  }

  const addThongTinBaiTest = async (values: CreateThongTinBaiTestDto) => {
    const response = await axios.post<BaiTestEntity>(
      URL_ENDPOINT_THONG_TIN_BAI_TEST,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editThongTinBaiTest = async (
    values: UpdateThongTinBaiTestDto,
    id: string
  ) => {
    const res = await axios.patch(
      `${URL_ENDPOINT_THONG_TIN_BAI_TEST}/${id}`,
      values
    )
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const deleteThongTinBaiTest = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_THONG_TIN_BAI_TEST}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietThongTinBaiTest = async (id: string) => {
    const response = await axios.get<ThongTinBaiTestEntity>(
      `${URL_ENDPOINT_THONG_TIN_BAI_TEST}/${id}`
    )
    return response
  }

  // tìm thông tin bài test user chưa làm
  const getChuaLam = async (query?: searchQuery) => {
    const response = await axios.get(
      `${URL_ENDPOINT_THONG_TIN_BAI_TEST}/chua-lam`,
      {
        params: query,
      }
    )
    return response
  }

  // tìm thông tin bài test user chưa làm
  const getDaLam = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: ThongTinBaiTestEntity[]
      metadata: MetadataType
    }>(`${URL_ENDPOINT_THONG_TIN_BAI_TEST}/da-lam`, {
      params: query,
    })
    return response
  }

  const addCauHoiInToThongTinBaiTest = async (
    // thongTinBaiTestId: string,
    // cauHoiId: string,
    // token: string,
    // object: { thongTinBaiTestId: string; cauHoiId: string }
    query: searchQuery
  ) => {
    const res = await axios.post(
      `${URL_ENDPOINT_THONG_TIN_BAI_TEST}/cau-hoi`,
      query
    )
    return res
  }

  const removeCauHoiFromThongTin = async (
    thongTinBaiTestId: string,
    cauHoiId: string
  ) => {
    const res = await axios.delete(
      `${URL_ENDPOINT_THONG_TIN_BAI_TEST}/${thongTinBaiTestId}/cau-hoi/${cauHoiId}`
    )
    return res
  }

  return {
    addCauHoiInToThongTinBaiTest,
    removeCauHoiFromThongTin,
    getChuaLam,
    getDaLam,
    getAllThongTinBaiTest,
    addThongTinBaiTest,
    editThongTinBaiTest,
    deleteThongTinBaiTest,
    getChitietThongTinBaiTest,
  }
}
