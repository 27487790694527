import React from "react";
import { FileText } from "react-feather";
import { SiderbarLeftLayoutInterface } from "ict24h-themes-react/lib/esm/layouts/SidebarLeftLayout";

export const dockConfig: SiderbarLeftLayoutInterface["dock"] = {
  items: [
    [
      {
        icon: (
          <img
            src="https://img.icons8.com/?size=32&id=103798&format=png"
            width={32}
            alt="Portal"
          />
        ),
        title: "Portal",
        link: "/",
      },
      {
        icon: (
          <img
            src="https://img.icons8.com/?size=32&id=twPPoi69TEic&format=png"
            width={32}
            alt="icon phê duyệt"
          />
        ),
        title: "Phê duyệt",
        link: "/quan-ly-phe-duyet/tam-ung-noi-bo/my-approval",
      },
      {
        icon: (
          <img
            src="https://img.icons8.com/?size=64&id=qDNClnB7Z4Ky&format=png"
            width={32}
            alt="icon Nhân viên"
          />
        ),
        title: "Nhân viên",
        link: "/cau-hinh-thong-tin/nhan-vien",
      },
      {
        icon: (
          <img
            src="https://img.icons8.com/?size=64&id=-Es6TVTSyruJ&format=png"
            width={40}
            alt="icon Đào tạo"
          />
        ),
        title: "Đào tạo",
        link: "/quan-ly-dao-tao/khoa-dao-tao",
      },

      {
        icon: (
          <img
            src="https://img.icons8.com/?size=64&id=DN2tvEZ6n36K&format=png"
            width={32}
            alt="icon Chấm công"
          />
        ),
        title: "Chấm công",
        link: "/cau-hinh-cham-cong/cham-cong",
      },
      {
        icon: (
          <img
            src="https://img.icons8.com/?size=64&id=nzGGOW4zBEbj&format=png"
            width={32}
            alt="icon Điều chuyển"
          />
        ),
        title: "Điều chuyển nhân sự",
        link: "/quan-ly-dieu-dong-luan-chuyen-nhan-su",
      },
      {
        icon: (
          <img
            src="https://img.icons8.com/?size=32&id=4tSyQosZoCw3&format=png"
            width={32}
            alt="icon KPI"
          />
        ),
        title: "KPI",
        link: "/danh-gia-kpi/dashboard-kpi",
      },
      {
        icon: (
          <img
            src="https://img.icons8.com/?size=32&id=HzqNEMUSstpY&format=png"
            width={32}
            alt="Quy trình"
          />
        ),
        title: "CH Quy trình",
        link: "/config-workflows",
      },

      // {
      //   icon: <FileText />,
      //   title: "workflow",
      //   link: "/embed-iframe/workflow",
      // },

      // {
      //   icon: <FileText />,
      //   title: "Files",
      //   link: "/sp-files",
      // },
    ],
  ],
};
