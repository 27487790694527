export const URL_ENDPOINT_CAU_HOI = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/cau-hoi`

export const CAU_HOI_PREFIX_URL = '/cau-hoi'

export const URL_ENDPOINT_CAU_TRA_LOI = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/cau-tra-loi`

export const CAU_TRA_LOI_PREFIX_URL = '/cau-tra-loi'

export const URL_ENDPOINT_NGAN_HANG_CAU_HOI = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/ngan-hang-cau-hoi`

export const NGAN_HANG_CAU_HOI_PREFIX_URL = '/ngan-hang-cau-hoi'
