import { HeaderLayoutConfig } from "ict24h-themes-react/lib/esm/components/UI/Header/Header";

export const QUAN_TRI_DANH_MUC_CHUNG_PATH = "quan-tri-danh-muc-chung";
export const QUAN_TRI_DANH_MUC_CHUNG_PREFIX_URL =
  "/pms/quan-tri-danh-muc-chung";

export const configQuanTriDanhMucChungLayout: HeaderLayoutConfig = {
  appName: "Quản lý dự án Chủ đầu tư",
  logoLink: "https://ict24h.net",
  backgroundColor: "#454791",
  color: "#000",
};
