import { LoadingLazyComponents } from "ict24h-themes-react";
import { AppRouterItem } from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";
import { lazy } from "react";
import { DE_NGHI_THANH_TOAN_PREFIX_URL } from "./constants/config.de-nghi-thanh-toan";
import './global-style.css'

const MyPaymentRequestList = lazy(() => import("./pages/MyPaymentRequestList"))
const WaitingPaymentRequestList = lazy(() => import("./pages/WaitingPaymentRequestList"))
const OldPaymentRequestList = lazy(() => import("./pages/OldPaymentRequestList"))
const EditPaymentRequest = lazy(() => import("./pages/EditPaymentRequest/EditPaymentRequest"))
const DetailPaymentRequest = lazy(() => import("./pages/DetailPaymentRequest/DetailPaymentRequest"))
const NewPaymentRequest = lazy(() => import("./pages/NewPaymentRequest/NewPaymentRequest"))

export const DeNghiThanhToanRouters: AppRouterItem[] = [
  {
    path: DE_NGHI_THANH_TOAN_PREFIX_URL + "/*",
    element: <LoadingLazyComponents children={<MyPaymentRequestList />} loadingType='skeleton' />
  },
  {
    path: DE_NGHI_THANH_TOAN_PREFIX_URL + "/my-approval",
    element: <LoadingLazyComponents children={<MyPaymentRequestList />} loadingType='skeleton' />
  },
  {
    path: DE_NGHI_THANH_TOAN_PREFIX_URL + "/waiting-approval",
    element: <LoadingLazyComponents children={<WaitingPaymentRequestList />} loadingType='skeleton' />
  },
  {
    path: DE_NGHI_THANH_TOAN_PREFIX_URL + "/my-act-approval",
    element: <LoadingLazyComponents children={<OldPaymentRequestList />} loadingType='skeleton' />
  },
  {
    path: DE_NGHI_THANH_TOAN_PREFIX_URL + "/:requestId/view",
    element: <LoadingLazyComponents children={<DetailPaymentRequest />} loadingType='skeleton' />
  },
  {
    path: DE_NGHI_THANH_TOAN_PREFIX_URL + "/:requestId/edit",
    element: <LoadingLazyComponents children={<EditPaymentRequest />} loadingType='skeleton' />
  },
  {
    path: DE_NGHI_THANH_TOAN_PREFIX_URL + "/create",
    element: <LoadingLazyComponents children={<NewPaymentRequest />} loadingType='skeleton' />
  }
]
