import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { CHUNG_MINH_KPI_PREFIX_URL } from './constants/config.chung-minh-kpi'

const ChoToiDuyetChungMinhKpi = lazy(
  () => import('./pages/ChoToiDuyet.ChungMinhKpi')
)
const ChiTietChungMinhKpi = lazy(() => import('./pages/ChiTiet.ChungMinhKpi'))

const ChungMinhKpiRouters: AppRouterItem[] = [
  {
    path: `${CHUNG_MINH_KPI_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ChoToiDuyetChungMinhKpi />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${CHUNG_MINH_KPI_PREFIX_URL}/cho-toi-chung-minh`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ChoToiDuyetChungMinhKpi />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${CHUNG_MINH_KPI_PREFIX_URL}/:tieuChiDanhGiaKpiId`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ChiTietChungMinhKpi />
      </LoadingLazyComponents>
    ),
  },
]

export default ChungMinhKpiRouters
