import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { DANH_MUC_PREFIX_URL } from './constants/config.danh-muc'

const ListPage = lazy(() => import('./pages/ListPage.DanhMuc'))

const DanhMucRouters: AppRouterItem[] = [
  {
    path: `${DANH_MUC_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default DanhMucRouters
