import PortalLayout from "./modules/portal/PortalLayout";
import { AppRouterItem } from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";
import { LoadingLazyComponents, NotFoundPage } from "ict24h-themes-react";
import HomeDashboard from "./modules/home/HomeDashboard";
import SpFilesHome from "./modules/sp-files/SpFiles.home";
import QuanLyPheDuyetLayout from "./modules/quan-ly-phe-duyet/QuanLyPheDuyetLayout";
import { QUAN_LY_PHE_DUYET_PREFIX_URL } from "./modules/quan-ly-phe-duyet/constants/config.quan-ly-phe-duyet";
import { CAU_HINH_THONG_TIN_PREFIX_URL } from "./modules/cau-hinh-thong-tin/constants/config.cau-hinh-thong-tin";
import CauHinhThongTinLayout from "./modules/cau-hinh-thong-tin/CauHinhThongTinLayout";
import { QUAN_LY_DAO_TAO_PREFIX_URL } from "./modules/quan-ly-dao-tao/constants/config.quan-ly-dao-tao";
import QuanLyDaoTaoLayout from "./modules/quan-ly-dao-tao/QuanLyDaoTaoLayout";
import { CHAM_CONG_PREFIX_URL } from "./modules/cham-cong/constants/config.cham-cong";
import ChamCongLayout from "./modules/cham-cong/ChamCongLayout";
import { QUAN_LY_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PATH } from "./modules/dieu-dong-luan-chuyen-nhan-su/constants/config.dieu-dong-luan-chuyen-nhan-su";
import QuanLyDieuDongLuanChuyenNhanSuLayout from "./modules/dieu-dong-luan-chuyen-nhan-su/DieuDongLuanChuyenNhanSuLayout";
import { DANH_GIA_KPI_PATH } from "./modules/danh-gia-kpi/constants/config.danh-gia-kpi";
import KpiLayout from "./modules/danh-gia-kpi/KpiLayout";
import { QUAN_LY_TUYEN_DUNG_PREFIX_URL } from "./modules/quan-ly-tuyen-dung/constants/config.quan-ly-tuyen-dung";
import QuanLyTuyenDungLayout from "./modules/quan-ly-tuyen-dung/QuanLyTuyenDungLayout";
import ConfigWorkflowLayout from "./modules/config-workflows/ConfigWorkflowLayout";
import QuanLyDuAnDauTuLayout from "./modules/quan-ly-du-an-dau-tu/QuanLyDuAnDauTuLayout";
import ExtComponent from "./modules/external/index";

import LoginTeams from "./modules/Components/TeamsConsent/Login";
import ConsentPopup from "./modules/Components/TeamsConsent/ConsentPopup";
import ClosePopup from "./modules/Components/TeamsConsent/ClosePopup";

import EmbedIframe from "./components/EmbedIframe";
import { CONFIG_WORKFLOW_APP_PREFIX_URL } from "./modules/config-workflows/constants/config.home-app";

const AppRouters: AppRouterItem[] = [
  {
    path: `/portal/*`,
    element: <PortalLayout />,
  },
  {
    path: `/pms/*`,
    element: <QuanLyDuAnDauTuLayout />,
  },
  {
    path: `/home`,
    element: <HomeDashboard />,
  },
  {
    path: `/sp-files/*`,
    element: <SpFilesHome />,
  },
  {
    path: `/ext/*`,
    element: <ExtComponent />,
  },
  {
    path: `/sp-files/*`,
    element: <SpFilesHome />,
  },
  {
    path: `/${QUAN_LY_PHE_DUYET_PREFIX_URL}/*`,
    element: <QuanLyPheDuyetLayout />,
  },

  // Start: HRM

  {
    path: `/${CAU_HINH_THONG_TIN_PREFIX_URL}/*`,
    element: <CauHinhThongTinLayout />,
  },
  {
    path: `/${QUAN_LY_DAO_TAO_PREFIX_URL}/*`,
    element: <QuanLyDaoTaoLayout />,
  },
  {
    path: `/${CHAM_CONG_PREFIX_URL}/*`,
    element: <ChamCongLayout />,
  },
  {
    path: `/${QUAN_LY_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PATH}/*`,
    element: <QuanLyDieuDongLuanChuyenNhanSuLayout />,
  },
  {
    path: `/${DANH_GIA_KPI_PATH}/*`,
    element: <KpiLayout />,
  },
  {
    path: `/${QUAN_LY_TUYEN_DUNG_PREFIX_URL}/*`,
    element: <QuanLyTuyenDungLayout />,
  },
  {
    path: `${CONFIG_WORKFLOW_APP_PREFIX_URL}/*`,
    element: <ConfigWorkflowLayout />,
    isNotNeedAuth: true,
  },
  {
    path: `/login-m365`,
    element: <LoginTeams />,
    isNotNeedAuth: true,
  },
  {
    path: `/login-m365-auth-start`,
    element: <ConsentPopup />,
    isNotNeedAuth: true,
  },
  {
    path: `/login-m365-auth-end`,
    element: <ClosePopup />,
    isNotNeedAuth: true,
  },
  {
    path: `/embed-iframe/workflow`,
    element: (
      <EmbedIframe src="https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/approval/default.aspx#/approval" />
    ),
  },
  // End: HRM
  {
    path: "*",
    element: <LoadingLazyComponents children={<NotFoundPage />} />,
  },
];

export default AppRouters;
