/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Card,
  Descriptions,
  Drawer,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Table,
} from 'antd'
import { useEffect, useState } from 'react'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import Search from 'antd/lib/input/Search'
import dayjs from 'dayjs'
import { useAuth } from 'ict24h-themes-react'
import TaiLieuGiaoAnService from '../../../../tai-lieu-giao-an/tai-lieu-giao-an.service'
import BuoiHocService from '../buoi-hoc.service'
import { HOC_VIEN_VANG_PREFIX_URL } from '../modules/constants/config.hoc-vien-vang'
import FormBuoiHoc from '../components/FormBuoiHoc'
import {
  BuoiHocEntity,
  TaiLieuGiaoAnEntity,
} from '../../../../../../../server-api-hrm.type'
import DrawerTaiLieuBuoiHoc from '../modules/components/DrawerTaiLieuBuoiHoc'
import { searchQuery } from '../../../../../../../utils/metadata.types'

export interface TableParams {
  name: string | null
  filterValue: string | null
  sortValue: string | null
  buoiHocId: string | undefined
}

function ChiTietBuoiHoc() {
  const url = window.location.href
  const buoiHocId = url.split('/buoi-hoc/').pop()

  const auth = useAuth()

  const [buoiHoc, setBuoiHoc] = useState<BuoiHocEntity>()
  const [taiLieu, setTaiLieu] = useState<TaiLieuGiaoAnEntity[]>()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const [name, setName] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')
  const location = useLocation()
  const navigate = useNavigate()
  const [isModalEditBuoiHoc, setIsModalEditBuoiHoc] = useState<boolean>(false)

  const getThongTinTaiLieuOfBuoiHoc = async (query: searchQuery) => {
    setIsLoading(true)
    const data = await TaiLieuGiaoAnService(
      auth.token!,
      navigate
    ).getAllTaiLieuGiaoAn(query)
    setTaiLieu(data.data.data)
    setIsLoading(false)
  }

  useEffect(() => {
    const nameUrl = searchParams.get('name')
    const query: searchQuery = {}
    if (nameUrl) {
      setName(nameUrl)
      setSearchText(nameUrl)
      query.name = nameUrl
    } else {
      setName('')
      setSearchText('')
    }
    setSearchParams(searchParams, { replace: true })
    if (buoiHocId) {
      getThongTinTaiLieuOfBuoiHoc({ buoiHocId, ...query })
    } // eslint-disable-next-line
  }, [location.search, buoiHocId])

  // chi tiet buoi hoc
  const chitietBuoiHoc = async (buoiHocIdParam: string) => {
    const data = await BuoiHocService(auth.token!, navigate).getChitietBuoiHoc(
      buoiHocIdParam
    )
    if (data) setBuoiHoc(data.data)
  }

  useEffect(() => {
    if (buoiHocId) {
      chitietBuoiHoc(buoiHocId)
    }
    // eslint-disable-next-line
  }, [buoiHocId])

  const handleEdit = async (values: any) => {
    if (buoiHoc) {
      setIsLoading(true)
      await BuoiHocService(auth.token!, navigate).editBuoiHoc(
        values,
        `${buoiHocId}`
      )
      if (buoiHocId) chitietBuoiHoc(buoiHocId)
      setIsLoading(false)
      setIsModalEditBuoiHoc(false)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  // them tai lieu giao an vao buoi hoc
  const handleAddTaiLieuBuoiHoc = async (taiLieuGiaoAnId: string) => {
    const abc = {
      taiLieuGiaoAnId,
      buoiHocId: `${buoiHocId}`,
    }
    if (buoiHocId) {
      const res = await BuoiHocService(auth.token!, navigate).addTaiLieuBuoiHoc(
        abc
      )
      if (res)
        getThongTinTaiLieuOfBuoiHoc({
          name,
          buoiHocId,
        })
    }
  }

  // xoa tai lieu cua tung khoa hoc
  const confirmDelete = async (record: TaiLieuGiaoAnEntity) => {
    if (buoiHocId) {
      const res = await BuoiHocService(
        auth.token!,
        navigate
      ).deleteTaiLieuBuoiHoc(buoiHocId, record.id)
      if (res)
        getThongTinTaiLieuOfBuoiHoc({
          name,
          buoiHocId,
        })
    }
  }

  const handleSearch = (search: string) => {
    if (search.length > 0) {
      searchParams.set('name', search)
    } else {
      searchParams.delete('name')
    }
    setSearchParams(searchParams, { replace: true })
  }

  const columns: ColumnsType<TaiLieuGiaoAnEntity> = [
    {
      title: 'Tài Liệu Giáo Án',
      dataIndex: 'ten',
      key: 'ten',
      width: '20%',
    },
    {
      title: 'Liên Kết',
      dataIndex: 'link',
      width: '30%',
    },
    {
      title: 'Mô Tả',
      dataIndex: 'moTa',
      width: '30%',
    },
    {
      title: 'Action',
      render: (text, record: TaiLieuGiaoAnEntity) => (
        <Space>
          <Popconfirm
            title="Bạn có chắc chắn là muốn xóa?"
            onConfirm={() => confirmDelete(record)}
            okText="Có"
            cancelText="Không"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]
  return (
    <div style={{ paddingTop: 16 }}>
      <Card
        title={<b>Chi tiết Buổi Học</b>}
        style={{ width: '100%' }}
        extra={
          <Button
            onClick={() => {
              setIsModalEditBuoiHoc(true)
            }}
          >
            <EditOutlined />
          </Button>
        }
      >
        {isLoading ? (
          <Skeleton />
        ) : (
          <Descriptions>
            <Descriptions.Item label="Tên Buổi Học">
              <text>{buoiHoc?.tenBuoiHoc}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Mô Tả Buổi Học">
              <text>{buoiHoc?.moTaBuoiHoc}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Ngày Học">
              <text>{`${dayjs(buoiHoc?.ngayHoc).format('MMM Do YY')}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Giờ Bắt Đầu">
              <text>{`${dayjs(buoiHoc?.gioBatDau).format('h:mm:ss')}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Giờ Kết Thúc">
              <text>{`${dayjs(buoiHoc?.gioKetThuc).format('h:mm:ss')}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Báo vắng buổi học">
              <Link to={HOC_VIEN_VANG_PREFIX_URL.substring(1)}>Báo vắng</Link>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>

      <Card
        title={<b>Tài Liệu Giáo Án Cho Buổi Học</b>}
        style={{ width: '100%', marginTop: 10 }}
        extra={
          <Button
            onClick={() => {
              setIsModalEditBuoiHoc(true)
            }}
          >
            <EditOutlined />
          </Button>
        }
      >
        <Row justify="end" style={{ marginBottom: '8px' }}>
          <Button
            type="primary"
            onClick={() => {
              setSearchText('')
              navigate(`${location.pathname}`)
            }}
            style={{ marginRight: '16px' }}
          >
            Reset
          </Button>
          <Search
            value={searchText}
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={(value: string) => {
              handleSearch(value)
            }}
            style={{ width: 200, marginRight: '16px' }}
          />

          <Button type="primary" onClick={showModal}>
            <PlusOutlined />
            Thêm
          </Button>
        </Row>
        <Table
          size="small"
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={taiLieu}
          loading={isLoading}
          pagination={false}
        />
      </Card>
      <DrawerTaiLieuBuoiHoc
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        addTaiLieuBuoiHoc={handleAddTaiLieuBuoiHoc}
      />
      <Drawer
        title="Sửa Thông Tin Buổi Học"
        open={isModalEditBuoiHoc}
        onClose={() => {
          setIsModalEditBuoiHoc(false)
        }}
        footer={null}
        destroyOnClose
      >
        <FormBuoiHoc
          onSubmit={handleEdit}
          buoihoc={buoiHoc}
          khoadId={undefined}
        />
      </Drawer>
    </div>
  )
}

export default ChiTietBuoiHoc
