import { LoadingLazyComponents } from "ict24h-themes-react";
import React from "react";
import { lazy } from "react";
import { TAM_UNG_NOI_BO_PREFIX_URL } from "./constants/config.tam-ung-noi-bo";
import {AppRouterItem} from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";

const DetailPage = lazy(() => import("./pages/DetailPage.TamUngNoiBo"));
const PreviewPage = lazy(() => import("./pages/PreviewPage.TamUngNoiBo"));
const DanhSachPhieuToiTaoPage = lazy(
  () => import("./pages/DanhSachPhieuToiTao.TamUngNoiBo")
);
const CreatePage = lazy(() => import("./pages/CreatePage.TamUngNoiBo"));
const EditPage = lazy(() => import("./pages/EditPage.TamUngNoiBo"));
const MyWaitingApprovalPage = lazy(
  () => import("./pages/DanhSachPhieuChoToiDuyet.TamUngNoiBo")
);
const MyApprovedApprovalPage = lazy(
  () => import("./pages/DanhSachPhieuToiThamGiaPheDuyet.TamUngNoiBo")
);

export const TamUngNoiBoRouters: AppRouterItem[] = [
  {
    path: TAM_UNG_NOI_BO_PREFIX_URL + "/create",
    element: (
      <LoadingLazyComponents children={<CreatePage />} loadingType="skeleton" />
    ),
  },

  {
    path: TAM_UNG_NOI_BO_PREFIX_URL + "/:id" + "/edit",
    element: (
      <LoadingLazyComponents children={<EditPage />} loadingType="skeleton" />
    ),
  },
  {
    path: TAM_UNG_NOI_BO_PREFIX_URL + "/my-approval",
    element: (
      <LoadingLazyComponents
        children={<DanhSachPhieuToiTaoPage />}
        loadingType="skeleton"
      />
    ),
  },
  {
    path: TAM_UNG_NOI_BO_PREFIX_URL + "/:id" + "/view",
    element: (
      <LoadingLazyComponents children={<DetailPage />} loadingType="skeleton" />
    ),
  },
  {
    path: TAM_UNG_NOI_BO_PREFIX_URL + "/:id" + "/preview",
    element: (
      <LoadingLazyComponents
        children={<PreviewPage />}
        loadingType="skeleton"
      />
    ),
  },
  {
    path: TAM_UNG_NOI_BO_PREFIX_URL + "/waiting-approval",
    element: (
      <LoadingLazyComponents
        children={<MyWaitingApprovalPage />}
        loadingType="skeleton"
      />
    ),
  },
  {
    path: TAM_UNG_NOI_BO_PREFIX_URL + "/my-act-approval",
    element: (
      <LoadingLazyComponents
        children={<MyApprovedApprovalPage />}
        loadingType="skeleton"
      />
    ),
  },
];
