import { useEffect, useState } from 'react'
import { Button, Row, Space, Table, TableColumnsType } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'ict24h-themes-react'
import DeXuatKhoaDaoTaoService from '../../../de-xuat-khoa-dao-tao/de-xuat-khoa-dao-tao.service'
import {
  MetadataType,
  searchQuery,
} from '../../../../../../utils/metadata.types'
import { DeXuatKhoaDaoTaoEntity } from '../../../../../../server-api-hrm.type'

type propsType = {
  deXuatKhoaDaoTao: DeXuatKhoaDaoTaoEntity | undefined
  // eslint-disable-next-line no-unused-vars
  addThongTinKhoaDaoTao: (khoaDaoTaoId: string) => void
}

function FormDeXuatKhoaDaoTaoOfKeHoach({
  deXuatKhoaDaoTao,
  addThongTinKhoaDaoTao,
}: propsType) {
  const auth = useAuth()

  const navigate = useNavigate()
  const [data, setData] = useState<DeXuatKhoaDaoTaoEntity[]>([])
  const [metadata, setMetadata] = useState<MetadataType>()

  // lay toan bo de xuat hien thi len form
  const getAllDeXuatKhoaDaoTao = async (query?: searchQuery) => {
    const res = await DeXuatKhoaDaoTaoService(
      auth.token!,
      navigate
    ).getAllDeXuatKhoaDaoTao(query)
    setData(res.data.data)
    setMetadata(res.data.metadata)
  }

  useEffect(() => {
    getAllDeXuatKhoaDaoTao()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddDeXuatOfKeHoach = async (record: DeXuatKhoaDaoTaoEntity) => {
    addThongTinKhoaDaoTao(record.id)
  }

  const columns: TableColumnsType<DeXuatKhoaDaoTaoEntity> = [
    {
      title: 'Đề Xuất Khoá Đào Tạo',
      dataIndex: 'tieuDe',
      key: 'name',
      render: (text, record: DeXuatKhoaDaoTaoEntity) => (
        <Link to={record.id}>{text}</Link>
      ),
    },
    {
      title: 'Mô Tả',
      dataIndex: 'mota',
      key: 'mota',
    },

    {
      title: 'Action',
      fixed: 'right',
      render: (record: DeXuatKhoaDaoTaoEntity) => (
        <Button onClick={() => handleAddDeXuatOfKeHoach(record)}>
          {deXuatKhoaDaoTao ? 'Sửa' : 'Thêm'}
        </Button>
      ),
    },
  ]

  return (
    <>
      <Table
        size="small"
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={false}
      />
      <Row justify="end" style={{ marginTop: '16px' }}>
        <Space>
          <Button
            disabled={metadata?.paging.cursor.prev.length === 0}
            onClick={() => {
              if (metadata) {
                getAllDeXuatKhoaDaoTao({
                  pageId: metadata?.paging.cursor.prev,
                })
              }
            }}
          >
            Trang trước
          </Button>
          <Button
            disabled={metadata?.paging.cursor.next.length === 0}
            onClick={() => {
              if (metadata) {
                if (metadata) {
                  getAllDeXuatKhoaDaoTao({
                    pageId: metadata?.paging.cursor.next,
                  })
                }
              }
            }}
          >
            Trang tiếp
          </Button>
        </Space>
      </Row>
    </>
  )
}

export default FormDeXuatKhoaDaoTaoOfKeHoach
