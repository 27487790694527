export const URL_ENDPOINT_DE_NGHI_HOAN_UNG = `${
  process.env.REACT_APP_ENDPOINT_URL
}/de-nghi-hoan-ung`;

export const DE_NGHI_HOAN_UNG_PREFIX_URL = "/de-nghi-hoan-ung";

export const URL_ENDPOINT_DE_NGHI_HOAN_UNG_APPROVAL_LOG = `${
  process.env.REACT_APP_ENDPOINT_URL
}/de-nghi-hoan-ung-approval-log`;

export const URL_ENDPOINT_CHI_TIET_DE_NGHI_HOAN_UNG = `${
  process.env.REACT_APP_ENDPOINT_URL
}/chi-tiet-de-nghi-hoan-ung`;

export const CHI_TIET_DE_NGHI_HOAN_UNG_PREFIX_URL =
  "/chi-tiet-de-nghi-hoan-ung";
