import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const THU_VIEN_KPI_PREFIX_URL = '/mau-kpi'
export const THU_VIEN_CHI_TIET_PREFIX_URL = '/mau-kpi'

export const URL_ENDPOINT_THU_VIEN = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/mau-kpi`

export const URL_ENDPOINT_THU_VIEN_CHI_TIET = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/mau-kpi`

export const configMauKpiLayout: HeaderLayoutConfig = {
  appName: ' Thông tin danh sách thư viện kpi',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
