import { LoadingLazyComponents } from "ict24h-themes-react";
import { lazy } from "react";
import { NHA_CUNG_CAP_PREFIX_URL } from "./constants/config.nha-cung-cap";
import {AppRouterItem} from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";

const LayoutPage = lazy(() => import("./pages/LayoutPage.NhaCungCap"));

export const NhaCungCapRouters: AppRouterItem[] = [
  {
    path: NHA_CUNG_CAP_PREFIX_URL + "/",
    element: <LoadingLazyComponents children={<LayoutPage />} loadingType="skeleton" />,
  },
];
