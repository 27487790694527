import { LoadingLazyComponents, NotFoundPage } from 'ict24h-themes-react'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import mapRouterHelper from '../../utils/MapRouterHelper'
import CheDoBaoHiemRouters from './modules/che-do-bao-hiem-xa-hoi/CheDoBaoHiemRouters'
import ChiNhanhRouters from './modules/chi-nhanh/ChiNhanhRouters'
import ChucVuRouters from './modules/chuc-vu/ChucVuRouters'
import LoaiNghiPhepRouters from './modules/loai-nghi-phep/LoaiNghiPhepRouters'
import NganHangRouters from './modules/ngan-hang/NganHangRouters'
import NgoaiNguRouters from './modules/ngoai-ngu/NgoaiNguRouters'
import NhanVienRouters from './modules/nhan-vien/NhanVienRouters'
import PhongBanRouters from './modules/phong-ban/PhongBanRouters'
import QuocGiaRouters from './modules/quoc-gia/QuocGiaRouters'
import XinNghiPhepRouters from './modules/xin-nghi-phep/XinNghiPhepRouters'
import ListModules from './pages/ListModules.CauHinhThongTin'

const CauHinhThongTinRouters: FC = () => (
  <Routes>
    <Route
      path="/"
      element={
        <LoadingLazyComponents>
          <ListModules />
        </LoadingLazyComponents>
      }
    />
    {mapRouterHelper(XinNghiPhepRouters)}
    {mapRouterHelper(CheDoBaoHiemRouters)}
    {mapRouterHelper(ChiNhanhRouters)}
    {mapRouterHelper(PhongBanRouters)}
    {mapRouterHelper(ChucVuRouters)}
    {mapRouterHelper(LoaiNghiPhepRouters)}
    {mapRouterHelper(QuocGiaRouters)}
    {mapRouterHelper(NganHangRouters)}
    {mapRouterHelper(NhanVienRouters)}
    {mapRouterHelper(NgoaiNguRouters)}
    {/* Fallback Not found pages */}
    <Route
      path="*"
      element={
        <LoadingLazyComponents>
          <NotFoundPage />
        </LoadingLazyComponents>
      }
    />
  </Routes>
)

export default CauHinhThongTinRouters
