import { message } from 'antd'
import { NavigateFunction } from 'react-router-dom'
import axiosClient from '../../../../utils/custom-fetch'
import { URL_ENDPOINT_KHOA_DAO_TAO } from './constants/config.khoa-dao-tao'
import {
  AddTaiLieuGiaoAnKdt,
  CreateKhoaDaoTaoDto,
  KhoaDaoTaoEntity,
  UpdateKhoaDaoTaoDto,
} from '../../../../server-api-hrm.type'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'

export default function KhoaDaoTaoService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllKhoaDaoTao = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: KhoaDaoTaoEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_KHOA_DAO_TAO, {
      params: query,
    })
    return response
  }

  const addKhoaDaoTao = async (values: CreateKhoaDaoTaoDto) => {
    const response = await axios.post<KhoaDaoTaoEntity>(
      URL_ENDPOINT_KHOA_DAO_TAO,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editKhoaDaoTao = async (values: UpdateKhoaDaoTaoDto, id: string) => {
    const res = await axios.patch(`${URL_ENDPOINT_KHOA_DAO_TAO}/${id}`, values)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getDeleteKhoaDaoTao = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_KHOA_DAO_TAO}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietKhoaDaoTao = async (id: string) => {
    const response = await axios.get<KhoaDaoTaoEntity>(
      `${URL_ENDPOINT_KHOA_DAO_TAO}/${id}`
    )
    return response
  }

  const URL = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/tai-lieu-giao-an`

  const getchAllTaiLieuOfKhoaDaoTao = async (id: string) => {
    const res = await axios.get(`${URL}?khoaDaoTaoId=${id}`)
    return res
  }

  const addTaiLieuKhoaDaoTao = async (
    // token: string,
    // object: { khoaDaoTaoId: string; taiLieuGiaoAnId: string },
    query: AddTaiLieuGiaoAnKdt
  ) => {
    const res = await axios.post(
      `${URL_ENDPOINT_KHOA_DAO_TAO}/tai-lieu-giao-an`,
      query
    )
    return res
  }

  const deleteTaiLieuTungKhoa = async (
    khoaDaoTaoId: string,
    taiLieuGiaoAnId: string
  ) => {
    const res = await axios.delete(
      `${URL_ENDPOINT_KHOA_DAO_TAO}/${khoaDaoTaoId}/tai-lieu-giao-an/${taiLieuGiaoAnId}`
    )
    return res
  }

  const deleteBaiTestTungKhoa = async (
    khoaDaoTaoId: string,
    thongTinBaiTestId: string
  ) => {
    const res = await axios.delete(
      `${URL_ENDPOINT_KHOA_DAO_TAO}/${khoaDaoTaoId}/thong-tin-bai-test/${thongTinBaiTestId}`
    )
    return res
  }

  const addBaiTestKhoaDaoTao = async (
    // token: string,
    // object: { khoaDaoTaoId: string; thongTinBaiTestId: string },
    query: searchQuery
  ) => {
    const res = await axios.post(
      `${URL_ENDPOINT_KHOA_DAO_TAO}/thong-tin-bai-test`,
      query
    )
    return res
  }

  const getAllHvOfKhoaDaoTao = async (id: string) => {
    const response = await axios.get(
      `https://rdn-hrm.zbh-dev.i24h.app/hoc-vien?khoaDaoTaoId=${id}`
    )
    return response
  }

  return {
    getAllHvOfKhoaDaoTao,
    deleteBaiTestTungKhoa,
    addBaiTestKhoaDaoTao,
    addTaiLieuKhoaDaoTao,
    getchAllTaiLieuOfKhoaDaoTao,
    deleteTaiLieuTungKhoa,
    editKhoaDaoTao,
    getAllKhoaDaoTao,
    getDeleteKhoaDaoTao,
    addKhoaDaoTao,
    getChitietKhoaDaoTao,
  }
}
