import { message } from "antd"
import axios from "axios"

interface IProps {
    url: string
    method?: FetchMethodeTypeEnum, 
    body?: any, 
    actionName?: string
    token: string | undefined
}

export enum FetchMethodeTypeEnum {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    DELETE = 'delete'
}

export const appAPI = process.env.REACT_APP_ENDPOINT_URL

export async function fetchApi<T>({
    url,
    method,
    body,
    actionName,
    token

}: IProps) : Promise<T | undefined>{
    try{
        if(typeof token !== "string"){
            console.warn('Không tìm thấy Token')
            return undefined
        }
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    
        const res = await axios(url, {method, data: body, headers})
        const resData = await res.data as T | undefined
    
        if(res.status > 199 && res.status < 300) {
            if(actionName){
                message.success(`${actionName} thành công !`)
            }
        } else {
            if(actionName){
                message.error(`${actionName} thất bại !`)
            }
            console.warn(`Tải dữ liệu thất bại ${res.statusText}`)
        }
    
        return resData
    } catch(err){
        console.warn(err);
        message.error(`${method === FetchMethodeTypeEnum.GET ? 'Tải dữ liệu' : actionName || 'Hành động'} thất bại !`)
        return undefined
    }
}
