import React from 'react'
import { Drawer } from 'antd'
import FormTaiLieuGiaoAnBuoiHoc from './FormTaiLieuGiaoAnBuoiHoc'

type propsType = {
  isModalOpen: boolean
  handleCancel: () => void
  // eslint-disable-next-line no-unused-vars
  addTaiLieuBuoiHoc: (taiLieuGiaoAnId: string) => void
}

function DrawerTaiLieuBuoiHoc({
  isModalOpen,
  handleCancel,
  addTaiLieuBuoiHoc,
}: propsType) {
  return (
    <Drawer
      title={<strong>Thêm Tài Liệu - Giáo Án</strong>}
      open={isModalOpen}
      onClose={handleCancel}
      footer={null}
      destroyOnClose
    >
      <FormTaiLieuGiaoAnBuoiHoc addTaiLieuBuoiHoc={addTaiLieuBuoiHoc} />
    </Drawer>
  )
}

export default DrawerTaiLieuBuoiHoc
