import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const CHUNG_MINH_KPI_PREFIX_URL = '/chung-minh-kpi'

export const configMauKpiLayout: HeaderLayoutConfig = {
  appName: 'Chứng minh KPI',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
