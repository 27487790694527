import { message } from 'antd'
import { NavigateFunction } from 'react-router-dom'
import { URL_ENDPOINT_DE_XUAT_KHOA_DAO_TAO } from './constants/config.de-xuat-khoa-dao-tao'
import axiosClient from '../../../../utils/custom-fetch'
import {
  CreateDeXuatKhoaDaoTaoDto,
  DeXuatKhoaDaoTaoEntity,
  UpdateDeXuatKhoaDaoTaoDto,
} from '../../../../server-api-hrm.type'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'

export default function DeXuatKhoaDaoTaoService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllDeXuatKhoaDaoTao = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: DeXuatKhoaDaoTaoEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_DE_XUAT_KHOA_DAO_TAO, {
      params: query,
    })
    return response
  }

  const addDeXuatKhoaDaoTao = async (values: CreateDeXuatKhoaDaoTaoDto) => {
    const response = await axios.post<DeXuatKhoaDaoTaoEntity>(
      URL_ENDPOINT_DE_XUAT_KHOA_DAO_TAO,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editDeXuatKhoaDaoTao = async (
    values: UpdateDeXuatKhoaDaoTaoDto,
    id: string
  ) => {
    const res = await axios.patch(
      `${URL_ENDPOINT_DE_XUAT_KHOA_DAO_TAO}/${id}`,
      values
    )
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const deleteDeXuatKhoaDaoTao = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_DE_XUAT_KHOA_DAO_TAO}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietDeXuatKhoaDaoTao = async (id: string) => {
    const response = await axios.get<DeXuatKhoaDaoTaoEntity>(
      `${URL_ENDPOINT_DE_XUAT_KHOA_DAO_TAO}/${id}`
    )
    return response
  }

  return {
    getAllDeXuatKhoaDaoTao,
    addDeXuatKhoaDaoTao,
    editDeXuatKhoaDaoTao,
    deleteDeXuatKhoaDaoTao,
    getChitietDeXuatKhoaDaoTao,
  }
}
