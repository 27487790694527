import { FC } from 'react'
import { LoadingLazyComponents, NotFoundPage } from 'ict24h-themes-react'
import { Route, Routes } from 'react-router-dom'
import mapRouterHelper from '../../utils/MapRouterHelper'
import CauHinhRouters from './modules/cau-hinh/CauHinhRouters'
import UngVienRouters from './modules/ung-vien/UngVienRouters'
import ViTriTuyenDungRouters from './modules/vi-tri-tuyen-dung/ViTriTuyenDungRouters'
import YeuCauTuyenDungRouters from './modules/yeu-cau-tuyen-dung/YeuCauTuyenDungRouters'
import KeHoachTuyenDungRouters from './modules/ke-hoach-tuyen-dung/KeHoachTuyenDungRouters'
import SuKienTuyenDungRouters from './modules/su-kien-tuyen-dung/SuKienTuyenDungRouters'

const QuanLyTuyenDungRouters: FC = () => (
  <Routes>
    {mapRouterHelper(UngVienRouters)}
    {mapRouterHelper(ViTriTuyenDungRouters)}
    {mapRouterHelper(YeuCauTuyenDungRouters)}
    {mapRouterHelper(CauHinhRouters)}
    {mapRouterHelper(KeHoachTuyenDungRouters)}
    {mapRouterHelper(SuKienTuyenDungRouters)}

    <Route
      path="*"
      element={
        <LoadingLazyComponents loadingType="skeleton">
          <NotFoundPage />
        </LoadingLazyComponents>
      }
    />
  </Routes>
)

export default QuanLyTuyenDungRouters
