import { Button, Form, Input, message, Row } from 'antd'
import { useEffect } from 'react'
import {
  CreateKeHoachHocTapDto,
  KeHoachHocTapEntity,
} from '../../../../../server-api-hrm.type'

type propsType = {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (values: CreateKeHoachHocTapDto) => void
  keHoach: KeHoachHocTapEntity | undefined
}

function FormKeHoach({ onSubmit, keHoach }: propsType) {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      nam: keHoach?.nam,
    })
    // eslint-disable-next-line
  }, [keHoach])

  const onFinish = (values: CreateKeHoachHocTapDto) => {
    onSubmit({ ...values })
    form.resetFields()
  }
  const onFinishFailed = () => {
    message.error('Hãy Nhập Đầy Đủ Thông Tin')
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      colon
    >
      <Form.Item
        name="nam"
        label="Năm"
        rules={[{ required: true, message: 'Hãy nhập năm dự kiến!' }]}
      >
        <Input />
      </Form.Item>

      <Row justify="end">
        <Form.Item labelAlign="right">
          <Button type="primary" htmlType="submit">
            {keHoach ? 'Sửa' : 'Thêm'}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  )
}

export default FormKeHoach
