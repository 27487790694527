import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { DE_XUAT_KHOA_DAO_TAO_PREFIX_URL } from './constants/config.de-xuat-khoa-dao-tao'

const ListPage = lazy(() => import('./pages/ListPage.DeXuatKhoaDaoTao'))

const DeXuatKhoaDaoTaoRouters: AppRouterItem[] = [
  {
    path: `${DE_XUAT_KHOA_DAO_TAO_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default DeXuatKhoaDaoTaoRouters
