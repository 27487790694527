import { SidebarLeftLayout } from "ict24h-themes-react";
import type { FC } from "react";
import LeftMenu from "./components/LeftMenu.HomeApp";
import ModuleRouters from "./ConfigWorkflowRouters";
import { dockConfig } from "../home/dock-config";

const ConfigWorkflowLayout: FC = () => {
  return (
    <SidebarLeftLayout
      menu={<LeftMenu />}
      headerConfig={null}
      dock={dockConfig}
    >
      <ModuleRouters />
    </SidebarLeftLayout>
  );
};

export default ConfigWorkflowLayout;
