import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const CA_LAM_PREFIX_URL = '/ca-lam-viec'
export const URL_ENDPOINT_CA_LAM = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/ca-lam-viec`

export const configCaLamLayout: HeaderLayoutConfig = {
  appName: ' Thông tin ca làm ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
