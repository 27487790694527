import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const CHUC_VU_PREFIX_URL = '/chuc-vu'
export const URL_ENDPOINT_CHUC_VU = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/chuc-vu`

export const configChucVuLayout: HeaderLayoutConfig = {
  appName: ' Thông tin chức vụ ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
