import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const CAU_HINH_THONG_TIN_PREFIX_URL = '/cau-hinh-thong-tin'

export const configHomeAppLayout: HeaderLayoutConfig = {
  appName: 'CẤU HÌNH THÔNG TIN',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
