import { message } from 'antd'
import axios from 'axios'
import { URL_ENDPOINT_HOC_VIEN_VANG } from './constants/config.hoc-vien-vang'
import {
  MetadataType,
  searchQuery,
} from '../../../../../../../utils/metadata.types'
import {
  CreateHocVienVangDto,
  HocVienVangEntity,
  UpdateHocVienVangDto,
} from '../../../../../../../server-api-hrm.type'

export default function HocVienVangServices() {
  const getAllHocVienVang = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: HocVienVangEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_HOC_VIEN_VANG, {
      params: query,
    })
    return response
  }

  const addHocVienVang = async (values: CreateHocVienVangDto) => {
    const response = await axios.post<HocVienVangEntity>(
      URL_ENDPOINT_HOC_VIEN_VANG,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editHocVienVang = async (values: UpdateHocVienVangDto, id: string) => {
    const res = await axios.patch(`${URL_ENDPOINT_HOC_VIEN_VANG}/${id}`, values)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const deleteHocVienVang = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_HOC_VIEN_VANG}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietHocVienVang = async (id: string) => {
    const response = await axios.get<HocVienVangEntity>(
      `${URL_ENDPOINT_HOC_VIEN_VANG}/${id}`
    )
    return response
  }
  return {
    getAllHocVienVang,
    addHocVienVang,
    editHocVienVang,
    deleteHocVienVang,
    getChitietHocVienVang,
  }
}
