import { lazy } from 'react'
import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { VI_TRI_TUYEN_DUNG_URL } from '../../constants/config.quan-ly-tuyen-dung'

const ViTriTuyenDung = lazy(() => import('./pages/ViTriTuyenDungList'))
const CreateViTriTuyenDungPage = lazy(
  () => import('./pages/Create.ViTriTuyenDungPage')
)
const EditViTriTuyenDungPage = lazy(
  () => import('./pages/Edit.ViTriTuyenDungPage')
)
const ViewViTriTuyenDungPage = lazy(
  () => import('./pages/View.ViTriTuyenDungPage')
)
const UngVienViTriTuyenDungPage = lazy(
  () => import('./pages/UngVienViTriTuyenDungPage')
)

const ViTriTuyenDungRouters: AppRouterItem[] = [
  {
    path: `/${VI_TRI_TUYEN_DUNG_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ViTriTuyenDung />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${VI_TRI_TUYEN_DUNG_URL}/by-yeu-cau-tuyen-dung/:yeuCauTuyenDungId/create`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CreateViTriTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${VI_TRI_TUYEN_DUNG_URL}/by-chi-tiet-ke-hoach-tuyen-dung/:chiTietKeHoachTuyenDungId/create`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CreateViTriTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${VI_TRI_TUYEN_DUNG_URL}/:viTriTuyenDungId/view`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ViewViTriTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${VI_TRI_TUYEN_DUNG_URL}/:viTriTuyenDungId/edit`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <EditViTriTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${VI_TRI_TUYEN_DUNG_URL}/:viTriTuyenDungId/ung-vien`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <UngVienViTriTuyenDungPage />
      </LoadingLazyComponents>
    ),
  },
]

export default ViTriTuyenDungRouters
