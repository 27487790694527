import {
  useContext,
  useReducer,
  createContext,
  ReactElement,
  Reducer,
  Dispatch,
} from "react";
import { IWorkflowStep } from "../../constants/workflow.entity";

const initialWorkflowStep: IWorkflowStep = {
  typeOfApproval: "sequential",
  chooseUserOrDepartment: "user",
  getAutoByField: false,
  parallelStepWorkflows: [],
  cacheStep: {},
};

const WorkflowStepContext = createContext<IWorkflowStep>(initialWorkflowStep);
const WorkflowStepDispatchContext =
  createContext<Dispatch<WorkflowStepAction> | null>(null);

export function WorkflowStepProvider({ children }: { children: ReactElement }) {
  const [wfStep, dispatch] = useReducer<
    Reducer<IWorkflowStep, WorkflowStepAction>
  >(workflowStepReducer, initialWorkflowStep);

  return (
    <WorkflowStepContext.Provider value={wfStep}>
      <WorkflowStepDispatchContext.Provider value={dispatch}>
        {children}
      </WorkflowStepDispatchContext.Provider>
    </WorkflowStepContext.Provider>
  );
}

export function useWorkflowStep() {
  return useContext(WorkflowStepContext);
}
export function useWorkflowStepDispatch() {
  return useContext(WorkflowStepDispatchContext);
}

// An enum with all the types of actions to use in our reducer
export enum WorkflowStepActionKind {
  RESET = "RESET",
  UPDATE_SOME_FIELDS = "UPDATE_SOME_FIELDS",
  ADD_SUB_STEP = "ADD_SUB_STEP",
  UPDATE_SUB_STEP_FIELD = "UPDATE_SUB_STEP_FIELD",
  RESET_SUB_STEP = "RESET_SUB_STEP",
  // SAVE_ALL_TO_SERVER = "SAVE_ALL_TO_SERVER", // it should be in another effect
}

// An interface for our actions
interface WorkflowStepAction {
  type: WorkflowStepActionKind;
  payload?: IWorkflowStep;
}

function workflowStepReducer(
  wfStep: IWorkflowStep,
  { type, payload }: WorkflowStepAction
): IWorkflowStep {
  switch (type) {
    case WorkflowStepActionKind.RESET: {
      return initialWorkflowStep;
    }
    case WorkflowStepActionKind.UPDATE_SOME_FIELDS: {
      return { ...wfStep, ...payload };
    }
    case WorkflowStepActionKind.ADD_SUB_STEP: {
      if (!wfStep.cacheStep?.stepName) {
        throw Error("cacheStep.stepName is required");
      }

      // check để khởi tạo parallelStepWorkflows tránh undefined
      const cloneParallelStepWorkflows = wfStep.parallelStepWorkflows
        ? wfStep.parallelStepWorkflows
        : [];

      return {
        ...wfStep,
        cacheStep: {}, // reset cacheStep
        parallelStepWorkflows: [
          ...cloneParallelStepWorkflows,
          wfStep.cacheStep,
        ],
      };
    }
    case WorkflowStepActionKind.UPDATE_SUB_STEP_FIELD: {
      return {
        ...wfStep,
        cacheStep: {
          ...wfStep.cacheStep,
          ...payload,
        },
      };
    }
    case WorkflowStepActionKind.RESET_SUB_STEP: {
      return {
        ...wfStep,
        cacheStep: {},
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}
