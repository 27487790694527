import UploadComponent from "./upload.comp";
import ViewListFile from "./view-list-file";

// make prop available to the component
export default function AttachmentFilesSharepoint() {
  return (
    <div>
      <h1>AttachmentFilesSharepoint</h1>
      <div>
        bây giờ cần lấy token của sp trước để call lên graph api <br />
      </div>
      <UploadComponent />
      <hr />
      <br />
      Mặc định là sẽ luôn hiển thị list dưới này (dù có cho up hay ko?)
      <br />
      Nhưng list cho up sẽ hiển thị nút xóa?
      <br />
      <ViewListFile />
    </div>
  );
}
