import { NavigateFunction } from 'react-router-dom'

import { MetadataType } from '../../../../constants/metadata.types'

import axiosClient from '../../../../utils/custom-fetch'
import { URL_ENDPOINT_CAP_KPI } from './constants/config.cap-kpi'
import {
  CapKpiEntity,
  CreateCapKpiDto,
  UpdateCapKpiDto,
} from '../../../../server-api-hrm.type'
import { SearchQuery } from '../../constants/search-query'

export default function CapKpiService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllCapKpi = async (query?: SearchQuery) => {
    const response = await axios.get<{
      data: CapKpiEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_CAP_KPI, {
      params: query,
    })
    return response
  }

  const getOneCapKpi = async (id: string, query?: SearchQuery) => {
    const response = await axios.get<CapKpiEntity>(
      `${URL_ENDPOINT_CAP_KPI}/${id}`,
      {
        params: query,
      }
    )
    return response
  }

  const addCapKpi = async (values: CreateCapKpiDto) => {
    const res = await axios.post(URL_ENDPOINT_CAP_KPI, values)
    return res
  }

  const editCapKpi = async (values: UpdateCapKpiDto, id: string) => {
    const res = await axios.patch(`${URL_ENDPOINT_CAP_KPI}/${id}`, values)
    return res
  }

  const deleteCapKpi = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_CAP_KPI}/${id}`)
    return res
  }

  return {
    getAllCapKpi,
    getOneCapKpi,
    addCapKpi,
    editCapKpi,
    deleteCapKpi,
  }
}
