export const URL_ENDPOINT_PHIEU_DIEU_DONG_LUAN_CHUYEN_NHAN_SU = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/phieu-dieu-dong-luan-chuyen-nhan-su`

export const PHIEU_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL =
  '/phieu-dieu-dong-luan-chuyen-nhan-su'

export const URL_ENDPOINT_PHIEU_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_APPROVAL_LOG = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/phieu-dieu-dong-luan-chuyen-nhan-su-approval-log`

export const URL_ENDPOINT_CHI_TIET_PHIEU_DIEU_DONG_LUAN_CHUYEN_NHAN_SU = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/chi-tiet-phieu-dieu-dong-luan-chuyen-nhan-su`

export const CHI_TIET_PHIEU_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL =
  '/chi-tiet-phieu-dieu-dong-luan-chuyen-nhan-su'

export const URL_ENDPOINT_NHAN_VIEN_DUOC_DIEU_DONG_LUAN_CHUYEN = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/nhan-vien-duoc-dieu-dong-luan-chuyen`
