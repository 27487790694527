import React from 'react'
import { Drawer } from 'antd'
import FormDeXuatKhoaDaoTaoOfKeHoach from './FormDeXuatKhoaDaoTaoOfKeHoach'
import { DeXuatKhoaDaoTaoEntity } from '../../../../../../server-api-hrm.type'

type propsType = {
  isModalOpen: boolean
  handleCancel: () => void
  deXuatKhoaDaoTao: DeXuatKhoaDaoTaoEntity | undefined
  // eslint-disable-next-line no-unused-vars
  addThongTinKhoaDaoTao: (khoaDaoTaoId: string) => void
}

function DrawerDeXuatKhoaDaoTaoOfKeHoach({
  isModalOpen,
  handleCancel,
  deXuatKhoaDaoTao,
  addThongTinKhoaDaoTao,
}: propsType) {
  return (
    <Drawer
      title={<strong>Thêm Đề Xuất Khoá Đào Tạo</strong>}
      open={isModalOpen}
      onClose={handleCancel}
      footer={null}
      destroyOnClose
    >
      <FormDeXuatKhoaDaoTaoOfKeHoach
        addThongTinKhoaDaoTao={addThongTinKhoaDaoTao}
        deXuatKhoaDaoTao={deXuatKhoaDaoTao}
      />
    </Drawer>
  )
}

export default DrawerDeXuatKhoaDaoTaoOfKeHoach
