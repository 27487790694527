export default function EmbedIframe({ src }: { src: string }) {
  return (
    <div>
      <iframe
        src={src}
        width="100%"
        height="500px"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
}
