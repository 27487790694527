import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import React, { lazy } from 'react'
import { KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL } from './constants/config.ke-hoach-dieu-dong-luan-chuyen-nhan-su'

const DetailPage = lazy(
  () => import('./pages/DetailPage.KeHoachDieuDongLuanChuyenNhanSu')
)
const PreviewPage = lazy(
  () => import('./pages/PreviewPage.KeHoachDieuDongLuanChuyenNhanSu')
)
const DanhSachPhieuToiTaoPage = lazy(
  () => import('./pages/DanhSachPhieuToiTao.KeHoachDieuDongLuanChuyenNhanSu')
)
const CreatePage = lazy(
  () => import('./pages/CreatePage.KeHoachDieuDongLuanChuyenNhanSu')
)
const EditPage = lazy(
  () => import('./pages/EditPage.KeHoachDieuDongLuanChuyenNhanSu')
)
const MyWaitingApprovalPage = lazy(
  () =>
    import('./pages/DanhSachPhieuChoToiDuyet.KeHoachDieuDongLuanChuyenNhanSu')
)
const ApprovedPage = lazy(
  () =>
    import('./pages/DanhSachPhieuDaDuocDuyet.KeHoachDieuDongLuanChuyenNhanSu')
)

const KeHoachDieuDongLuanChuyenNhanSuRouters: AppRouterItem[] = [
  {
    path: `${KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL}/create`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CreatePage />
      </LoadingLazyComponents>
    ),
  },

  {
    path: `${KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL}/:id/edit`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <EditPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL}/my-approval`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <DanhSachPhieuToiTaoPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL}/:id/view`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <DetailPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL}/:id/preview`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <PreviewPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL}/waiting-approval`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <MyWaitingApprovalPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${KE_HOACH_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PREFIX_URL}/approved`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ApprovedPage />
      </LoadingLazyComponents>
    ),
  },
]

export default KeHoachDieuDongLuanChuyenNhanSuRouters
