import { MenuProps, Menu } from 'antd'
import { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  BarChartOutlined,
  CarryOutOutlined,
  FullscreenExitOutlined,
  HomeOutlined,
  UserOutlined,
  ReadOutlined,
} from '@ant-design/icons'
import { useAuth } from 'ict24h-themes-react'
import {
  LIST_MENU_CAU_HINH_KPI,
  LIST_MENU_CHI_DINH_KPI,
  LIST_MENU_CHUNG_MINH_KPI,
  LIST_MENU_DASHBOARD_KPI,
  LIST_MENU_KE_HOACH_KPI,
  LIST_MENU_THU_VIEN_KPI,
} from '../constants/list-menu.kpi'
import CapKpiService from '../modules/cap-kpi/cap-kpi.service'
import axiosServiceCheck from '../../../utils/axios-service-check'
import { DANH_GIA_KPI_PREFIX_URL } from '../constants/config.danh-gia-kpi'
import { KE_HOACH_KPI_PREFIX_URL } from '../modules/ke-hoach-kpi/constants/config.ke-hoach-kpi'
import { MetadataType } from '../../../constants/metadata.types'
import { SearchQuery } from '../constants/search-query'

interface MenuItemInterface {
  key: string
  label: string
  onClick?: () => void
}

const LeftMenuHomeApp: FC = () => {
  const { token } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [selectedKey, setSelectedKey] = useState<string>('')
  const [capKpiMenu, setCapKpiMenu] = useState<MenuItemInterface[] | undefined>(
    undefined
  )
  const [capKpiMetadata, setCapKpiMetadata] = useState<
    MetadataType | undefined
  >(undefined)

  const handleGetCapKPI = (query?: SearchQuery) => {
    if (!token) {
      return
    }
    CapKpiService(token)
      .getAllCapKpi(query)
      .then((res) => {
        axiosServiceCheck({
          res,
          followUpAction() {
            const capMenu: MenuItemInterface[] = []
            res.data.data.forEach((item) =>
              capMenu.push({
                key: `${DANH_GIA_KPI_PREFIX_URL}${KE_HOACH_KPI_PREFIX_URL}/cap-kpi/${item.id}`,
                label: item.capKpi,
              })
            )
            setCapKpiMenu(capMenu)
            setCapKpiMetadata(res.data.metadata)
          },
        })
      })
  }

  useEffect(() => {
    handleGetCapKPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (capKpiMetadata && capKpiMetadata.paging.cursor.next) {
      handleGetCapKPI({ pageLimit: `${capKpiMetadata.paging.limit || 0 + 20}` })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capKpiMetadata])

  // Tự highlight menu đúng với đường dẫn
  useEffect(() => {
    if (pathname) {
      setSelectedKey(pathname)
    }
  }, [pathname])

  // tạo menu fake mẫu
  const items2: MenuProps['items'] = []

  // Thêm list menu = chương trình

  items2.unshift({
    key: 'other2',
    icon: <UserOutlined />,
    label: 'Cấu hình KPI',
    children: LIST_MENU_CAU_HINH_KPI.map((menuItem) => {
      // eslint-disable-next-line no-param-reassign
      menuItem.onClick = () => {
        navigate(menuItem.key)
      }
      return menuItem
    }),
  })

  items2.unshift({
    key: 'other4',
    icon: <ReadOutlined />,
    label: 'Thư viện KPI',
    children: LIST_MENU_THU_VIEN_KPI.map((menuItem) => {
      // eslint-disable-next-line no-param-reassign
      menuItem.onClick = () => {
        navigate(menuItem.key)
      }
      return menuItem
    }),
  })

  items2.unshift({
    ...LIST_MENU_CHUNG_MINH_KPI,
    icon: <CarryOutOutlined />,
    onClick: () => navigate(LIST_MENU_CHUNG_MINH_KPI.key),
  })

  items2.unshift({
    ...LIST_MENU_CHI_DINH_KPI,
    icon: <FullscreenExitOutlined />,
    onClick: () => navigate(LIST_MENU_CHI_DINH_KPI.key),
  })

  items2.unshift({
    key: 'other3',
    icon: <HomeOutlined />,
    label: 'Kế hoạch KPI',
    children: capKpiMenu?.concat(LIST_MENU_KE_HOACH_KPI).map((menuItem) => {
      // eslint-disable-next-line no-param-reassign
      menuItem.onClick = () => {
        navigate(menuItem.key)
      }
      return menuItem
    }),
  })

  items2.unshift({
    ...LIST_MENU_DASHBOARD_KPI,
    icon: <BarChartOutlined />,
    onClick: () => navigate(LIST_MENU_DASHBOARD_KPI.key),
  })

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={['other1']}
      style={{ height: '100%', minHeight: '89vh', borderRight: 0 }}
      items={items2}
      selectedKeys={[selectedKey]}
    />
  )
}

export default LeftMenuHomeApp
