import { SidebarLeftLayout } from "ict24h-themes-react";
import type { FC } from "react";
import LeftMenu from "./components/LeftMenu.QuanLyPheDuyet";
import ModuleRouters from "./QuanLyPheDuyetRouters";
import {dockConfig} from "../home/dock-config";

const HomeAppLayout: FC = () => {
  return (
    <SidebarLeftLayout menu={<LeftMenu />} headerConfig={null} dock={dockConfig}>
      <ModuleRouters />
    </SidebarLeftLayout>
  );
};

export default HomeAppLayout;
