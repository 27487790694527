import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const QUAN_LY_TUYEN_DUNG_PREFIX_URL = '/quan-ly-tuyen-dung'
export const UNG_VIEN_URL = '/ung-vien'
export const VI_TRI_TUYEN_DUNG_URL = '/vi-tri-tuyen-dung'
export const YEU_CAU_TUYEN_DUNG_URL = '/yeu-cau-tuyen-dung'
export const KE_HOACH_TUYEN_DUNG_URL = '/ke-hoach-tuyen-dung'
export const CAU_HINH_URL = '/cau-hinh'
export const SU_KIEN_TUYEN_DUNG_URL = '/su-kien-cua-toi'

export const configQuanLyTuyenDungLayout: HeaderLayoutConfig = {
  appName: 'Quản Lý Tuyển Dụng',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
