import { useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
} from 'antd'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { useAuth } from 'ict24h-themes-react'
import { useNavigate } from 'react-router-dom'
import ChucVuService from '../../chuc-vu/chuc-vu.service'
import PhongBanService from '../../phong-ban/phong-ban.service'
import ChiNhanhService from '../../chi-nhanh/chi-nhanh.service'
import {
  ChiNhanhEntity,
  ChucVuEntity,
  CreateNhanVienDto,
  NhanVienEntity,
  PhongBanEntity,
} from '../../../../../server-api-hrm.type'
import { searchQuery } from '../../../../../utils/metadata.types'

type propsType = {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (values: CreateNhanVienDto) => void
  nhanvien: NhanVienEntity | undefined
  onClose: () => void
  loading: boolean
}

function FormNhanVien({ onSubmit, nhanvien, loading, onClose }: propsType) {
  const auth = useAuth()
  const [form] = Form.useForm()
  const [chiNhanhList, setChiNhanhList] = useState<ChiNhanhEntity[]>([])
  const [loadingChiNhanh, setLoadingChiNhanh] = useState<boolean>(false)
  const [phongBanList, setPhongBanList] = useState<PhongBanEntity[]>([])
  const [loadingPhongBan, setLoadingPhongBan] = useState<boolean>(false)
  const [chucVuList, setChucVuList] = useState<ChucVuEntity[]>([])
  const [loadingChucVu, setLoadingChucVu] = useState<boolean>(false)
  const navigate = useNavigate()

  const getAllChiNhanh = async (query?: searchQuery) => {
    setLoadingChiNhanh(true)
    const res = await ChiNhanhService(auth.token!, navigate).getAllChiNhanh({
      ...query,
    })
    setChiNhanhList(res.data.data)
    setLoadingChiNhanh(false)
  }

  const getAllPhongBan = async (query?: searchQuery) => {
    setLoadingPhongBan(true)
    const res = await PhongBanService(auth.token!, navigate).getAllPhongBan({
      ...query,
    })
    setPhongBanList(res.data.data)
    setLoadingPhongBan(false)
  }

  const getAllChucVu = async (query?: searchQuery) => {
    setLoadingChucVu(true)
    const res = await ChucVuService(auth.token!, navigate).getAllChucVu({
      ...query,
    })
    setChucVuList(res.data.data)
    setLoadingChucVu(false)
  }

  useEffect(() => {
    getAllChiNhanh()
    getAllChucVu()
    getAllPhongBan()

    form.setFieldsValue({
      hoTen: nhanvien?.hoTen,
      diaChi: nhanvien?.diaChi,
      soDienThoai: nhanvien?.soDienThoai,
      email: nhanvien?.email,
      ngayBatDauLamViec: dayjs(nhanvien?.ngayBatDauLamViec),
      chiNhanhId: nhanvien?.chiNhanhId,
      phongBanId: nhanvien?.phongBanId,
      chucVuId: nhanvien?.chucVuId,
      ten: nhanvien?.ten,
      ho: nhanvien?.ho,
    })
    // eslint-disable-next-line
  }, [nhanvien])

  const onFinish = (values: CreateNhanVienDto) => {
    onSubmit({
      ...values,
      ngayBatDauLamViec: dayjs(values.ngayBatDauLamViec).toISOString(),
    })
  }

  const onFinishFailed = () => {
    message.error('Hãy Nhập Đầy Đủ Thông Tin')
  }

  const debounceFncChiNhanh = debounce((values) => {
    getAllChiNhanh({ name: values })
  }, 500)
  const debounceFncPhongBan = debounce((values) => {
    getAllPhongBan({ name: values })
  }, 500)
  const debounceFncChucVu = debounce((values) => {
    getAllChucVu({ name: values })
  }, 500)

  const handleSearchChiNhanh = (values: string) => {
    debounceFncChiNhanh(values)
  }

  const handleSearchPhongBan = (values: string) => {
    debounceFncPhongBan(values)
  }

  const handleSearchChucVu = (values: string) => {
    debounceFncChucVu(values)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      // labelCol={{ span: 8 }}
      // wrapperCol={{ span: 12 }}
      layout="vertical"
      colon
    >
      <Row>
        <Space>
          <Form.Item
            name="ho"
            label="Họ nhân viên"
            rules={[{ required: true, message: 'Hãy nhập họ nhân viên!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="ten"
            label="Tên nhân viên"
            rules={[{ required: true, message: 'Hãy nhập tên!' }]}
          >
            <Input />
          </Form.Item>
        </Space>
      </Row>

      <Form.Item
        name="hoTen"
        label="Họ và Tên đầy đủ"
        rules={[{ required: true, message: 'Hãy nhập tên!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="diaChi"
        label="Địa chỉ"
        rules={[{ required: true, message: 'Hãy nhập địa chỉ' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Hãy nhập email!' },
          {
            // eslint-disable-next-line
            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            message: 'Hãy nhập đúng định dạng',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="soDienThoai"
        label="Số điện thoại"
        rules={[{ required: true, message: 'Hãy nhập số điện thoại' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Ngày bắt đầu làm việc"
        name="ngayBatDauLamViec"
        rules={[{ required: true, message: 'Hãy nhập ngày bắt đầu làm việc' }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Chi nhánh"
        name="chiNhanhId"
        rules={[{ required: true, message: 'Hãy chọn một chi nhánh' }]}
      >
        <Select
          filterOption={false}
          showSearch
          placeholder="Chọn chi nhánh"
          notFoundContent={loadingChiNhanh ? <Spin size="small" /> : null}
          onSearch={handleSearchChiNhanh}
        >
          {chiNhanhList.map((chiNhanh: ChiNhanhEntity) => (
            <Select.Option key={chiNhanh.id} value={chiNhanh.id}>
              {chiNhanh.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Phòng ban"
        name="phongBanId"
        rules={[{ required: true, message: 'Hãy chọn một phòng ban' }]}
      >
        <Select
          filterOption={false}
          showSearch
          placeholder="Chọn phòng ban"
          notFoundContent={loadingPhongBan ? <Spin size="small" /> : null}
          onSearch={handleSearchPhongBan}
        >
          {phongBanList.map((phongBan: PhongBanEntity) => (
            <Select.Option key={phongBan.id} value={phongBan.id}>
              {phongBan.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Chức vụ"
        name="chucVuId"
        rules={[{ required: true, message: 'Hãy chọn một chức vụ' }]}
      >
        <Select
          filterOption={false}
          showSearch
          placeholder="Chọn chức vụ"
          notFoundContent={loadingChucVu ? <Spin size="small" /> : null}
          onSearch={handleSearchChucVu}
        >
          {chucVuList.map((chucVU: ChucVuEntity) => (
            <Select.Option key={chucVU.id} value={chucVU.id}>
              {chucVU.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Row justify="end">
        <Space style={{ alignItems: 'normal' }}>
          <div style={{ display: 'flex', gap: 6 }}>
            <Button type="primary" danger onClick={() => onClose()}>
              Hủy
            </Button>
          </div>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              {nhanvien ? 'Lưu chỉnh sửa' : 'Tạo mới'}
            </Button>
          </Form.Item>
        </Space>
      </Row>
    </Form>
  )
}

export default FormNhanVien
