import { lazy } from 'react'
import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { UNG_VIEN_URL } from '../../constants/config.quan-ly-tuyen-dung'

const UngVienList = lazy(() => import('./pages/UngVienList'))
const CreateUngVienPage = lazy(() => import('./pages/Create.UngVienPage'))
const EditUngVienPage = lazy(() => import('./pages/Edit.UngVienPage'))
const ViewUngVienPage = lazy(() => import('./pages/View.UngVienPage'))

const UngVienRouters: AppRouterItem[] = [
  {
    path: '/',
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <UngVienList />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${UNG_VIEN_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <UngVienList />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${UNG_VIEN_URL}/create`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <CreateUngVienPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${UNG_VIEN_URL}/:ungVienId/view`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <ViewUngVienPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${UNG_VIEN_URL}/:ungVienId/edit`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <EditUngVienPage />
      </LoadingLazyComponents>
    ),
  },
]

export default UngVienRouters
