import * as microsoftTeams from "@microsoft/teams-js";
import axios from "axios";
import jwtDecode from "jwt-decode";

let graphAccessToken: string | undefined = undefined;

// luôn gọi hàm này để lấy access token ở chỗ lấy để có token mới nhất
export default async function getGraphAccessToken() {
  try {
    if (graphAccessToken) {
      // exp export as seconds
      const { exp } = jwtDecode<{ exp: number }>(graphAccessToken);
      const NOW_IN_SECONDS = Date.now() / 1000;

      if (exp && NOW_IN_SECONDS < exp) {
        return graphAccessToken;
      }
    }

    const msTeamsAuthToken = await microsoftTeams.authentication.getAuthToken();

    const { data } = await axios.post(
      "https://approval.zbh-dev.i24h.app/auth/get-ms-token",
      {
        token: msTeamsAuthToken,
      }
    );

    graphAccessToken = data?.data;
  } catch (error) {
    console.error("Không thể xác minh tài khoản của bạn", error);
  }

  return graphAccessToken;
}
