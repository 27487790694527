import { useEffect, useState } from 'react'
import { Button, Row, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Search from 'antd/lib/input/Search'
import { useAuth } from 'ict24h-themes-react'
import { useNavigate } from 'react-router-dom'
import { TaiLieuGiaoAnEntity } from '../../../../../../../../server-api-hrm.type'
import {
  MetadataType,
  searchQuery,
} from '../../../../../../../../utils/metadata.types'
import TaiLieuGiaoAnService from '../../../../../tai-lieu-giao-an/tai-lieu-giao-an.service'

type propsType = {
  // eslint-disable-next-line no-unused-vars
  addTaiLieuBuoiHoc: (taiLieuGiaoAnId: string) => void
}

function FormTaiLieuGiaoAnBuoiHoc({ addTaiLieuBuoiHoc }: propsType) {
  const [data, setData] = useState<TaiLieuGiaoAnEntity[]>([])
  const [metadata, setMetadata] = useState<MetadataType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')
  const auth = useAuth()
  const navigate = useNavigate()

  const handleAddTaiLieuGiaoAn = async (record: TaiLieuGiaoAnEntity) => {
    addTaiLieuBuoiHoc(record.id)
  }

  // lay toan bo tai lieu giao an hien thi len form
  const getAllTaiLieuGiaoAn = async (query?: searchQuery) => {
    setIsLoading(true)
    const res = await TaiLieuGiaoAnService(
      auth.token!,
      navigate
    ).getAllTaiLieuGiaoAn(query)
    setData(res.data.data)
    setMetadata(res.data.metadata)
    setIsLoading(false)
  }

  const handleSearch = (search: string) => {
    setName(search)
    getAllTaiLieuGiaoAn({ name: search })
  }

  useEffect(() => {
    getAllTaiLieuGiaoAn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: ColumnsType<TaiLieuGiaoAnEntity> = [
    {
      title: 'Taì Liệu Giáo Án',
      dataIndex: 'ten',
      key: 'ten',
      width: '30%',
    },

    {
      title: 'Liên Kết',
      dataIndex: 'link',
      key: 'link',
      width: '20%',
    },
    {
      title: 'Mô tả',
      dataIndex: 'moTa',
      key: 'moTa',
      width: '20%',
    },
    {
      title: 'Action',
      render: (record: TaiLieuGiaoAnEntity) => (
        <Button onClick={() => handleAddTaiLieuGiaoAn(record)}>Thêm</Button>
      ),
    },
  ]

  return (
    <>
      <Row justify="end">
        <Search
          allowClear
          value={searchText}
          placeholder="Search"
          onChange={(e) => setSearchText(e.target.value)}
          onSearch={(value: string) => {
            handleSearch(value)
          }}
          style={{ width: 200, marginRight: '16px', marginBottom: '16px' }}
        />
      </Row>
      <Table
        loading={isLoading}
        size="small"
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
      />
      <Row justify="end" style={{ marginTop: '16px' }}>
        <Space>
          <Button
            disabled={metadata?.paging.cursor.prev.length === 0}
            onClick={() => {
              if (metadata) {
                getAllTaiLieuGiaoAn({
                  pageId: metadata.paging.cursor.prev,
                  name,
                })
              }
            }}
          >
            Trang trước
          </Button>
          <Button
            disabled={metadata?.paging.cursor.next.length === 0}
            onClick={() => {
              if (metadata) {
                getAllTaiLieuGiaoAn({
                  pageId: metadata.paging.cursor.next,
                  name,
                })
              }
            }}
          >
            Trang tiếp
          </Button>
        </Space>
      </Row>
    </>
  )
}

export default FormTaiLieuGiaoAnBuoiHoc
