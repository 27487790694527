import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'
import { CA_LAM_PREFIX_URL } from '../modules/ca-lam/constants/config.ca-lam'
import { NGAY_LE_PREFIX_URL } from '../modules/ngay-le/constants/config.ngay-le'
import { QUAN_LY_CHAM_CONG_PREFIX_URL } from '../modules/quan-ly-cham-cong/constants/config.quan-ly-cham-cong'

export const CHAM_CONG_PREFIX_URL = '/cau-hinh-cham-cong'

export const configHomeAppLayout: HeaderLayoutConfig = {
  appName: 'CẤU HÌNH CHẤM CÔNG',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}

export const mapperBreadCrumbChamCong = {
  [CHAM_CONG_PREFIX_URL + CA_LAM_PREFIX_URL]: 'Ca làm việc',
  [CHAM_CONG_PREFIX_URL + NGAY_LE_PREFIX_URL]: 'Ngày lễ',
  [CHAM_CONG_PREFIX_URL + QUAN_LY_CHAM_CONG_PREFIX_URL]: 'Quản lý chấm công',
}
