import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { CHE_DO_BAO_HIEM_PREFIX_URL } from './constants/config.che-do-bao-hiem-xa-hoi'

const ListPage = lazy(() => import('./pages/ListPage.CheDoBaoHiem'))

const CheDoBaoHiemRouters: AppRouterItem[] = [
  {
    path: `${CHE_DO_BAO_HIEM_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default CheDoBaoHiemRouters
