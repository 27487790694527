import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { XIN_NGHI_PHEP_PREFIX_URL } from './constants/config.xin-nghi-phep'

const ListPage = lazy(() => import('./pages/ListPage.XinNghiPhep'))
const DetailPage = lazy(() => import('./pages/DetailPage.XinNghiPhep'))

const XinNghiPhepRouters: AppRouterItem[] = [
  {
    path: `${XIN_NGHI_PHEP_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `${XIN_NGHI_PHEP_PREFIX_URL}/:id`,
    element: (
      <LoadingLazyComponents>
        <DetailPage />
      </LoadingLazyComponents>
    ),
  },
]

export default XinNghiPhepRouters
