import { lazy } from 'react'
import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { SU_KIEN_TUYEN_DUNG_URL } from '../../constants/config.quan-ly-tuyen-dung'

const LayoutPageSuKienTuyenDung = lazy(
  () => import('./LayoutPage.SuKienTuyenDung')
)

const SuKienTuyenDungRouters: AppRouterItem[] = [
  {
    path: `/${SU_KIEN_TUYEN_DUNG_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <LayoutPageSuKienTuyenDung />
      </LoadingLazyComponents>
    ),
  },
]

export default SuKienTuyenDungRouters
