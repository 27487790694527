import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const QUAN_LY_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PATH =
  '/quan-ly-dieu-dong-luan-chuyen-nhan-su'

export const configHomeAppLayout: HeaderLayoutConfig = {
  appName: 'Quản lý điều động luân chuyển nhân sự',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
