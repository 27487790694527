import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { NHOM_TIEU_CHI_DANH_GIA_KPI_PREFIX_URL } from './constants/config.nhom-tieu-chi-danh-gia-kpi'

const ListPage = lazy(() => import('./pages/ListPage.NhomTieuChiDanhGia'))
// const EditPageMauKpi = lazy(() => import('./pages/EditPage.MauKpi'))
// const DetailPageMauKpi = lazy(() => import('./pages/DetailPage.MauKpi'))

const NhomTieuChiDanhGiaKpiRouters: AppRouterItem[] = [
  {
    path: `${NHOM_TIEU_CHI_DANH_GIA_KPI_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
  // {
  //   path: `${CAP_KPI_PREFIX_URL}/:id/edit`,
  //   element: (
  //     <LoadingLazyComponents>
  //       <EditPageMauKpi />
  //     </LoadingLazyComponents>
  //   ),
  // },
  // {
  //   path: `${CAP_KPI_PREFIX_URL}/:id/view`,
  //   element: (
  //     <LoadingLazyComponents>
  //       <DetailPageMauKpi />
  //     </LoadingLazyComponents>
  //   ),
  // },
]

export default NhomTieuChiDanhGiaKpiRouters
