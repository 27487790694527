import { MenuProps, Menu } from 'antd'
import { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import {
  LIST_MENU,
  LIST_MENU_PHIEU_DIEU_DONG_LUAN_CHUYEN_NHAN_SU,
} from '../constants/list-menu.dieu-dong-luan-chuyen-nhan-su'

const LeftMenuHomeApp: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [selectedKey, setSelectedKey] = useState<string>('')

  // Tự highlight menu đúng với đường dẫn
  useEffect(() => {
    if (pathname) {
      setSelectedKey(pathname)
    }
  }, [pathname])

  // tạo menu fake mẫu
  const items2: MenuProps['items'] = []

  // Thêm list menu = chương trình
  items2.unshift({
    key: 'other1',
    icon: <UserOutlined />,
    label: 'Quản lý điều động luân chuyển nhân sự',
    children: LIST_MENU.map((menuItem) => {
      const temp = menuItem
      temp.onClick = () => {
        navigate(menuItem.key)
      }
      return temp
    }),
  })

  items2.unshift({
    key: 'other2',
    icon: <UserOutlined />,
    label: 'Phiếu điều động luân chuyển nhân sự',
    children: LIST_MENU_PHIEU_DIEU_DONG_LUAN_CHUYEN_NHAN_SU.map((menuItem) => {
      const temp = menuItem
      temp.onClick = () => {
        navigate(menuItem.key)
      }
      return temp
    }),
  })

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={['other1']}
      style={{ height: '100%', minHeight: '89vh', borderRight: 0 }}
      items={items2}
      selectedKeys={[selectedKey]}
    />
  )
}

export default LeftMenuHomeApp
