import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { CHI_NHANH_PREFIX_URL } from './constants/config.chi-nhanh'

const ListPage = lazy(() => import('./pages/ListPage.ChiNhanh'))

const ChiNhanhRouters: AppRouterItem[] = [
  {
    path: `${CHI_NHANH_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default ChiNhanhRouters
