import { Breadcrumb, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";

import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { QUAN_LY_PHE_DUYET_PREFIX_URL } from "../constants/config.quan-ly-phe-duyet";

import { DON_VI_PREFIX_URL } from "../modules/don-vi/constants/config.don-vi";
import { TIEN_TE_PREFIX_URL } from "../modules/tien-te/constants/config.tien-te";
import { CAP_BAC_NHAN_VIEN_PREFIX_URL } from "../modules/cap-bac-nhan-vien/constants/config.cap-bac-nhan-vien";
import { TAM_UNG_NOI_BO_PREFIX_URL } from "../modules/tam-ung-noi-bo/constants/config.tam-ung-noi-bo";
import { DE_NGHI_HOAN_UNG_PREFIX_URL } from "../modules/de-nghi-hoan-ung/constants/config.de-nghi-hoan-ung";
import { DE_NGHI_MUA_SAM_PREFIX_URL } from "../modules/de-nghi-mua-sam/constants/config.de-nghi-mua-sam";
import { DE_NGHI_THANH_TOAN_PREFIX_URL } from "../modules/de-nghi-thanh-toan/constants/config.de-nghi-thanh-toan";
import { LOAI_PHIEU_THANH_TOAN_PREFIX_URL } from "../modules/loai-phieu-thanh-toan/constants/config.loai-phieu-thanh-toan";
import { NHA_CUNG_CAP_PREFIX_URL } from "../modules/nha-cung-cap/constants/config.nha-cung-cap";

const gridStyle: React.CSSProperties = {
  width: "25%",
  textAlign: "center",
};

const ListModules = () => {
  return (
    <div style={{ marginTop: 16 }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <UserOutlined />
          Danh sách modules Quản lý phê duyệt
        </Breadcrumb.Item>
      </Breadcrumb>

      <Card title="Danh sách modules tạm ứng nội bộ" style={{ marginTop: 16 }}>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              TAM_UNG_NOI_BO_PREFIX_URL +
              "/create"
            }
          >
            Tạo phiếu tạm ứng nội bộ
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              TAM_UNG_NOI_BO_PREFIX_URL +
              "/my-approval"
            }
          >
            Danh sách phiếu tạm ứng nội bộ tôi tạo
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              TAM_UNG_NOI_BO_PREFIX_URL +
              "/waiting-approval"
            }
          >
            Danh sách phiếu tạm ứng nội bộ chờ tôi duyệt
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              TAM_UNG_NOI_BO_PREFIX_URL +
              "/my-rejected"
            }
          >
            Danh sách phiếu tạm ứng nội bộ tôi từ chối
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              TAM_UNG_NOI_BO_PREFIX_URL +
              "/my-approved"
            }
          >
            Danh sách phiếu tạm ứng nội bộ tôi đã duyệt
          </Link>
        </Card.Grid>
      </Card>

      <Card
        title="Danh sách modules đề nghị hoàn ứng"
        style={{ marginTop: 16 }}
      >
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_HOAN_UNG_PREFIX_URL +
              "/create"
            }
          >
            Tạo phiếu đề nghị hoàn ứng
          </Link>
        </Card.Grid>

        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_HOAN_UNG_PREFIX_URL +
              "/my-approval"
            }
          >
            Danh sách phiếu đề nghị hoàn ứng tôi tạo
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_HOAN_UNG_PREFIX_URL +
              "/waiting-approval"
            }
          >
            Danh sách phiếu đề nghị hoàn ứng chờ tôi duyệt
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_HOAN_UNG_PREFIX_URL +
              "/my-rejected"
            }
          >
            Danh sách phiếu đề nghị hoàn ứng tôi từ chối
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_HOAN_UNG_PREFIX_URL +
              "/my-approved"
            }
          >
            Danh sách phiếu đề nghị hoàn ứng tôi đã duyệt
          </Link>
        </Card.Grid>
      </Card>

      <Card title="Danh sách modules đề nghị mua sắm" style={{ marginTop: 16 }}>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_MUA_SAM_PREFIX_URL +
              "/create"
            }
          >
            Tạo phiếu đề nghị mua sắm
          </Link>
        </Card.Grid>

        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_MUA_SAM_PREFIX_URL +
              "/my-approval"
            }
          >
            Danh sách phiếu đề nghị mua sắm tôi tạo
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_MUA_SAM_PREFIX_URL +
              "/waiting-approval"
            }
          >
            Danh sách phiếu đề nghị mua sắm chờ tôi duyệt
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_MUA_SAM_PREFIX_URL +
              "/my-rejected"
            }
          >
            Danh sách phiếu đề nghị mua sắm tôi từ chối
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={
              QUAN_LY_PHE_DUYET_PREFIX_URL +
              DE_NGHI_MUA_SAM_PREFIX_URL +
              "/my-approved"
            }
          >
            Danh sách phiếu đề nghị mua sắm tôi đã duyệt
          </Link>
        </Card.Grid>
      </Card>

      <Card title="Danh sách modules đề nghị thanh toán" style={{ marginTop: 20 }}>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_PHE_DUYET_PREFIX_URL + DE_NGHI_THANH_TOAN_PREFIX_URL + '/create'}>
            Tạo phiếu đề nghị thanh toán mới
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_PHE_DUYET_PREFIX_URL + DE_NGHI_THANH_TOAN_PREFIX_URL + '/my-approval'}>
            Danh sách phiếu đề nghị thanh toán tôi tạo
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_PHE_DUYET_PREFIX_URL + DE_NGHI_THANH_TOAN_PREFIX_URL + '/waiting-approval'}>
            Danh sách phiếu đề nghị thanh toán chờ tôi duyệt
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_PHE_DUYET_PREFIX_URL + DE_NGHI_THANH_TOAN_PREFIX_URL + '/my-act-approval'}>
            Danh sách phiếu đề nghị thanh toán tôi tham gia duyệt
          </Link>
        </Card.Grid>
      </Card>

      <Card title="Others" style={{ marginTop: 16 }}>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_PHE_DUYET_PREFIX_URL + DON_VI_PREFIX_URL}>
            Đơn vị
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link to={QUAN_LY_PHE_DUYET_PREFIX_URL + TIEN_TE_PREFIX_URL}>
            Tiền tệ
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={QUAN_LY_PHE_DUYET_PREFIX_URL + CAP_BAC_NHAN_VIEN_PREFIX_URL}
          >
            Cấp bậc
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={QUAN_LY_PHE_DUYET_PREFIX_URL + LOAI_PHIEU_THANH_TOAN_PREFIX_URL}
          >
            Loại phiếu thanh toán
          </Link>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Link
            to={QUAN_LY_PHE_DUYET_PREFIX_URL + NHA_CUNG_CAP_PREFIX_URL}
          >
            Nhà cung cấp
          </Link>
        </Card.Grid>
      </Card>
    </div>
  );
};

export default ListModules;
