import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const XIN_NGHI_PHEP_PREFIX_URL = '/xin-nghi-phep'
export const URL_ENDPOINT_XIN_NGHI_PHEP = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/nghi-phep`

export const configXinNghiPhepLayout: HeaderLayoutConfig = {
  appName: ' Thông tin xin nghỉ phép ',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
