import { message } from 'antd'

import { NavigateFunction } from 'react-router-dom'
import { URL_ENDPOINT_NHAN_VIEN } from './constants/config.nhan-vien'
import axiosClient from '../../../../utils/custom-fetch'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'
import {
  CreateNhanVienDto,
  NhanVienEntity,
  UpdateNhanVienDto,
} from '../../../../server-api-hrm.type'

export default function NhanVienService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllNhanVien = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: NhanVienEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_NHAN_VIEN, {
      params: query,
    })
    return response
  }

  const addNhanVien = async (values: CreateNhanVienDto) => {
    const response = await axios.post<NhanVienEntity>(
      URL_ENDPOINT_NHAN_VIEN,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editNhanVien = async (values: UpdateNhanVienDto, id: string) => {
    const res = await axios.patch(`${URL_ENDPOINT_NHAN_VIEN}/${id}`, values)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const deleteNhanVien = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_NHAN_VIEN}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietNhanVien = async (id: string) => {
    const response = await axios.get<NhanVienEntity>(
      `${URL_ENDPOINT_NHAN_VIEN}/${id}`
    )
    return response
  }

  return {
    getAllNhanVien,
    addNhanVien,
    editNhanVien,
    deleteNhanVien,
    getChitietNhanVien,
  }
}
