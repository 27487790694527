import { SidebarLeftLayout } from "ict24h-themes-react";
import type { FC } from "react";
import LeftMenu from "./components/LeftMenu.Kpi";
import { configHomeAppLayout } from "./constants/config.danh-gia-kpi";
import { dockConfig } from "../home/dock-config";
import ModuleRouters from "./KpiRouters";

const KpiLayout: FC = () => (
  <SidebarLeftLayout menu={<LeftMenu />} headerConfig={null} dock={dockConfig}>
    <ModuleRouters />
  </SidebarLeftLayout>
);

export default KpiLayout;
