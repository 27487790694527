import { HeaderLayoutConfig } from "ict24h-themes-react/lib/esm/components/UI/Header/Header";

export const CHI_TIET_DU_AN_PATH = "du-an";
export const CHI_TIET_DU_AN_PREFIX_URL = "/pms/du-an";
export const URL_ENDPOINT_DU_AN = `${process.env.REACT_APP_INVESTOR_PROJECT_MANAGEMENT_ENDPOINT_URL}/du-an`;

export const configChiTietDuAnLayout: HeaderLayoutConfig = {
  appName: "Quản lý dự án Chủ đầu tư",
  logoLink: "https://ict24h.net",
  backgroundColor: "#454791",
  color: "#000",
};
