import { message } from 'antd'
import { NavigateFunction } from 'react-router-dom'

const errorHandler = (
  status: number,
  responseMessage?: string,
  navigate?: NavigateFunction
) => {
  if (status === 400) {
    message.error(responseMessage)
  }
  if (status === 401) {
    message.error('Unauthorized')
    if (navigate) navigate('/notfound')
  }
  if (status === 406) {
    message.error(responseMessage)
    if (responseMessage === 'Không có phiếu hợp lệ')
      if (navigate) navigate('/notfound')
  }
  if (status === 404) {
    message.error('Not found')
    if (navigate) navigate('/notfound')
  }
  if (status > 499) {
    message.error('Server error')
    if (navigate) navigate('/notfound')
  }
}

export default errorHandler
