import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { DASHBOARD_KPI_PREFIX_URL } from './constants/config.dashboard-kpi'

const DashboardKpi = lazy(() => import('./DashboardKpi'))

const DashboardRouters: AppRouterItem[] = [
  {
    path: `${DASHBOARD_KPI_PREFIX_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <DashboardKpi />
      </LoadingLazyComponents>
    ),
  },
]

export default DashboardRouters
