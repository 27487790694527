import React, { useEffect } from 'react'
import { Button, DatePicker, Form, Input, message, Row, TimePicker } from 'antd'
import dayjs from 'dayjs'
import {
  BuoiHocEntity,
  KhoaDaoTaoEntity,
} from '../../../../../../../server-api-hrm.type'
import { dateFormatMain } from '../../../../../../../utils/dateFormat'

type propsType = {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (values: BuoiHocEntity) => void
  buoihoc: BuoiHocEntity | undefined
  khoadId: KhoaDaoTaoEntity | undefined
}

const { TextArea } = Input

function FormBuoiHoc({ onSubmit, buoihoc, khoadId }: propsType) {
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    const payload: any = {
      id: buoihoc?.id,
      gioBatDau: values.valueDate[0],
      gioKetThuc: values.valueDate[1],
      tenBuoiHoc: values.tenBuoiHoc,
      moTaBuoiHoc: values.moTaBuoiHoc,
      ngayHoc: dayjs(values.ngayHoc).toISOString(),
      khoaDaoTaoId: khoadId?.id,
      khoaDaoTao: values.khoaDaoTao,
    }

    onSubmit(payload)
  }

  useEffect(() => {
    form.setFieldsValue({
      tenBuoiHoc: buoihoc?.tenBuoiHoc,
      moTaBuoiHoc: buoihoc?.moTaBuoiHoc,
      valueDate: [dayjs(buoihoc?.gioBatDau), dayjs(buoihoc?.gioKetThuc)],
      khoaDaoTaoId: buoihoc?.khoaDaoTaoId,
      khoaDaoTao: buoihoc?.khoaDaoTao,
      ngayHoc: dayjs(buoihoc?.ngayHoc),
    })
    // eslint-disable-next-line
  }, [buoihoc])

  const onFinishFailed = () => {
    message.error('Hãy Nhập Đầy Đủ Thông Tin')
  }

  // eslint-disable-next-line arrow-body-style, @typescript-eslint/no-unused-vars
  // const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
  //   // eslint-disable-next-line no-self-compare
  //   return (
  //     dayjs(khoadId?.ngayKetThuc).isSameOrBefore(current, "day") ||
  //     dayjs(khoadId?.ngayBatDau).isSameOrAfter(current, "day")
  //   );
  // };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      colon
    >
      <Form.Item
        name="tenBuoiHoc"
        label="Tên Buổi Học"
        rules={[{ required: true, message: 'Hãy nhập buổi học!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="moTaBuoiHoc" label="Mô tả Buổi Học">
        <TextArea rows={2} />
      </Form.Item>

      <Form.Item
        label="Ngày Học"
        name="ngayHoc"
        rules={[{ required: true, message: 'Hãy nhập Ngày Học' }]}
      >
        <DatePicker format={dateFormatMain} placeholder="Chọn ngày học" />
      </Form.Item>

      <Form.Item
        label="Chọn Giờ Học"
        name="valueDate"
        rules={[{ required: true, message: 'Hãy chọn giờ !' }]}
      >
        <TimePicker.RangePicker />
      </Form.Item>

      <Row justify="end">
        <Form.Item labelAlign="right">
          <Button type="primary" htmlType="submit">
            {buoihoc ? 'Sửa' : 'Thêm'}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  )
}

export default FormBuoiHoc
