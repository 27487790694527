import * as microsoftTeams from "@microsoft/teams-js";

export default function () {
  microsoftTeams.authentication
    .authenticate({
      url: window.location.origin + "/login-m365-auth-start",
      width: 600,
      height: 535,
    })
    .then((result: string) => {
      // this.consentSuccess(result ?? "")
      console.log("result: ", result);
    })
    .catch((reason: string) => {
      // this.consentFailure(reason ?? "")
      console.error(reason);
    });
}
