import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const DON_VI_TINH_KPI_PREFIX_URL = '/don-vi-tinh'
export const DON_VI_TINH_CHI_TIET_PREFIX_URL = '/don-vi-tinh-chi-tiet'

export const URL_ENDPOINT_DON_VI_TINH = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/don-vi-tinh`

export const URL_ENDPOINT_DON_VI_TINH_CHI_TIET = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/don-vi-tinh-chi-tiet`

export const configMauKpiLayout: HeaderLayoutConfig = {
  appName: ' Thông tin danh sách đơn vị tính',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
