/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  Button,
  Card,
  Descriptions,
  Drawer,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  TableColumnsType,
  Tag,
} from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { ColumnsType } from 'antd/lib/table'
import { useAuth } from 'ict24h-themes-react'
import KeHoachHocTapService from '../ke-hoach-hoc-tap.service'
import FormKeHoach from '../components/FormKeHoach'
import BreadCrumb4th from '../../../components/BreadCrumb4th.QuanLyDaoTao'
import { QUAN_LY_DAO_TAO_PREFIX_URL } from '../../../constants/config.quan-ly-dao-tao'
import { KE_HOACH_HOC_TAP_PREFIX_URL } from '../constants/config.ke-hoach-hoc-tap'
import NhanVienService from '../../../../cau-hinh-thong-tin/modules/nhan-vien/nhan-vien.service'
import FormNhanVien from '../../../../cau-hinh-thong-tin/modules/nhan-vien/components/FormNhanVien'
import {
  CreateKeHoachHocTapDto,
  CreateNhanVienDto,
  DeXuatKhoaDaoTaoEntity,
  KeHoachHocTapEntity,
  KhoaDaoTaoEntity,
} from '../../../../../server-api-hrm.type'
import DrawerDeXuatKhoaDaoTaoOfKeHoach from '../modules/de-xuat-khoa-dao-tao/ModalDeXuatKhoaDaoTaoOfKeHoach'
import DrawerKhoaDaoTaoOfKeHoach from '../modules/khoa-dao-tao/ModalKhoaDaoTaoOfKeHoach'

function DetailPageKeHoachHocTap() {
  const auth = useAuth()

  const { id } = useParams()
  const [keHoach, setKeHoach] = useState<KeHoachHocTapEntity>()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalDeXuat, setModalDeXuat] = useState(false)
  const [selectedThongTinKhoaDaoTao, setSelectedThongTinKhoaDaoTao] =
    useState<KhoaDaoTaoEntity>()
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const [thongTinKhoaDaoTao, setThongTinKhoaDaoTao] =
    useState<KhoaDaoTaoEntity[]>()
  const [listDeXuatKhoaDaoTao, setListDeXuatKhoaDaoTao] =
    useState<DeXuatKhoaDaoTaoEntity[]>()
  const [selectedDeXuatKhoaDaoTao, setSelectedDeXuatKhoaDaoTao] =
    useState<DeXuatKhoaDaoTaoEntity>()
  const [loadingFormNhanVien, setLoadingFormNhanVien] = useState<boolean>(false)
  const [isModalEditKeHoach, setIsModalEditKeHoach] = useState<boolean>(false)
  const [isModalEditNhanVien, setIsModalEditNhanVien] = useState<boolean>(false)

  const fetchSingleKeHoach = async (idKeHoach: string) => {
    setLoading(true)
    const data = await KeHoachHocTapService(
      auth.token!,
      navigate
    ).getChitietKeHoach(idKeHoach)
    if (data) setKeHoach(data.data)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      fetchSingleKeHoach(id)
    }
    // eslint-disable-next-line
  }, [id])

  // --------------handle KeHoachKhoaDaoTao----------------------------//
  const getKhoaDaoTaoOfKeHoach = async () => {
    setIsLoading(true)
    const data = await KeHoachHocTapService(auth.token!, navigate).getSingle(
      `${id}`
    )
    setThongTinKhoaDaoTao(data.data.khoaDaoTao)
    setIsLoading(false)
    return data
  }
  useEffect(() => {
    getKhoaDaoTaoOfKeHoach()
    // eslint-disable-next-line
  }, [location.search, id])

  const confirmDelete = async (record: KhoaDaoTaoEntity) => {
    const abc = {
      keHoachHocTapId: `${id}`,
      khoaDaoTaoId: record.id,
    }
    if (id) {
      const res = await KeHoachHocTapService(
        auth.token!,
        navigate
      ).deleteKhoaDaoTaoOfKeHoach(abc)
      if (res) getKhoaDaoTaoOfKeHoach()
    }
  }

  const handleCancel = () => {
    navigate(`${location.pathname}`)
    setIsModalOpen(false)
  }

  const handleAddThongTinKhoaDaoTao = async (khoaDaoTaoId: string) => {
    if (id) {
      const res = await KeHoachHocTapService(
        auth.token!,
        navigate
      ).addKhoaDaoTaoToKeHoach({
        keHoachHocTapId: id,
        khoaDaoTaoId,
      })
      if (res) getKhoaDaoTaoOfKeHoach()
    }
  }

  const handleEditKeHoach = async (values: CreateKeHoachHocTapDto) => {
    if (keHoach) {
      setLoading(true)
      await KeHoachHocTapService(auth.token!, navigate).editKeHoach(
        values,
        keHoach.id
      )
      if (id) fetchSingleKeHoach(id)
      setLoading(false)
      setIsModalEditKeHoach(false)
    }
  }

  // note sửa

  const handleEditNhanVien = async (values: CreateNhanVienDto) => {
    if (keHoach?.nhanVien) {
      setLoadingFormNhanVien(true)
      await NhanVienService(auth.token!, navigate).editNhanVien(
        values,
        keHoach?.nhanVien.id
      )
      if (id) fetchSingleKeHoach(id)
      setLoadingFormNhanVien(false)
      setIsModalEditNhanVien(false)
    }
  }

  const columns: TableColumnsType<KhoaDaoTaoEntity> = [
    {
      title: 'Tiêu đề khoá đào tạo',
      dataIndex: 'tieuDe',
      key: 'name',
      render: (text, record: KhoaDaoTaoEntity, index) => (
        <Link to={record.id}>{text}</Link>
      ),
    },
    {
      title: 'Mô tả',
      dataIndex: 'moTa',
      key: 'diachi',
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'ngayBatDau',
      render: (text) => dayjs(text).format('MMM Do YY'),
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'ngayKetThuc',
      render: (text: Date) => dayjs(text).format('MMM Do YY'),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'trangThai',
      render: (text: string, record: KhoaDaoTaoEntity, index) => {
        if (
          dayjs(record?.ngayKetThuc).isSameOrAfter(dayjs(), 'day') &&
          dayjs(record?.ngayBatDau).isSameOrBefore(dayjs(), 'day')
        ) {
          return <Tag color="green">Active</Tag>
        }
        if (dayjs(record?.ngayBatDau).isAfter(dayjs(), 'day')) {
          return <Tag color="blue">Soon</Tag>
        }
        return <Tag color="red">Expired</Tag>
      },
    },

    {
      title: 'Hình thức',
      dataIndex: 'hinhThuc',
      key: 'hinhThuc',
    },
    {
      title: 'Loại hình',
      dataIndex: 'loaiHinh',
      key: 'loaiHinh',
    },
    {
      title: 'Danh mục',
      dataIndex: ['danhMuc', 'name'],
    },
    {
      width: '15%',
      fixed: 'right',
      title: 'Action',
      render: (text, record: KhoaDaoTaoEntity, index) => (
        <Space>
          <Popconfirm
            title="Bạn có chắc chắn là muốn xóa?"
            onConfirm={() => confirmDelete(record)}
            okText="Có"
            cancelText="Không"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  // ---------------------------------------------handle KeHoachKhoaDaoTao--------------------------------------------------------------------//

  /// //////--------------------------------- handle DeXuat -------------------------------------------//
  const getThongTinDeXuatKhoaDaoTao = async () => {
    setIsLoading(true)
    const res = await KeHoachHocTapService(auth.token!, navigate).getSingle(
      `${id}`
    )
    setListDeXuatKhoaDaoTao(res.data.deXuatKhoaDaoTao)
    setIsLoading(false)
    return res
  }

  useEffect(() => {
    getThongTinDeXuatKhoaDaoTao()
    // eslint-disable-next-line
  }, [location.search])

  const handleCancelDeXuat = () => {
    navigate(`${location.pathname}`)
    setModalDeXuat(false)
  }
  const confirmDeleteDeXuat = async (record: DeXuatKhoaDaoTaoEntity) => {
    const abc = {
      keHoachHocTapId: `${id}`,
      deXuatKhoaDaoTaoId: record.id,
    }
    if (id) {
      const res = await KeHoachHocTapService(
        auth.token!,
        navigate
      ).deleteDeXuatOfKeHoach(abc)
      if (res) getThongTinDeXuatKhoaDaoTao()
    }
  }
  const showModalDeXuat = () => {
    setModalDeXuat(true)
  }

  const handleAddThongTinDeXuat = async (deXuatKhoaDaoTaoId: string) => {
    if (id) {
      const res = await KeHoachHocTapService(
        auth.token!,
        navigate
      ).addDeXuatToKeHoach({
        keHoachHocTapId: id,
        deXuatKhoaDaoTaoId,
      })
      if (res) getThongTinDeXuatKhoaDaoTao()
    }
  }
  const columnsDeXuat: ColumnsType<DeXuatKhoaDaoTaoEntity> = [
    {
      title: 'Tiêu đề',
      dataIndex: 'tieuDe',
      key: 'hoTen',
      render: (text, record: DeXuatKhoaDaoTaoEntity, index) => (
        <Link to={`${record.id}`}>{text}</Link>
      ),
      width: '30%',
    },

    {
      title: 'Mô tả',
      dataIndex: 'mota',
      key: 'diachi',
      width: '20%',
    },
    {
      fixed: 'right',
      width: '15%',
      title: 'Action',
      render: (record: DeXuatKhoaDaoTaoEntity) => (
        <Space>
          <Popconfirm
            title="Bạn có chắc chắn là muốn xóa?"
            onConfirm={() => confirmDeleteDeXuat(record)}
            okText="Có"
            cancelText="Không"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]
  /// //--------------------------------- handle DeXuat -------------------------------------------//

  return (
    <div style={{ paddingTop: 16 }}>
      <BreadCrumb4th
        label="Chi tiết kế hoạch học tập"
        item3rd={{
          label: 'Danh sách kế hoạch học tập',
          link: QUAN_LY_DAO_TAO_PREFIX_URL + KE_HOACH_HOC_TAP_PREFIX_URL,
        }}
      />
      <Card
        title={<b>Chi tiết kế hoạch</b>}
        style={{ width: '100%', marginTop: 16 }}
        extra={
          <Button
            onClick={() => {
              setIsModalEditKeHoach(true)
            }}
          >
            <EditOutlined />
          </Button>
        }
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Descriptions>
            <Descriptions.Item label="Kế Hoach">
              <text>{keHoach?.nhanVien.hoTen}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Năm Dự Kiến">
              <text>{`${keHoach?.nam}`}</text>
            </Descriptions.Item>
          </Descriptions>
        )}
        <Modal
          title="Sửa thông tin kế hoạch"
          open={isModalEditKeHoach}
          onCancel={() => {
            setIsModalEditKeHoach(false)
          }}
          footer={null}
          destroyOnClose
        >
          <FormKeHoach onSubmit={handleEditKeHoach} keHoach={keHoach} />
        </Modal>
      </Card>
      {/* -------------------------------------------------------------------- */}
      <Card
        title={<b>Chi tiết nhân viên của kế hoạch này</b>}
        style={{ width: '100%', marginTop: 16 }}
        extra={
          <Button
            onClick={() => {
              setIsModalEditNhanVien(true)
            }}
          >
            <EditOutlined />
          </Button>
        }
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Descriptions>
            <Descriptions.Item label="Nhân viên">
              <text>{keHoach?.nhanVien.hoTen}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Địa chỉ">
              <text>{`${keHoach?.nhanVien.diaChi}`}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <text>{keHoach?.nhanVien.email}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Số điện thoại">
              <text>{keHoach?.nhanVien.soDienThoai}</text>
            </Descriptions.Item>
            <Descriptions.Item label="Ngày bắt đầu làm việc">
              <span>
                {dayjs(keHoach?.nhanVien?.ngayBatDauLamViec).format(
                  'DD/MM/YYYY'
                )}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Chi nhánh">
              <span>{keHoach?.nhanVien?.chiNhanh?.name}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Phòng ban">
              <span>{keHoach?.nhanVien?.phongBan?.name}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Chức vụ">
              <span>{keHoach?.nhanVien?.chucVu?.name}</span>
            </Descriptions.Item>
          </Descriptions>
        )}
        <Drawer
          title="Sửa thông tin nhân viên"
          open={isModalEditNhanVien}
          onClose={() => {
            setIsModalEditNhanVien(false)
          }}
          footer={null}
          destroyOnClose
        >
          <FormNhanVien
            loading={loadingFormNhanVien}
            onSubmit={handleEditNhanVien}
            nhanvien={keHoach?.nhanVien}
            onClose={() => setIsModalEditNhanVien(false)}
          />
        </Drawer>
      </Card>

      <Card
        title={<b>Danh sách khoá đào tạo của kế hoạch</b>}
        style={{ width: '100%', marginTop: 16 }}
        extra={
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            <PlusOutlined />
            Thêm
          </Button>
        }
      >
        <Table
          scroll={{ x: 'max-content' }}
          size="small"
          loading={isLoading}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={thongTinKhoaDaoTao}
          pagination={false}
        />
        <DrawerKhoaDaoTaoOfKeHoach
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          thongTinKhoaDaoTao={selectedThongTinKhoaDaoTao}
          addThongTinKhoaDaoTao={handleAddThongTinKhoaDaoTao}
        />
      </Card>
      {/* ------------------------------------------------------------------ */}
      <Card
        title={<b>Khoá đào tạo đề xuất cho kế hoạch này</b>}
        style={{ width: '100%', marginTop: 16 }}
        extra={
          <Button type="primary" onClick={showModalDeXuat}>
            <PlusOutlined />
            Thêm
          </Button>
        }
      >
        <Table
          scroll={{ x: 'max-content' }}
          size="small"
          columns={columnsDeXuat}
          loading={isLoading}
          rowKey={(record) => record.id}
          dataSource={listDeXuatKhoaDaoTao}
          pagination={false}
        />
        <DrawerDeXuatKhoaDaoTaoOfKeHoach
          isModalOpen={modalDeXuat}
          handleCancel={handleCancelDeXuat}
          deXuatKhoaDaoTao={selectedDeXuatKhoaDaoTao}
          addThongTinKhoaDaoTao={handleAddThongTinDeXuat}
        />
      </Card>
    </div>
  )
}

export default DetailPageKeHoachHocTap
