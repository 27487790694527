import { HeaderLayoutConfig } from "ict24h-themes-react/lib/esm/components/UI/Header/Header";

export const QUAN_LY_PHE_DUYET_PREFIX_URL = "/quan-ly-phe-duyet";

export const configHomeAppLayout: HeaderLayoutConfig = {
  appName: "Quản Lý Phê Duyệt",
  logoLink: "https://ict24h.net",
  backgroundColor: "#454791",
  color: "#000",
};

export type searchQuery = {
  name?: string;
  pageId?: string;
  pageLimit?: string;
  nguoiTrinhDuyetName?: string;
  approvalStatus?: string;
  approvalAction?: string;
  userId?: string;
  authorIds?: string;
};
