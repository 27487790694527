import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const QUAN_LY_DAO_TAO_PREFIX_URL = '/quan-ly-dao-tao'

export const configHomeAppLayout: HeaderLayoutConfig = {
  appName: 'Quản Lý Đào Tạo',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
