import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { lazy } from 'react'
import { LOAI_NGHI_PHEP_PREFIX_URL } from './constants/config.loai-nghi-phep'

const ListPage = lazy(() => import('./pages/ListPage.LoaiNghiPhep'))

const LoaiNghiPhepRouters: AppRouterItem[] = [
  {
    path: `${LOAI_NGHI_PHEP_PREFIX_URL}/`,
    element: (
      <LoadingLazyComponents>
        <ListPage />
      </LoadingLazyComponents>
    ),
  },
]

export default LoaiNghiPhepRouters
