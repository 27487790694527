import { SidebarLeftLayout } from "ict24h-themes-react";
import type { FC } from "react";
import LeftMenu from "./components/LeftMenu.DieuDongLuanChuyenNhanSu";
import { configHomeAppLayout } from "./constants/config.dieu-dong-luan-chuyen-nhan-su";
import ModuleRouters from "./DieuDongLuanChuyenNhanSuRouters";
import { dockConfig } from "../home/dock-config";

const QuanLyDieuDongLuanChuyenNhanSuLayout: FC = () => (
  <SidebarLeftLayout menu={<LeftMenu />} headerConfig={null} dock={dockConfig}>
    <ModuleRouters />
  </SidebarLeftLayout>
);

export default QuanLyDieuDongLuanChuyenNhanSuLayout;
