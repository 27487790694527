import { HeaderLayoutConfig } from 'ict24h-themes-react/lib/esm/components/UI/Header/Header'

export const PHUONG_PHAP_TINH_KPI_PREFIX_URL = '/phuong-phap-tinh'
export const PHUONG_PHAP_TINH_CHI_TIET_PREFIX_URL = '/phuong-phap-tinh-chi-tiet'

export const URL_ENDPOINT_PHUONG_PHAP_TINH = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/phuong-phap-tinh`

export const URL_ENDPOINT_PHUONG_PHAP_TINH_CHI_TIET = `${process.env.REACT_APP_HRM_ENDPOINT_URL}/phuong-phap-tinh-kpi-chi-tiet`

export const configMauKpiLayout: HeaderLayoutConfig = {
  appName: ' Thông tin danh sách phương pháp tính',
  logoLink: 'https://ict24h.net',
  backgroundColor: '#454791',
  color: '#000',
}
