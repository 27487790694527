import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import RenderIframe, { IframeProps } from "./RenderIframe";

const defaultModule: IframeProps["module"] = {
  childOrigin: "https://ptc-core-prod-test.i24h.app",
  url: "/dashboard",
};

const initListModules: { [key: string]: IframeProps["module"] } = {
  default: defaultModule,
};

export default function ExternalComponent() {
  let [searchParams] = useSearchParams();
  const queryModulename = searchParams.get("module");

  const [currentModule, setCurrentModule] = useState<string>("default");
  const [listModule, setListModule] = useState(initListModules);

  //load list config from local storage
  useEffect(() => {
    const listModules = localStorage.getItem("listModules");
    if (listModules) {
      setListModule({
        ...JSON.parse(listModules),
        ...initListModules,
      });
    }
  }, []);

  useEffect(() => {
    if (queryModulename) {
      setCurrentModule(queryModulename);
    }
  }, [queryModulename]);

  return <RenderIframe module={listModule[currentModule]} />;
}
