export const URL_ENDPOINT_DE_NGHI_MUA_SAM = `${
  process.env.REACT_APP_ENDPOINT_URL
}/de-nghi-mua-sam`;

export const DE_NGHI_MUA_SAM_PREFIX_URL = "/de-nghi-mua-sam";

export const URL_ENDPOINT_CHI_TIET_DE_NGHI_MUA_SAM = `${
  process.env.REACT_APP_ENDPOINT_URL
}/chi-tiet-de-nghi-mua-sam`;

export const URL_ENDPOINT_CHI_TIET_DE_NGHI_MUA_SAM_APPROVAL_LOG = `${
  process.env.REACT_APP_ENDPOINT_URL
}/de-nghi-mua-sam-approval-log`;

export const CHI_TIET_DE_NGHI_MUA_SAM_PREFIX_URL = "/chi-tiet-de-nghi-mua-sam";
