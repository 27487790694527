import { MenuProps, Menu, Tooltip } from 'antd'
import { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { TeamOutlined } from '@ant-design/icons'
import LIST_MENU from '../constants/list-menu.quan-ly-tuyen-dung'

const LeftMenuHomeApp: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [selectedKey, setSelectedKey] = useState<string>('')

  // Tự highlight menu đúng với đường dẫn
  useEffect(() => {
    if (pathname) {
      setSelectedKey(pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  // tạo menu fake mẫu
  const items2: MenuProps['items'] = []

  // Thêm list menu = chương trình
  items2.unshift({
    key: 'other1',
    icon: <TeamOutlined />,
    label: 'Quản lý tuyển dụng',
    children: LIST_MENU.map((menuItem) => ({
      key: menuItem.key,
      label: <Tooltip title={menuItem.label}>{menuItem.label}</Tooltip>,
      onClick: () => {
        if (!menuItem.childern) {
          navigate(menuItem.key)
        }
      },
      children: menuItem.childern?.map((subItem) => ({
        key: subItem.key,
        label: <Tooltip title={subItem.label}>{subItem.label}</Tooltip>,
        onMouseDown: () => navigate(subItem.key),
      })),
    })),
  })

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={['other1']}
      style={{ height: '100%', minHeight: '89vh', borderRight: 0 }}
      items={items2}
      selectedKeys={[selectedKey]}
    />
  )
}

export default LeftMenuHomeApp
