import { message } from 'antd'

import { NavigateFunction } from 'react-router-dom'
import { URL_ENDPOINT_CHUC_VU } from './constants/config.chuc-vu'
import axiosClient from '../../../../utils/custom-fetch'
import {
  ChucVuEntity,
  CreateChucVuDto,
  UpdateChucVuDto,
} from '../../../../server-api-hrm.type'
import { MetadataType, searchQuery } from '../../../../utils/metadata.types'

export default function ChucVuService(
  token: string,
  navigate?: NavigateFunction
) {
  const axios = axiosClient(token, navigate)

  const getAllChucVu = async (query?: searchQuery) => {
    const response = await axios.get<{
      data: ChucVuEntity[]
      metadata: MetadataType
    }>(URL_ENDPOINT_CHUC_VU, {
      params: query,
    })
    return response
  }

  const addChucVu = async (values: CreateChucVuDto) => {
    const response = await axios.post<ChucVuEntity>(
      URL_ENDPOINT_CHUC_VU,
      values
    )
    if (response.status > 199 && response.status < 300) {
      message.success('Thành công')
    }
    return response
  }

  const editChucVu = async (values: UpdateChucVuDto, id: string) => {
    const res = await axios.patch(`${URL_ENDPOINT_CHUC_VU}/${id}`, values)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const deleteChucVu = async (id: string) => {
    const res = await axios.delete(`${URL_ENDPOINT_CHUC_VU}/${id}`)
    if (res.status > 199 && res.status < 300) {
      message.success('Thành công')
    }
    return res
  }

  const getChitietChucVu = async (id: string) => {
    const response = await axios.get<ChucVuEntity>(
      `${URL_ENDPOINT_CHUC_VU}/${id}`
    )
    return response
  }
  return {
    getAllChucVu,
    addChucVu,
    editChucVu,
    deleteChucVu,
    getChitietChucVu,
  }
}
