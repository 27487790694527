import { lazy } from 'react'
import { LoadingLazyComponents } from 'ict24h-themes-react'
import { AppRouterItem } from 'ict24h-themes-react/lib/esm/modules/routers/AppRouters'
import { CAU_HINH_URL } from '../../constants/config.quan-ly-tuyen-dung'
import { BO_TIEU_CHI_DANH_GIA_PREFIX_URL } from './bo-tieu-chi-danh-gia/constants/config.bo-tieu-chi-danh-gia'
import { HINH_THUC_LAM_VIEC_PREFIX_URL } from './hinh-thuc-lam-viec/constants/config.hinh-thuc-lam-viec'
import { LOAI_SU_KIEN_TUYEN_DUNG_PREFIX_URL } from './loai-su-kien-tuyen-dung/constants/config.loai-su-kien-tuyen-dung'
import { NGUON_TUYEN_DUNG_PREFIX_URL } from './nguon-tuyen-dung/constants/config.nguon-tuyen-dung'

const BoTieuChiDanhGia = lazy(
  () => import('./bo-tieu-chi-danh-gia/LayoutPage.BoTieuChiDanhGia')
)
const HinhThucLamViec = lazy(
  () => import('./hinh-thuc-lam-viec/LayoutPage.HinhThucLamViec')
)
const LoaiSuKienTuyenDung = lazy(
  () => import('./loai-su-kien-tuyen-dung/LayoutPage.LoaiSuKienTuyenDung')
)
const NguonTuyenDung = lazy(
  () => import('./nguon-tuyen-dung/LayoutPage.NguonTuyenDung')
)

const CauHinhRouters: AppRouterItem[] = [
  {
    path: `/${CAU_HINH_URL}${BO_TIEU_CHI_DANH_GIA_PREFIX_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <BoTieuChiDanhGia />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${CAU_HINH_URL}${HINH_THUC_LAM_VIEC_PREFIX_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <HinhThucLamViec />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${CAU_HINH_URL}${LOAI_SU_KIEN_TUYEN_DUNG_PREFIX_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <LoaiSuKienTuyenDung />
      </LoadingLazyComponents>
    ),
  },
  {
    path: `/${CAU_HINH_URL}${NGUON_TUYEN_DUNG_PREFIX_URL}`,
    element: (
      <LoadingLazyComponents loadingType="skeleton">
        <NguonTuyenDung />
      </LoadingLazyComponents>
    ),
  },
]

export default CauHinhRouters
